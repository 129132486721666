export interface BlogListItem {
  cmpnyCd?: string
  cstId?: string
  nttSn: number
  regId?: string
  regDate?: string
  updId?: string
  updDate?: string
  lclasCd?: string
  mlsfcCd?: string
  sclasCd?: string
  bbsTy?: string
  bbsSj?: string
  nttSj: string
  nttCn: string
  imgUrl?: string
  readngCnt?: number
  answerCnt?: number
  accessIp: string
  page?: number
  useYn?: string
  delYn?: string
  srchOpt?: string
  srchStr?: string
  option?: string
  commentCn?: string
  commentSn?: number
  parentCommentSn?: number
  blogNttSn?: string
  blogFileSeq?: number
  fileSeqList?: number[]
  fileNameList?: string[]
  deleteFileSeqList?: number[]
  nickName?: string
  withDrawYn?: string
  thumbnailUrl?: string
}

export interface BlogDetailItem {
  bbsSj: string
  nttSn: number
  nttSj: string
  nttCn: string
  nickName: string
  withDrawYn: string
  regId: string
  regDate: string
  updId: string
  updDate: string
  accessIp?: string
  readngCnt?: number
  answerCnt?: number
}

export interface ThumbnailItem {
  fileNm: string
  filePath: string
  fileUrl: string
}
export interface FileUploadResponse {
  fileNm: string
  filePath: string
  fileSeq: number
  fileSize: number
  fileType: string
  binaryFile: string
}

export const removeHtmlTags = (html: string): string => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}
