import { ApexBarChart } from '@/components'
import { generatePeriod } from '@/pages/main/_components/golfSearch/useGolfSearchData'
import {
  GraphDataState,
  MonthAvgPriceByRegionItem,
} from '@/redux/slices/golfSearchSlice'
import styled from 'styled-components'

interface MonthGraphProps {
  monthData: GraphDataState['monthData']
  monthAvgPriceByRegion?: GraphDataState['monthAvgPriceByRegion']
  isDualGraph?: boolean
  areaCd?: string | null
  areaNm?: string
}

export const MonthGraph = ({
  monthData,
  monthAvgPriceByRegion,
  isDualGraph,
  areaCd,
  areaNm,
}: MonthGraphProps) => {
  const safeGraphData = monthData && monthData.length ? monthData : []
  const safeRegionGraphData =
    monthAvgPriceByRegion && monthAvgPriceByRegion.length
      ? monthAvgPriceByRegion
      : []

  const monthsArr = generatePeriod('month')

  const mapRegionAvgToGraph = (
    regionData: MonthAvgPriceByRegionItem[] = [],
    monthsArr: string[] = [],
  ): number[][] => {
    const updatedRegionGraphData = [
      new Array(12).fill(0),
      new Array(12).fill(0),
      new Array(12).fill(0),
      new Array(12).fill(0),
    ]

    regionData.forEach((item) => {
      const formattedMonth = `${item.year.slice(-2)}-${item.month.padStart(2, '0')}`
      const monthIndex = monthsArr.findIndex(
        (month) => month === formattedMonth,
      )

      if (monthIndex >= 0 && monthIndex < 12) {
        updatedRegionGraphData[0][monthIndex] = item.n01
        updatedRegionGraphData[1][monthIndex] = item.n02
        updatedRegionGraphData[2][monthIndex] = item.m01
        updatedRegionGraphData[3][monthIndex] = item.m02
      }
    })

    return updatedRegionGraphData
  }

  const regionGraphData = mapRegionAvgToGraph(safeRegionGraphData, monthsArr)

  const commonOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: { show: false },
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      categories: monthsArr,
      labels: {
        rotateAlways: true,
        rotate: -45,
      },
    },
    yaxis: {
      title: { text: '단위 (원)' },
      labels: {
        formatter: (value: any) => value.toLocaleString('ko-KR'),
      },
    },
    tooltip: {
      y: {
        formatter: (value: any) => value.toLocaleString('ko-KR'),
      },
    },
    fill: {
      opacity: [0.85, 0.85, 0.55, 0.45],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
      },
    },
  }

  const nonMemberData = [
    {
      name: '비회원(주중)',
      data: safeGraphData[0] || [0],
      color: '#FF6347',
    },
    {
      name: '비회원(주말)',
      data: safeGraphData[1] || [0],
      color: '#4682B4',
    },
    {
      name: `${areaNm} 평균(비회원 주중)`,
      data: regionGraphData[0] || [0],
      color: '#7B68EE',
      type: 'area',
      dashArray: 5,
    },
    {
      name: `${areaNm} 평균(비회원 주말)`,
      data: regionGraphData[1] || [0],
      color: '#00FA9A',
      type: 'area',
      dashArray: 5,
    },
  ]

  const memberData = [
    {
      name: '회원(주중)',
      data: safeGraphData[2] || [0],
      color: '#32CD32',
    },
    {
      name: '회원(주말)',
      data: safeGraphData[3] || [0],
      color: '#FFD700',
    },
    {
      name: `${areaNm} 평균(회원 주중)`,
      data: regionGraphData[2] || [0],
      color: '#7B68EE',
      type: 'line',
      dashArray: 5,
    },
    {
      name: `${areaNm} 평균(회원 주말)`,
      data: regionGraphData[3] || [0],
      color: '#00FA9A',
      type: 'line',
      dashArray: 5,
    },
  ]

  const isNonMemberDataAvailable =
    safeGraphData[0]?.some((value) => value !== 0) ||
    safeGraphData[1]?.some((value) => value !== 0)
  const isMemberDataAvailable =
    safeGraphData[2]?.some((value) => value !== 0) ||
    safeGraphData[3]?.some((value) => value !== 0)

  return (
    <GraphWrapper>
      <GraphTitle>
        {areaNm ? `${areaNm} 지역 평균` : '월 평균 가격 비교'}
      </GraphTitle>

      {isNonMemberDataAvailable ? (
        <ApexBarChart
          options={{
            ...commonOptions,
            title: {
              text: '비회원',
              align: 'left',
              style: { fontSize: '16px', fontWeight: 'bold' },
            },
          }}
          height="350px"
          width="100%"
          series={nonMemberData}
        />
      ) : null}

      {isMemberDataAvailable ? (
        <ApexBarChart
          options={{
            ...commonOptions,
            title: {
              text: '회원',
              align: 'left',
              style: { fontSize: '16px', fontWeight: 'bold' },
            },
          }}
          height="350px"
          width="100%"
          series={memberData}
        />
      ) : null}
    </GraphWrapper>
  )
}

const GraphWrapper = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
`

const GraphTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
`
