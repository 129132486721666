import { Button, Checkbox, Input, Label, Select, Textarea } from '@/components'
import React, { useRef, useState } from 'react'
import { InsertFile } from './insertFile'
import FileUploadSample from '@/components/sample/fileUploadSample'

// Props 타입 정의
interface InsertFormProps {
  formData: {
    bbsSj: string
    nttSj: string
    nttCn: string
    guestName?: string
    guestEml?: string
    guestInsertCheck?: boolean
  }
  fileName?: string | undefined
  modalMessage: string
  showSaveButton: boolean
  moveList: boolean
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void
  handleCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleButtonClick: () => void
  handleList: () => void
  openPrivacyPolicy?: () => void
  showPolicyPopup?: boolean
  setShowPolicyPopup?: React.Dispatch<React.SetStateAction<boolean>>
  navigate: (path: string) => void
  isAuthenticated: boolean
  insertBoard?: () => void
}

const options = [
  { value: '', label: '말머리 선택' },
  { value: '01', label: '잡담' },
  { value: '02', label: '질문' },
  { value: '00', label: '기타' },
]

export const InsertForm: React.FC<InsertFormProps> = ({
  formData,
  fileName,
  handleSubmit,
  handleChange,
  handleCheckboxChange,
  handleFileChange,
  handleButtonClick,
  handleList,
  openPrivacyPolicy,
  isAuthenticated,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          gap: '5px',
        }}
      >
        <Select
          id="bbsSj"
          name="bbsSj"
          width="200px"
          value={formData.bbsSj}
          onChange={handleChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Input
          id="nttSj"
          name="nttSj"
          placeholder="제목을 입력하세요."
          value={formData.nttSj}
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          marginBottom: '10px',
        }}
      >
        <Textarea
          id="nttCn"
          name="nttCn"
          width={'100%'}
          height={'500px'}
          placeholder="내용을 입력하세요."
          state="success"
          value={formData.nttCn}
          onChange={handleChange}
        />
      </div>
      {!isAuthenticated && (
        <>
          <div
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              gap: '5px',
            }}
          >
            <div style={{ flex: 1 }}>
              <Label display="block" mb="5px" htmlFor="guestName">
                <span style={{ color: 'orange' }}>*&nbsp;</span>작성자
              </Label>
              <div style={{ marginTop: '5px' }} />
              <Input
                id="guestName"
                name="guestName"
                width="100%"
                placeholder="10자 이내로 입력 (한글,영어만 가능)"
                maxLength={10}
                value={formData.guestName}
                onChange={handleChange}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Label display="block" mb="5px" htmlFor="guestEml">
                <span style={{ color: 'orange' }}>*&nbsp;</span>이메일
              </Label>
              <div style={{ marginTop: '5px' }} />
              <Input
                id="guestEml"
                name="guestEml"
                width="100%"
                placeholder="입력 예시 : example@example.com"
                maxLength={30}
                value={formData.guestEml}
                onChange={handleChange}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', gap: '5px', marginTop: '10px' }}>
              <Checkbox
                id="guestInsertCheck"
                name="guestInsertCheck"
                label="[필수] 개인정보 수집 및 이용 동의"
                checked={formData.guestInsertCheck}
                onChange={handleCheckboxChange}
              />
              <div
                onClick={openPrivacyPolicy}
                style={{
                  textDecoration: 'underline', // 밑줄 추가
                  cursor: 'pointer', // 포인터 모양 커서
                  color: '#d09d30', // 강조된 파란색 텍스트
                }}
              >
                내용보기
              </div>
            </div>
          </div>
        </>
      )}
      {/* {isAuthenticated && (
        <InsertFile
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          fileName={fileName}
          handleButtonClick={handleButtonClick}
        />
      )} */}
      {/* <FileUploadSample /> */}
      <div
        style={{
          marginBottom: '10px',
        }}
      ></div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button color="neverbe" type="submit">
              저장
            </Button>
            <Button color="neverbe" onClick={handleList}>
              취소
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}
