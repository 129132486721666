import { useLocation, useNavigate } from 'react-router-dom'
import Pagination from '../_components/pagination'
import { BoardListItem, fieldNames } from '../boardItem'
import { BoardTable } from './boardTable'
import { BoardSearch } from './boardSearch'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

export interface SearchData {
  srchOpt: string
  srchStr: string
}

interface BoardListProps {
  srchData: SearchData
  setSrchData: React.Dispatch<React.SetStateAction<SearchData>>
  setPage: React.Dispatch<React.SetStateAction<number>>
  boardList: BoardListItem[]
  pageCnt: number
  getBoardList: (pageParam:number) => void
  boardSrchData?: any // TODO 다른 화면에서도 사용하고 있어서 임시 옵셔너리
}

export const BoardList: React.FC<BoardListProps> = ({
  srchData,
  setSrchData,
  setPage,
  boardList,
  pageCnt,
  getBoardList,
  boardSrchData,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathName = pathname.split('/')[1]

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handleSearch = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setSrchData((prev) => ({ ...prev, [name]: value }))
  }

  //글상세보기
  const handleDetailView = (nttSn: number) => {
    navigate(`/${pathName}/detail?nttSn=${nttSn}`, { state: boardSrchData })
  }

  // 검색 버튼 클릭 시 또는 Enter 키 입력 시 getBoardList 실행
  const handleSearchSubmit = () => {
    getBoardList(1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getBoardList(1)
    }
  }

  return (
    <>
      <BoardTable
        boardList={boardList}
        fieldNames={fieldNames}
        handleDetailView={handleDetailView}
      />
      <BoardSearch
        handleSearch={handleSearch}
        handleSearchSubmit={handleSearchSubmit}
        handleKeyDown={handleKeyDown}
        srchData={srchData}
      />
      {pageCnt > 0 && (
        <Pagination
          totalCount={pageCnt}
          onPageChange={handlePageChange}
          paramPage={boardSrchData?.page || 1}
        />
      )}
    </>
  )
}
