import { ChangeEvent, useEffect, useRef } from 'react'
import { BoardListItem, withDrawnCumtomer } from '../boardItem'
import { TInput } from './detailBoard'
import { DetailBoardInfo } from './detailBoardInfo'
import { DetailBoardReply } from './detailBoardReply'
import { Textarea } from '@/components'

interface DetailContentsProps {
  isDisabled: boolean
  boardDetail: BoardListItem | null
  height: string
  handleChange: (e: React.ChangeEvent<TInput>) => void
  isGuest: boolean
  scrollToComment: () => void
  handleCopy: () => void
}

export const DetailContents: React.FC<DetailContentsProps> = ({
  isDisabled,
  boardDetail,
  height,
  handleChange,
  isGuest,
  scrollToComment,
  handleCopy,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      console.log('Height adjusting...', textareaRef.current.scrollHeight)
      textareaRef.current.style.height = 'auto' // 높이 초기화
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // 새로운 높이 설정
    }
  }, [boardDetail?.nttCn]) // boardDetail.NTT_CN이 변경될 때마다 실행

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = e.target
    textarea.style.height = 'auto' // 높이 초기화
    textarea.style.height = `${textarea.scrollHeight}px` // 새로운 높이 설정
    handleChange(e) // 부모 컴포넌트의 handleChange 호출
  }

  return (
    <>
      {isDisabled ? (
        // 수정 불가 모드일 때는 그냥 텍스트로 보여주기
        <div
          style={{
            fontSize: '20px',
            fontWeight: '600',
            whiteSpace: 'pre-wrap', // 자동 줄바꿈
            wordWrap: 'break-word', // 긴 단어 줄바꿈
            overflow: 'hidden',
            marginBottom: '5px',
            marginTop: '5px',
          }}
        >
          {boardDetail?.nttSj}
        </div>
      ) : (
        // 수정 모드일 때는 textarea로 표시
        <textarea
          id="nttSj"
          name="nttSj"
          style={{
            border: 'none',
            padding: '0',
            margin: '0',
            fontSize: '20px',
            backgroundColor: 'transparent',
            wordWrap: 'break-word', // 긴 단어 줄바꿈
            whiteSpace: 'normal', // 자동 줄바꿈 허용
            lineHeight: '1.5', // 줄 간격 설정
            display: 'block', // block 요소로 설정
            width: '100%', // 부모 요소 너비에 맞추기
            resize: 'none', // 크기 조정 버튼 제거
            height: height, // 동적으로 높이를 설정
          }}
          value={boardDetail?.nttSj}
          disabled={isDisabled}
          onChange={handleInputChange}
        />
      )}
      <DetailBoardInfo
        isGuest={isGuest}
        boardDetail={boardDetail}
        scrollToComment={scrollToComment}
        handleCopy={handleCopy}
      />
      <div
        style={{
          boxShadow: '0 0 0 0.5px #ebecef',
          borderColor: '#ebecef',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      />
      <div
        style={{
          marginBottom: '10px',
          border: 'none',
          borderRadius: '4px',
          padding: '20px 0',
        }}
      >
        <textarea
          ref={textareaRef}
          id="nttCn"
          name="nttCn"
          value={
            boardDetail?.withDrawYn === 'Y'
              ? withDrawnCumtomer.contents
              : boardDetail?.nttCn
          }
          disabled={isDisabled}
          onChange={handleInputChange}
          style={{
            width: '100%',
            height: '400px',
            border: 'none', // 테두리 제거
            outline: 'none', // 포커스 시 테두리 제거
            borderRadius: '4px', // 모서리를 둥글게
            resize: 'none', // 사용자가 크기 조정 불가
            backgroundColor: 'transparent',
          }}
        >
          {boardDetail?.withDrawYn === 'Y'
            ? withDrawnCumtomer.contents
            : boardDetail?.nttCn}
        </textarea>
      </div>
    </>
  )
}
