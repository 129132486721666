import { BoardHeader } from '../_components/boardHeader'
import { getHeaderTitle } from '../boardItem'
import { BlogBoardList } from './blogBoardList'

const BlogBoard = () => {
  const headerTitle = getHeaderTitle(window.location.pathname)
  return (
    <>
      <BoardHeader title={headerTitle} />
      <BlogBoardList />
    </>
  )
}

export default BlogBoard
