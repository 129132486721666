import React, { useEffect, useState } from "react";
import { useAxios } from '@/context/axiosContext'
import { useNavigate } from "react-router-dom";
import { StyledWrap,  StyledDesc, StyledTitle, StyledConfirmButton, StyledCancelButton, StyledHighlight } from "./style";
import FormGroup from "@/components/forms/form-elements/form-group";
import { useAppSelector } from "@/redux/hooks";
import { useDialog } from "@/context/dialogContext";

interface Props {
    kmcResult:any,
}

const FindMemberId: React.FC<Props> = (
    {
        kmcResult = {
            ci : '',
        }
    }
) => {

    const axios = useAxios();
    const navigate = useNavigate();
    const { showAlert, } = useDialog();

    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const [isDone, setIsDone] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [isMember, setIsMember] = useState(false);


    const getMemberId = async () => {
        const response = await axios.post('/member/getMemberId', {ci : kmcResult.ci});
        if (response.data.common.resultCode === 'MB200') {
            setIsMember(true);
            setMemberId(response.data.data.result || '');
        }else{
            setIsMember(false);
        }
        setIsDone(true);
    };

    useEffect(() => {
        if(!isAuthenticated){
            getMemberId();
        }else{
            showAlert({
                type : 'warning',
                message : '잘못된 접근입니다.',
                onClose: () => {
                    navigate(-1);
                }
            });
        }
    }, []);

    return (
        isDone ?
        <StyledWrap>
            <StyledTitle>아이디 찾기</StyledTitle>
        {
            isMember && memberId != '' ? <>
            <StyledDesc>회원님의 ID는 <StyledHighlight>{memberId}</StyledHighlight> 입니다.</StyledDesc>
            <FormGroup mb="20px" margin={'AlignCenter'}>
                <StyledConfirmButton
                    color="neverbe"
                    onClick={() => {
                        navigate('/login');
                    }}>로그인</StyledConfirmButton>
                <StyledCancelButton
                    color="neverbe"
                    variant="outlined"
                    onClick={() => {
                        navigate('/member/management/pwd');
                    }}>비밀번호 재설정</StyledCancelButton>
            </FormGroup>
            </> : <>
            <StyledDesc>존재하지 않는 회원입니다.</StyledDesc>
            <FormGroup mb="20px" margin={'AlignCenter'}>
                <StyledConfirmButton
                    color="neverbe"
                    onClick={() => {
                        navigate('/member');
                    }}>회원가입</StyledConfirmButton>
                <StyledCancelButton
                    color="neverbe"
                    variant="outlined"
                    onClick={() => {
                        navigate('/main');
                    }}>메인</StyledCancelButton>
            </FormGroup>
            </>
        }
        </StyledWrap>:<></>
    );
}

export default FindMemberId;