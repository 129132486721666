import { useAxios } from '@/context/axiosContext'
import { useDialog } from '@/context/dialogContext'
import { Viewer } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { BlogDetailItem } from '../blogItem'
import { BoardHeader } from '../_components/boardHeader'
import {
  buttonName,
  confirmMessages,
  getBadgeLabel,
  getHeaderTitle,
  maskIpSuffix,
  successMessages,
  withDrawnCumtomer,
} from '../boardItem'
import { Badge, Button } from '@/components'
import commonUtils from '@/utils/commonUtils'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { useAppSelector } from '@/redux/hooks'
import { UpdateBlog } from './updateBlog'

const DetailBlog: React.FC = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const location = useLocation()
  const headerTitle = getHeaderTitle(window.location.pathname)
  const [isDisabled, setIsDisabled] = useState(true) // 초기값은 비활성화
  const [isEditMode, setIsEditMode] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const { showAlert, showConfirm } = useDialog()
  const [blogNttCn, setBlogNttCn] = useState<string>('')
  const [searchParams] = useSearchParams() // 쿼리 파라미터 사용
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const nttSn = Number(searchParams.get('nttSn')) // 게시판 번호
  const cmpnyCd = '001'
  const commentRef = useRef<HTMLDivElement>(null)
  const { user } = useAppSelector((state) => state.auth)
  const [imgUrls, setImgUrls] = useState<string[]>([])
  const [blogDetail, setBlogDetail] = useState<BlogDetailItem>({
    bbsSj: '',
    nttSn: 0,
    nttSj: '',
    nttCn: '',
    nickName: '',
    withDrawYn: '',
    regId: '',
    regDate: '',
    updId: '',
    updDate: '',
    accessIp: '',
    readngCnt: 0,
    answerCnt: 0,
  })

  useEffect(() => {
    getBlogDetail()
  }, [nttSn])

  const [fileList, setFileList] = useState<
    { fileSeq: number; fileName: string }[]
  >([])
  const withDrawYn = blogDetail?.withDrawYn === 'Y'
  const handleCopy = () => {
    const url = window.location.href // 현재 페이지 URL
    copyToClipboard(url)
  }

  const extractImageUrls = (htmlContent: string) => {
    const imgUrls = Array.from(
      new DOMParser()
        .parseFromString(htmlContent, 'text/html')
        .querySelectorAll('img'),
    ).map((img) => {
      const imgSrc = img.src
      const filePath = imgSrc.split('upload')[1]
      return decodeURIComponent(filePath)
    })
    return imgUrls
  }

  const getBlogDetail = async () => {
    try {
      const response = await axios.get(`/blog/detail`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { cmpnyCd: cmpnyCd, nttSn },
      })

      const { data } = response.data // 응답에서 data 추출

      // 호출 예시
      const htmlContent = data.blogDetail.nttCn // 서버에서 받은 HTML 콘텐츠
      const imgUrls = extractImageUrls(htmlContent)

      if (!data || !data.blogDetail) {
        return
      }

      const fileInfoList = data.fileList.map((file: any) => ({
        fileName: file.fileNm,
        fileSeq: file.fileSeq,
      }))

      setFileList(fileInfoList)
      setImgUrls(imgUrls)
      setBlogDetail(data.blogDetail)
    } catch (err: any) {
      console.error('Error fetching detailBlog:', err)
    }
  }

  const deleteBlog = async () => {
    try {
      await axios.post(`/blog/delete`, { cmpnyCd: cmpnyCd, nttSn })

      showAlert({
        type: 'success',
        title: successMessages.delete,
        btnName: buttonName.confirm,
        onClose() {
          setTimeout(() => {
            navigate(`/blog`)
          }, 100)
        },
      })
    } catch (err: any) {
      console.error('Error fetching detailBlog:', err)
    }
  }

  const handleModalAction = (type: 'update' | 'delete' | 'cancel') => {
    if (type === 'update') {
      showConfirm({
        title: confirmMessages.update,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          //navigate(`/blog/update?nttSn=${nttSn}`, { state: { blogDetail } })
          setIsEditMode(true)
        },
      })
    }

    if (type === 'delete') {
      showConfirm({
        title: confirmMessages.delete,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          deleteBlog()
        },
      })
    }

    if (type === 'cancel') {
      showConfirm({
        title: confirmMessages.cancel,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          setTimeout(() => {
            navigate(0) // 500ms 후에 페이지 이동
          }, 100)
        },
      })
    }
  }

  const scrollToComment = () => {
    commentRef.current?.scrollIntoView({
      behavior: 'smooth', // 부드러운 스크롤
    })
  }

  return (
    <>
      <BoardHeader title={headerTitle} />
      {isEditMode ? (
        <UpdateBlog
          nttSn={nttSn}
          nttSj={blogDetail.nttSj}
          nttCn={blogDetail.nttCn}
          bbsSj={blogDetail.bbsSj}
          detailFileList={fileList}
        />
      ) : (
        <div>
          <div
            style={{
              marginBottom: '10px',
              width: '100%',
              height: 'auto',
              padding: '29px 29px 0',
              borderRadius: '6px',
              border: '1px solid',
              borderColor: '#ebecef',
            }}
          >
            <Badge color="neverbe">
              {getBadgeLabel(blogDetail.bbsSj || '기타')}
            </Badge>
            <div
              style={{
                fontSize: '20px',
                fontWeight: '600',
                whiteSpace: 'pre-wrap', // 자동 줄바꿈
                wordWrap: 'break-word', // 긴 단어 줄바꿈
                overflow: 'hidden',
                marginBottom: '5px',
                marginTop: '5px',
              }}
            >
              {blogDetail.nttSj}
            </div>
            <div style={{ color: '#979797' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <div>
                    작성자 :&nbsp;
                    {withDrawYn ? withDrawnCumtomer.name : blogDetail?.nickName}
                    &nbsp; ({maskIpSuffix(blogDetail?.accessIp || '')})
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      작성일 :&nbsp;
                      {blogDetail?.regDate
                        ? commonUtils.formatDate(blogDetail.regDate, true)
                        : ''}
                    </div>
                    <div
                      style={{
                        margin: '0 8px',
                        borderLeft: '1px solid #ddd',
                        height: '16px',
                      }}
                    />
                    <div>조회 : {blogDetail?.readngCnt}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                    onClick={handleCopy}
                  >
                    URL복사
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow: '0 0 0 0.5px #ebecef',
                borderColor: '#ebecef',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
            <div
              style={{
                marginBottom: '10px',
                border: 'none',
                borderRadius: '4px',
                padding: '20px 0',
              }}
            >
              <Viewer key={blogDetail.nttSn} initialValue={blogDetail.nttCn} />
            </div>
          </div>
          <div
            ref={commentRef}
            style={{ display: 'flex', marginBottom: '10px' }}
          >
            <div style={{ marginLeft: 'auto' }}>
              {isDisabled ? (
                <div style={{ display: 'flex', gap: '5px' }}>
                  {user?.cstId === blogDetail?.regId && (
                    <>
                      <Button
                        color="neverbe"
                        onClick={() => handleModalAction('update')}
                      >
                        수정
                      </Button>
                      <Button
                        color="neverbe"
                        onClick={() => handleModalAction('delete')}
                      >
                        삭제
                      </Button>
                    </>
                  )}
                  <Button
                    color="neverbe"
                    onClick={() => navigate(`/blog`, { state: location.state })}
                  >
                    목록
                  </Button>
                </div>
              ) : (
                <div style={{ display: 'flex', gap: '5px' }}>
                  <Button
                    color="neverbe"
                    onClick={() => handleModalAction('cancel')}
                  >
                    취소
                  </Button>
                  <Button
                    color="neverbe"
                    onClick={() => navigate(`/blog`, { state: location.state })}
                  >
                    목록
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DetailBlog
