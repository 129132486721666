import { BoardHeader } from '../_components/boardHeader'
import { QnaBoardList } from './qnaBoardList'
import { getHeaderTitle } from '../boardItem'

const QnaBoard: React.FC = () => {
  const headerTitle = getHeaderTitle(window.location.pathname)
  return (
    <>
      <BoardHeader title={headerTitle} />
      <QnaBoardList />
    </>
  )
}

export default QnaBoard
