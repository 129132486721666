import React from 'react'
import { NoticeList } from './_components/noticeList'
import { NoticeHeader } from './_components/noticeHeader'

const Notice: React.FC = () => {
  return (
    <>
      <NoticeHeader title={'공지사항'} />
      <NoticeList />
    </>
  )
}

export default Notice
