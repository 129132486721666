import commonUtils from '@/utils/commonUtils'
import { CommentListItem, withDrawnCumtomer } from '../boardItem'
import './customerComment.css' // CSS 파일 import
type ModalAction = 'insert' | 'delete' | 'update'

interface CommentProps {
  commentList: CommentListItem[]
  handleCommentAction: (action: ModalAction, item?: any) => void
  isAuthenticated: boolean
  userCstId?: string
}

export const CustomerComment: React.FC<CommentProps> = ({
  commentList,
  handleCommentAction,
  isAuthenticated,
  userCstId,
}) => {
  const customerComments = commentList.filter(
    (comment) => comment.regId !== 'ADMIN',
  )

  return (
    <>
      {customerComments.map((item) => (
        <>
          <div key={item.commentSn} className="chat-container">
            <div className="speech-bubble">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="chat-title" style={{ fontWeight: 'bold' }}>
                  {item.withDrawYn === 'Y'
                    ? withDrawnCumtomer.name
                    : item.nickName}
                </div>
                <div>{commonUtils.formatDate(item.regDate, true)}</div>
              </div>
              <div className="chat-content">
                {item.withDrawYn === 'Y'
                  ? withDrawnCumtomer.contents
                  : item.commentCn}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  color: '#979797',
                  cursor: 'pointer',
                }}
              >
                {isAuthenticated && userCstId === item.cstId && (
                  <div
                    onClick={
                      () => handleCommentAction('delete', item.commentSn) // item을 전달하여 선택된 댓글 정보를 저장
                    }
                  >
                    삭제
                  </div>
                )}
              </div>
            </div>
            <div className="avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
          </div>
        </>
      ))}
    </>
  )
}
