import React, { useEffect, useRef, useState } from 'react'
import { Button, DropdownItem, DropdownMenu } from '@/components'
import { useAxios } from '@/context/axiosContext'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { logout } from '@/redux/slices/auth'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { LogOut } from 'react-feather'

import { useDialog } from '@/context/dialogContext'
import { themeGet } from '@/styles/styled'

const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const HeaderAuth: React.FC = () => {
  const axios = useAxios()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { showAlert } = useDialog()

  const [showMenu, setShowMenu] = useState(false)
  const toggleRef = useRef<any>(null)

  const handleLogout = async () => {
    try {
      await axios.post('/auth/logout')
      dispatch(logout())
      navigate('/')
    } catch (error) {
      console.error('로그아웃 실패:', error)
      showAlert({
        type: 'error',
        title: '잠시 후 다시 시도해 주세요.',
        message: '',
      })
    }
  }

  const handleClickOutside = (e: any) => {
    if (
      toggleRef.current &&
      (!toggleRef.current.contains(e.target) ||
        e.target.className.indexOf('dropdown-item') > -1)
    ) {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <AuthContainer>
      {isAuthenticated ? (
        <>
          <span
            ref={toggleRef}
            style={{ fontWeight: 'bold', position: 'relative' }}
          >
            <div
              onClick={() => {
                setShowMenu(!showMenu)
              }}
              style={{
                cursor: 'pointer',
                display: 'inline-block',
                color: '#0168fa',
              }}
            >
              {user?.name ?? '사용자'}
            </div>
            님 환영합니다.
            <DropdownMenu show={showMenu}>
              <DropdownItem path="/mypage">회원정보수정</DropdownItem>
              <DropdownItem path="/mypage/pwd">비밀번호재설정</DropdownItem>
              <DropdownItem path="/mypage/withdraw">회원탈퇴</DropdownItem>
            </DropdownMenu>
          </span>
          <Button
            height="38px"
            borderRadius="24px"
            color="white"
            onClick={handleLogout}
          >
            로그아웃
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '8px',
              }}
            >
              <LogOut size={16} />
            </span>
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => navigate('/login')}
            borderRadius="24px"
            color="white"
          >
            <span style={{ color: '#333333', fontWeight: 'bold' }}>로그인</span>
          </Button>
          <Button
            onClick={() => navigate('/member')}
            borderRadius="24px"
            color="neverbe"
          >
            회원가입
          </Button>
        </>
      )}
    </AuthContainer>
  )
}

export default HeaderAuth
