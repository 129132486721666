import { Editor } from '@toast-ui/react-editor'
import { useEffect, useRef, useState } from 'react'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import '@toast-ui/editor/dist/i18n/ko-kr'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import { Button, Input, Select } from '@/components'
import { useDialog } from '@/context/dialogContext'
import {
  buttonName,
  confirmMessages,
  errorMessages,
  fieldMessages,
  successMessages,
} from '../boardItem'
import { BlogListItem, FileUploadResponse } from '../blogItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { useAppSelector } from '@/redux/hooks'
import { options, TInput } from './blogEditor'

type UpdateProps = {
  bbsSj: string
  nttSn: number
  nttSj: string
  nttCn: string
  detailFileList: { fileSeq: number; fileName: string }[]
}
export const UpdateBlog: React.FC<UpdateProps> = ({
  bbsSj,
  nttSn,
  nttSj,
  nttCn,
  detailFileList,
}) => {
  const editorRef = useRef<Editor>(null)
  const axios = useAxios()
  const { user } = useAppSelector((state) => state.auth)
  const { showAlert, showConfirm } = useDialog()
  const navigate = useNavigate()
  const [fileList, setFileList] = useState<
    { fileSeq: number; fileName: string }[]
  >([])
  const [beforFileNameList, setBeforFileNameList] = useState<string[]>([])
  const [newFileSeqList, setNewFileSeqList] = useState<number[]>([])
  const [newFileNameList, setNewFileNameList] = useState<string[]>([])
  const [formData, setFormData] = useState<BlogListItem>({
    cmpnyCd: '001',
    cstId: user?.cstId || '',
    nttSn: nttSn,
    regId: user?.cstId || '',
    regDate: '',
    updId: user?.cstId || '',
    updDate: '',
    bbsTy: '01',
    bbsSj: bbsSj,
    nttSj: nttSj,
    nttCn: nttCn,
    page: 1,
    srchOpt: '',
    srchStr: '',
    option: '',
    accessIp: '',
    fileSeqList: newFileSeqList,
    fileNameList: newFileNameList,
    deleteFileSeqList: [],
  })

  useEffect(() => {
    setFileList(detailFileList)
    setBeforFileNameList(detailFileList.map((file) => file.fileName))
  }, [detailFileList])

  console.log('fileSeqList', newFileSeqList)
  console.log('fileNameList', newFileNameList)

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current
        .getInstance()
        .addHook(
          'addImageBlobHook',
          async (
            blob: Blob,
            callback: (url: string, altText: string) => void,
          ) => {
            const { fileSeq, fileUrl } = await uploadImageToServer(blob)

            if (fileUrl) {
              callback(fileUrl, '')
            }

            return false
          },
        )
    }
  }, [])

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      fileSeqList: newFileSeqList,
      fileNameList: newFileNameList,
    }))
  }, [newFileSeqList, newFileNameList]) // fileSeqList가 변경될 때마다 formData를 업데이트

  // 입력된 html 정보에서 img url 추출
  const getImagesFromEditor = (editorInstance: any) => {
    const htmlContent = editorInstance.getHTML() // 에디터의 HTML 콘텐츠 가져오기
    const imgUrls = Array.from(
      new DOMParser()
        .parseFromString(htmlContent, 'text/html')
        .querySelectorAll('img'),
    ).map((img) => {
      const imgSrc = img.src
      const filePath = imgSrc.split('upload')[1]
      return decodeURIComponent(filePath)
    })

    return imgUrls
  }

  // 기존 이미지 중 삭제해야 할 이미지 찾기
  const compareAndDeleteImages = (
    oldFileName: string[],
    newFileName: string[],
  ) => {
    // newFileName에서 파일명만 추출
    const newFileNamesWithoutPath = newFileName.map((filePath) => {
      return filePath.split('/').pop() // 경로에서 파일명만 추출
    })

    // oldFileName과 비교하여 삭제할 이미지 추출
    const imagesToDelete = oldFileName.filter(
      (fileNm) => !newFileNamesWithoutPath.includes(fileNm),
    )

    // 삭제할 이미지들의 seq를 찾기
    const deleteImgSeqs = imagesToDelete
      .map((fileName) => {
        const file = detailFileList.find((file) => file.fileName === fileName)
        return file ? file.fileSeq : null // fileSeq가 없으면 null
      })
      .filter((seq): seq is number => seq !== null) // 타입 가드 사용

    return deleteImgSeqs
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleEditorChange = () => {
    const editorNttCn = editorRef.current?.getInstance()
    if (editorNttCn) {
      const nttCn = editorNttCn.getHTML() // 에디터에서 입력한 HTML 내용 가져오기
      setFormData((prev) => ({ ...prev, nttCn }))
    }
  }

  const uploadImageToServer = async (
    blob: Blob,
  ): Promise<{ fileUrl: string; fileSeq: number }> => {
    const formData = new FormData()
    formData.append('file', blob)

    try {
      const response = await axios.post<{ data: FileUploadResponse }>(
        '/blog/fileUpload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )

      const fileUrl =
        process.env.REACT_APP_BASE_URL + '/upload/' + response.data.data.fileNm
      const fileSeq = response.data.data.fileSeq
      const fileName = response.data.data.fileNm

      // 새로추가하는 이미지
      setNewFileSeqList((prevList) => [...prevList, fileSeq])
      setNewFileNameList((prevList) => [...prevList, fileName])
      return { fileUrl, fileSeq }
    } catch (error) {
      console.error('이미지 업로드 실패:', error)
      return { fileUrl: '', fileSeq: 0 }
    }
  }

  const handleModalAction = (type: 'update' | 'cancel') => {
    if (type === 'update') {
      // const editorNttCn = editorRef.current?.getInstance()
      // const htmlContent = editorNttCn?.getHTML()

      // // 수정시 빈값이어도 <p></br><p> 기본으로 입력 되서, replace 처리 함
      // if (
      //   !htmlContent ||
      //   htmlContent
      //     .replace(/<p><br><\/p>/g, '')
      //     .replace(/<br\s*\/?>/g, '')
      //     .replace(/<p><\/p>/g, '')
      //     .trim() === ''
      // ) {
      //   // 필수 입력값 확인
      //   showAlert({
      //     type: 'warning',
      //     message: fieldMessages.nttCn,
      //     btnName: '확인',
      //   })
      //   return
      // }

      const requiredFields: { field: keyof BlogListItem; message: string }[] = [
        { field: 'bbsSj', message: fieldMessages.bbsSj },
        { field: 'nttSj', message: fieldMessages.nttSj },
        { field: 'nttCn', message: fieldMessages.nttCn },
      ]

      // 필수 입력값 확인
      for (const { field, message } of requiredFields) {
        if (!formData[field]) {
          showAlert({
            type: 'warning',
            message: message,
            btnName: '확인',
          })
          return
        }
      }

      showConfirm({
        title: confirmMessages.insert,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          updateBlog()
        },
      })
    }

    if (type === 'cancel') {
      showConfirm({
        title: confirmMessages.cancel,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          navigate(0)
        },
      })
    }
  }

  const updateBlog = async () => {
    const editorInstance = editorRef.current?.getInstance() // 에디터 인스턴스 가져오기
    const newFileNameList = getImagesFromEditor(editorInstance) // 이미지 URL 추출

    // 삭제할 이미지 목록을 받아옴
    const deleteImgSeqs = compareAndDeleteImages(
      beforFileNameList,
      newFileNameList,
    )

    setFormData((prevFormData) => ({
      ...prevFormData,
      deleteFileSeqList: deleteImgSeqs,
      fileNameList: newFileNameList,
    }))

    try {
      await axios.post(`/blog/update`, {
        ...formData,
        deleteFileSeqList: deleteImgSeqs, //최신값 반영
      })

      showAlert({
        type: 'success',
        title: successMessages.update,
        btnName: buttonName.confirm,
        onClose() {
          setTimeout(() => {
            navigate(0)
          }, 100)
        },
      })
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: errorMessages.update,
        message: err.response?.data?.message,
        btnName: buttonName.confirm,
      })
    }
  }

  return (
    <div>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          gap: '5px',
        }}
      >
        <Select
          id="bbsSj"
          name="bbsSj"
          width="200px"
          value={formData.bbsSj}
          onChange={handleChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Input
          id="nttSj"
          name="nttSj"
          placeholder="제목을 입력하세요."
          value={formData.nttSj}
          onChange={handleChange}
        />
      </div>
      <Editor
        ref={editorRef}
        initialValue={formData.nttCn}
        previewStyle="vertical"
        useCommandShortcut={true}
        placeholder="내용을 입력해 주세요!"
        height="500px" // 에디터 창 높이
        initialEditType="wysiwyg" // 초기 입력모드 설정 (기본 markdown)
        language="ko-KR"
        hideModeSwitch={true} // 하단의 타입 선택 탭 숨기기
        plugins={[colorSyntax, codeSyntaxHighlight]}
        onChange={handleEditorChange}
      />

      <div style={{ display: 'flex', marginBottom: '10px', marginTop: '10px' }}>
        <div style={{ marginLeft: 'auto' }}>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button color="neverbe" onClick={() => handleModalAction('update')}>
              저장
            </Button>
            <Button color="neverbe" onClick={() => handleModalAction('cancel')}>
              취소
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
