import { useDialog } from "@/context/dialogContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Survey:React.FC = ()=>{
// TODO 임시 블록기능
  const { showAlert } = useDialog();
  const navigate = useNavigate();
  useEffect(() => {
    showAlert({
      type:'warning',
      message: '서비스 준비중입니다.',
      // title?: string | React.ReactNode
      btnName: '확인',
      onClose: () => {
        navigate(-1);
      }
  });
  }, []);
    return  (<></>);

}

export default Survey;