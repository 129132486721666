import { useAxios } from '@/context/axiosContext'
import { useDialog } from '@/context/dialogContext'
import { useAppDispatch } from '@/redux/hooks'
import { login } from '@/redux/slices/auth'
import { useLocation, useNavigate } from 'react-router-dom'

export interface IFormValues {
  userId: string
  password: string
}

export const useLogin = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { showAlert, showConfirm } = useDialog()

  const handleLogin = async (data: IFormValues) => {
    const reqData: IFormValues = {
      userId: data.userId,
      password: data.password,
    }

    try {
      const response = await axios.post('/auth/login', reqData)
      if (response.data.common.resultCode === 'L0200') {
        console.log('Login Success', response.data.data)
        dispatch(login({ ...response.data.data }))

        const url = location.state?.redirectTo || '/'
        navigate(url)
      } else {
        showAlert({ title: response.data.common.resultMsg })
      }
    } catch (error: any) {
      const { code, message } = error

      console.error('Login request failed:', error)

      // if (code === 'NETWORK_ERROR') {
      // showAlert({
      //   title:
      //     message || '일시적인 오류가 발생했어요. 잠시 후 다시 시도해 주세요.',
      // })
      // }

      showAlert({
        title:
          message || '일시적인 오류가 발생했어요. 잠시 후 다시 시도해 주세요.',
      })
    }
  }

  return { handleLogin }
}
