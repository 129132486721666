import React, { createContext, useContext, useState } from 'react'
import Alert from '@/components/dialog/Alert'
import Confirm from '@/components/dialog/Confirm'

export interface AlertProps {
  type?: 'success' | 'warning' | 'error'
  message?: string | React.ReactNode
  title?: string | React.ReactNode
  btnName?: string
  onClose?: () => void
}

export interface ConfirmProps {
  message?: string | React.ReactNode
  title?: string | React.ReactNode
  confirmBtnName?: string
  cancelBtnName?: string
  onConfirm?: () => void
  onCancel?: () => void
}

interface DialogContextType {
  showAlert: (params: AlertProps) => void
  showConfirm: (params: ConfirmProps) => void
}

const DialogContext = createContext<DialogContextType | undefined>(undefined)

export const useDialog = (): DialogContextType => {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error('현재 Dialog')
  }
  return context
}

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertData, setAlertData] = useState<AlertProps | null>(null)
  const [confirmData, setConfirmData] = useState<ConfirmProps | null>(null)

  const showAlert = (params: AlertProps) => {
    const { type, message, title, btnName, onClose } = params
    const resolvedTitle =
      title ||
      (type === 'success' ? 'Notice' : type === 'warning' ? 'Warning' : 'Error')

    setAlertData({
      type,
      message,
      title: resolvedTitle as string | React.ReactNode,
      btnName,
      onClose,
    })
  }

  const showConfirm = (params: ConfirmProps) => {
    const {
      title,
      message,
      confirmBtnName,
      cancelBtnName,
      onConfirm,
      onCancel,
    } = params

    const resolvedTitle = title || 'Notice'

    setConfirmData({
      title: resolvedTitle as string | React.ReactNode,
      message,
      confirmBtnName,
      cancelBtnName,
      onConfirm,
      onCancel,
    })
  }

  const handleClose = () => {
    if (alertData?.onClose) {
      alertData.onClose()
    }
    setAlertData(null)
  }

  const handleConfirm = () => {
    if (confirmData?.onConfirm) {
      confirmData.onConfirm()
    }
    setConfirmData(null)
  }

  const handleCancel = () => {
    if (confirmData?.onCancel) {
      confirmData.onCancel()
    }
    setConfirmData(null)
  }

  return (
    <DialogContext.Provider value={{ showAlert, showConfirm }}>
      {children}
      {alertData && (
        <Alert
          type={alertData.type || 'error'}
          message={alertData.message}
          title={alertData.title}
          btnName={alertData.btnName || '확인'}
          onClose={handleClose}
        />
      )}
      {confirmData && (
        <Confirm
          title={confirmData.title}
          message={confirmData.message}
          confirmBtnName={confirmData.confirmBtnName || '확인'}
          cancelBtnName={confirmData.cancelBtnName || '취소'}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </DialogContext.Provider>
  )
}
