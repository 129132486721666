import { YoutubeRankData } from '@/pages/youtube/youtubeRankItem'
import './mainYoutube.css'
import { useDialog } from '@/context/dialogContext'
import { useAppSelector } from '@/redux/hooks'
import { useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Autoplay, Grid, Navigation, Pagination } from 'swiper/modules'

interface ListItemProps {
  data: YoutubeRankData[]
  setLikeStatus?: (
    formData: FormData,
    chnlId: string,
    currentLikeStatus: string,
  ) => void
}

export const MainYoutubeListItem: React.FC<ListItemProps> = ({
  data,
  setLikeStatus,
}) => {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth)
  const { showAlert } = useDialog()

  const [likesState, setLikesState] = useState(
    data.reduce(
      (acc, item) => {
        acc[item.chnlId] = isAuthenticated ? item.userLikeYn || 'N' : 'N' // 'Y'면 좋아요, 'N'이면 좋아요 없음
        return acc
      },
      {} as Record<string, string>,
    ),
  )

  const formatSubscribers = (num: number): string => {
    if (num >= 10000) {
      return (num / 10000).toFixed(1) + '만' // 반올림
    }
    return num.toLocaleString()
  }

  const formatVideos = (num: number): string => {
    if (num >= 1000) {
      return Math.floor(num / 100) / 10 + '천' // 내림 (예: 1576 → 1.5천)
    }
    return num.toLocaleString()
  }

  const openYouTubeChannel = (chnlId: string) => {
    const url = `https://www.youtube.com/channel/${chnlId}`
    window.open(url, '_blank')
  }

  const handleClick = (chnlId: string) => {
    if (!isAuthenticated) {
      showAlert({
        type: 'error',
        title: '로그인 후 좋아요를 눌러주세요.',
      })
      return
    }
    const newLikesYn = likesState[chnlId] === 'Y' ? 'N' : 'Y'

    setLikesState((prevState) => ({
      ...prevState,
      [chnlId]: newLikesYn,
    }))

    if (user?.cstId) {
      const formData = new FormData()
      formData.append('cstId', user.cstId)
      formData.append('chnlId', chnlId)
      formData.append('likesYn', newLikesYn)
      setLikeStatus?.(formData, chnlId, likesState[chnlId])
    } else {
      showAlert({
        type: 'error',
        title: '로그인 후 좋아요를 눌러주세요.',
      })
    }
  }
  return (
    <>
      <div style={{ margin: '5px' }}>
        <Swiper
          slidesPerView={1} // 한 번에 1개 슬라이드만 보여줍니다.
          slidesPerGroup={1} // 1개씩 그룹으로 묶어 슬라이드되게 설정
          loop={true} // 슬라이드가 반복되도록 설정
          direction="horizontal" // 가로 방향으로 슬라이드
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          className="swiper-container"
          modules={[Autoplay]}
        >
          {data.map((item, index) => {
            // 5개씩 그룹을 묶기 위해 index가 5의 배수일 때만 슬라이드를 그룹화
            if (index % 5 === 0) {
              return (
                <SwiperSlide key={index}>
                  <div className="swiper-slide-group">
                    {data.slice(index, index + 5).map((item) => (
                      <div className="ranking-item" key={item.chnlId}>
                        <div className="rank">{item.chnlRank}</div>
                        <div
                          className="logo"
                          onClick={() => openYouTubeChannel(item.chnlId)}
                        >
                          <img src={item.thumbnailsDefault} alt={''} />
                        </div>
                        <div
                          className="channel-info"
                          onClick={() => openYouTubeChannel(item.chnlId)}
                        >
                          <div className="channel-name">{item.title}</div>
                          <div className="subscribers">
                            {`구독자 ${formatSubscribers(item.subscriberCount)}명 | `}
                            {`동영상 ${formatVideos(item.videoCount)}개`}
                          </div>
                        </div>
                        <div className="likes">
                          <svg
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleClick(item.chnlId)}
                            width="30"
                            height="30"
                          >
                            <g data-name="Layer 1" id="Layer_1">
                              <path
                                d="M16 29s13-8.35 13-14.15c0-5.03-3.4-8.66-7.5-8.66-2.86 0-4.91 2.11-5.5 3.56-0.59-1.45-2.64-3.56-5.5-3.56-4.1 0-7.5 3.63-7.5 8.66C3 20.65 16 29 16 29z"
                                fill={
                                  likesState[item.chnlId] === 'Y'
                                    ? '#ff0000'
                                    : 'none'
                                } // likesYn에 따라 색상 변경
                                stroke={
                                  likesState[item.chnlId] === 'Y'
                                    ? 'none'
                                    : '#101820'
                                }
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="like-count">
                          {formatVideos(item.likeCount)}
                        </div>
                      </div>
                    ))}
                  </div>
                </SwiperSlide>
              )
            }
            return null
          })}
        </Swiper>
      </div>
    </>
  )
}
