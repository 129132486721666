import { Badge, Table } from '@/components'
import {
  BoardListItem,
  getBadgeLabel,
  searchMessages,
  withDrawnCumtomer,
} from '../boardItem'
import commonUtils from '@/utils/commonUtils'
import numberUtils from '@/utils/numberUtils'

interface BoardTableProps {
  boardList: BoardListItem[]
  fieldNames?: string[]
  handleDetailView: (nttSn: number) => void
}

const commonStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center' as const,
}

export const BoardTable: React.FC<BoardTableProps> = ({
  boardList,
  fieldNames,
  handleDetailView,
}) => {
  console.log('BoardTable', boardList)
  return (
    <Table
      hover={true}
      bordered={true}
      style={{ width: '100%', tableLayout: 'auto', overflow: 'hidden' }}
    >
      <thead>
        {fieldNames?.map((header, index) => (
          <th key={index} scope="col" style={commonStyle}>
            {header}
          </th>
        ))}
      </thead>
      <tbody>
        {boardList.length > 0 ? (
          boardList.map((item) => (
            <tr key={item.nttSn}>
              <td style={{ textAlign: 'right', width: '30px' }}>
                {item.nttSn}
              </td>
              <td
                style={{
                  textAlign: 'left',
                  width: 'auto',
                  cursor: 'pointer',
                  maxWidth: '200px', // 최대 너비 설정
                  overflow: 'hidden', // 넘치는 텍스트 숨기기
                  whiteSpace: 'nowrap', // 텍스트를 한 줄로 유지
                  textOverflow: 'ellipsis', // 말줄임표로 표시
                }}
                onClick={() => handleDetailView(item.nttSn)}
              >
                <Badge color="neverbe">{getBadgeLabel(item.bbsSj)}</Badge>
                &nbsp;&nbsp;
                {item.nttSj}
                {item.answerCnt ? ` [${item.answerCnt}]` : ''}
                &nbsp;&nbsp;
                {item.replyYn === 'Y' && (
                  <Badge color="success">답변완료</Badge>
                )}
              </td>
              <td style={{ textAlign: 'center', width: '100px' }}>
                <div
                  style={{
                    textAlign: 'left',
                    maxWidth: '100px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.withDrawYn === 'Y'
                    ? withDrawnCumtomer.name
                    : item.cstId === ''
                      ? item.guestName || '비회원'
                      : item.nickName}
                </div>
              </td>
              <td style={{ textAlign: 'center', width: '50px' }}>
                {commonUtils.formatDate(item.regDate, false)}
              </td>
              <td style={{ textAlign: 'right', width: '40px' }}>
                {numberUtils.formatViewCount(item.readngCnt)}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5} style={{ textAlign: 'center' }}>
              {searchMessages.noItem}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
