import styled from 'styled-components'

import aboutImage03 from '@/assets/images/about/about_03.png'
import aboutImage04 from '@/assets/images/about/about_04.png'
import aboutImage05 from '@/assets/images/about/about_05.png'
import { SEO } from '@/components/seo/seo'

const AboutUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
`

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  gap: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  `
  // @media (max-width: 768px) {
  //   flex-direction: column;
  //   gap: 10px;
  //   margin-bottom: 40px;
  // }
const ImageContainer = styled.div`
  width: 400px;
  min-width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  `
  // @media (max-width: 768px) {
  //   width: 100%;
  //   height: auto;
  // }

const TextContainer = styled.div`
  flex-grow: 1;

  h3 {
    font-weight: bold;
    color: #343a40;
    margin-bottom: 10px;
    font-size: 1.4rem;
    ${
      /* @media (max-width: 768px) {
        text-align: center;
      } */''
    }
  }

  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #495057;
    word-wrap: break-word;
    white-space: normal;
    text-align: justify;
    ${
      /* @media (max-width: 768px) {
        font-size: 1rem
        text-align: center;
      } */''
    }
  }
`

const AboutUs = () => {
  const sections = [
    {
      title: '골프 정보의 모든 것, 한곳에',
      text: '국내 주요 골프장 정보와 실시간 그린피 시세를 제공하며, 골프를 사랑하는 유저들이 소통할 수 있는 커뮤니케이션 서비스를 통해 골프 문화를 선도합니다.',
      imgSrc: aboutImage04,
    },
    {
      title: '신뢰할 수 있는 골프 시세 정보',
      text: '투명하고 정확한 데이터를 기반으로 그린피 시세를 실시간으로 제공합니다. 골프 관련 중요한 결정을 내리는 데 필요한 모든 정보를 제공합니다.',
      imgSrc: aboutImage05,
    },
    {
      title: '골퍼들을 위한 커뮤니티',
      text: '골프를 사랑하는 유저들이 모여 정보를 나누고 소통할 수 있는 공간을 제공합니다. 경험과 노하우를 공유하며 더 나은 골프 라이프를 만들어갑니다.',
      imgSrc: aboutImage03,
    },
  ]

  return (
    <>
      <SEO title="About Us" />
      <AboutUsContainer>
        {sections.map((section, index) => (
          <Section key={index}>
            <ImageContainer>
              <img src={section.imgSrc} alt={section.title} />
            </ImageContainer>
            <TextContainer>
              <h3>{section.title}</h3>
              <p>{section.text}</p>
            </TextContainer>
          </Section>
        ))}
      </AboutUsContainer>
    </>
  )
}

export default AboutUs
