import React, { useEffect, useState } from "react";
import { StyledButton, StyledConfirmDesc, StyledConfirmTitle, StyledConfirmWrap, StyledDesc, StyledTitle, StyledWrap } from "./style";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAxios } from '@/context/axiosContext'
import commonUtils from '@/utils/commonUtils';
import MypageForm from "./mypageForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDialog } from "@/context/dialogContext";
import { useAppDispatch } from "@/redux/hooks";
import { logout } from '@/redux/slices/auth';
import { FormGroup, Input } from "@/components";
import ResetMemberPwd from "../../management/_components/resetMemberPwd";

const Mypage: React.FC = () => {

    const axios = useAxios();
    const { type } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { showAlert, showConfirm } = useDialog();

    const [userInfoConfirmDone, setUserInfoConfirmDone] = useState(false);
    const [userInfo, setUserInfo] = useState<any>(null);

    const {
        register: confirmRegister,
        handleSubmit: confirmMemberInfo,
        formState: { errors: confirmError },
        reset,
    } = useForm<any>();

    const confirmMemberInfoSubmit: SubmitHandler<any> = async (data) => {
        const reqData: any = {
            password: data.password,
        }

        const response = await axios.post('/member/confirmMemberInfo', reqData);
        if (response.data.common.resultCode === 'L0200') {
            setUserInfoConfirmDone(true);
            if(!type){
                getUserInfo();
            }else if(type == 'pwd'){
                // component change 
            }else if(type == 'withdraw'){
                showConfirm({
                    message : '회원 탈퇴 하시겠습니까?',
                    onConfirm : () => {
                        withdraw();
                    },
                    onCancel : () => {
                        setUserInfoConfirmDone(false);
                        // TODO 입력값 초기화? 
                    }
                });
            }else{
                showAlert({
                    type : 'warning',
                    message : '잘못된 접근입니다.',
                    onClose : () => {
                        setUserInfoConfirmDone(false);
                        reset();
                    }
                });
            }
        } else {
            showAlert({
                type : 'error',
                message : response.data.common.resultMsg,
                title :response.data.common.resultCode,
            });
        }
    };

    const getUserInfo = async () => {
        const response = await axios.post('/member/getMemberInfo', {});
        if (response.data.common.resultCode === 'MB200') {
            setUserInfo(response.data.data.info || {});
        } else {
            showAlert({
                type : 'error',
                message : response.data.common.resultMsg,
                title :response.data.common.resultCode,
            });

            setUserInfoConfirmDone(false);
            reset();
        }
    };

    const withdraw = async () => {
        const response = await axios.post('/member/withdraw', {});
        if (response.data.common.resultCode === 'MB200') {
            showAlert({
                type : 'success',
                message : '탈퇴가 완료되었습니다.',
                onClose : async () =>  {
                    await axios.post('/auth/logout');
                    dispatch(logout());
                    navigate('/');
                }
            });

        } else {
            showAlert({
                type : 'error',
                message : response.data.common.resultMsg,
                title : response.data.common.resultCode,
                onClose : () => {
                    setUserInfoConfirmDone(false);
                    reset();
                }
            });
        }
    }

    useEffect(() => {
        setUserInfoConfirmDone(false);
        reset();
    }, [type]);

    return (
        !userInfoConfirmDone ?
            <StyledConfirmWrap>
                <StyledConfirmTitle>
                    회원정보확인
                </StyledConfirmTitle>
                <StyledConfirmDesc>정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 확인 합니다.</StyledConfirmDesc>
                <form onSubmit={confirmMemberInfo(confirmMemberInfoSubmit)} noValidate>
                    <FormGroup mb="16px">
                        <Input
                            id="password"
                            type="password"
                            placeholder="비밀번호 입력"
                            feedbackText={confirmError?.password?.message?.toString()}
                            state={commonUtils.hasKey(confirmError, 'password') ? 'error' : 'success'}
                            showState={!!commonUtils.hasKey(confirmError, 'password')}
                            {...confirmRegister('password', {
                                required: '비밀번호를 입력하세요.',
                            })}
                            height="56px"
                        />
                    </FormGroup>
                    <StyledButton type="submit">확인</StyledButton>
                </form>
            </StyledConfirmWrap> :
            !type && userInfo != null ? // 회원정보 변경
            <MypageForm data={userInfo} /> :
            type == 'pwd' ?             // 비밀번호 재설정 
            <ResetMemberPwd kmcResult={null}/> :<></>
    );
}

export default Mypage;