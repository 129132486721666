import { Button } from '@/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { BoardListItem } from '../boardItem'
import { useEffect } from 'react'
import { useAppSelector } from '@/redux/hooks'

interface DetailBoardButtonProps {
  commentRef: React.RefObject<HTMLDivElement>
  isDisabled: boolean
  boardDetail: BoardListItem | null
  handleEditMode: () => void
  handleModalAction: (type: 'update' | 'delete' | 'cancel' | 'save') => void
  pathName: string
  userId: string | undefined
}

export const DetailBoardButton: React.FC<DetailBoardButtonProps> = ({
  commentRef,
  isDisabled,
  boardDetail,
  handleEditMode,
  handleModalAction,
  pathName,
  userId,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAppSelector((state) => state.auth)

  return (
    <div ref={commentRef} style={{ display: 'flex', marginBottom: '10px' }}>
      <div style={{ marginLeft: 'auto' }}>
        {isDisabled ? (
          <div style={{ display: 'flex', gap: '5px' }}>
            {/* TODO 등록자에 2025.03.11 16시 이후로 cstId 값이 들어가도록 바꿔놔서 방어로직 추가했습니다. 
              코드 이대로 유지할지는 추후에 고민
            */}
            {(user?.userId === boardDetail?.regId || user?.cstId === boardDetail?.regId)&& (
              <>
                <Button color="neverbe" onClick={handleEditMode}>
                  수정
                </Button>
                <Button
                  color="neverbe"
                  onClick={() => handleModalAction('delete')}
                >
                  삭제
                </Button>
              </>
            )}
            <Button
              color="neverbe"
              onClick={() =>
                navigate(`/${pathName}`, { state: location.state })
              }
            >
              목록
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '5px' }}>
            <Button color="neverbe" onClick={() => handleModalAction('save')}>
              저장
            </Button>
            <Button color="neverbe" onClick={() => handleModalAction('cancel')}>
              취소
            </Button>
            <Button
              color="neverbe"
              onClick={() =>
                navigate(`/${pathName}`, { state: location.state })
              }
            >
              목록
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
