import { useEffect, useState, useRef, memo } from 'react'
import './style.css'
import { clearSelectMapData, GolfPriceItem, setSelectMapData, } from '@/redux/slices/golfSearchSlice'
import { Table } from '@/components'
import { TableCell, TableCellHeader, TableRow } from '../detailInfo/_components/style'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/redux/store'
import { fontWeight, textAlign } from 'styled-system'

declare global {
  interface Window {
    kakao: any
  }
}

interface DetailMapProps {
  data: GolfPriceItem[]
  screenType?: string
}

export const DetailMap = memo(({ data, screenType = 'detail' }: DetailMapProps) => {
  const [showMap, setShowMap] = useState<boolean>(true)
  const isMounted = useRef(true)
  const mapRef = useRef<any>(null)
  const mapInitializedRef = useRef<boolean>(false)
  const apiCallInProgressRef = useRef<boolean>(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  const dispatch = useDispatch();
  const selectGolf = useSelector(
    (state: RootState) => state.golfSearch.selectMapData,
  );
  
  const searchParams = useSelector(
    (state: RootState) => state.golfSearch.searchParams,
  );

  const selectBizCd = useRef<string>("");

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
      // 컴포넌트 언마운트 시 진행 중인 요청 취소
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    // if (!data || data.length === 0 || data.length > 50) {
    //   setShowMap(false)
    //   return
    // }
    // 지도내 그리드 초기화
    dispatch(clearSelectMapData());
    if(!searchParams.areaCd || !data || data.length == 0){
      // 지역코드 검색할경우에만 지도를 로드한다.
      setShowMap(false);
      return;
    }

    // 이미 API 호출이 진행 중이면 중복 호출 방지
    if (apiCallInProgressRef.current) {
      return
    }

    // 이전 요청이 있으면 취소
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    // 새로운 AbortController 생성
    abortControllerRef.current = new AbortController()
    apiCallInProgressRef.current = true

    // 지도 컨테이너를 보이게 설정
    setShowMap(true)

    // DOM이 완전히 렌더링될 시간을 주기 위한 지연
    const timer = setTimeout(() => {
      if (!isMounted.current) return

      const container = document.getElementById('map') as HTMLElement
      if (!container) return
      else container.innerHTML = '';    // 지도 새로 만들어서 마커 중복되게 찍히는거 방지

      // if (!mapInitializedRef.current || !mapRef.current) {
      try {
        const options = {
          center: new window.kakao.maps.LatLng(37.5665, 126.978),
          level: 10,
        }
        const map = new window.kakao.maps.Map(container, options)
        mapRef.current = map
        mapInitializedRef.current = true
      } catch (error) {
        console.error('지도 초기화 오류:', error)
        apiCallInProgressRef.current = false
        return
      }
      // }

      const map = mapRef.current

      const geocoder = new window.kakao.maps.services.Geocoder()
      const markersCoords: any[] = []
      let processedCount = 0,
        successCount = 0

      data.forEach(({ addr, bizNm, bizCd }) => {
        if (!isMounted.current || abortControllerRef.current?.signal.aborted)
          return

        geocoder.addressSearch(addr, (result: any, status: any) => {
          // 요청이 취소되었거나 컴포넌트가 언마운트된 경우
          if (!isMounted.current || abortControllerRef.current?.signal.aborted)
            return

          processedCount++
          if (status === window.kakao.maps.services.Status.OK) {
            successCount++
            const coords = new window.kakao.maps.LatLng(
              result[0].y,
              result[0].x,
            )
            markersCoords.push(coords)

            const customOverlay = new window.kakao.maps.CustomOverlay({
              position: coords,
              content: screenType === 'deatil' ? `
                <a
                href="https://map.kakao.com/link/map/${bizNm},${result[0].y},${result[0].x}"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none; color: black; position: relative; display: inline-block; text-align: center;">
                <div class="marker-box">
                  ${bizNm}
                </div>
                <div class="pointer"></div>
              `: `
                <div class="marker-box" id="${bizCd}">
                  ${bizNm}
                </div>
                <div class="pointer"></div>
              `
              ,
              yAnchor: 1,
            })
            customOverlay.setMap(map)
          } else {
            console.error(`${bizNm}-주소 변환 실패: ${addr}`, status)
          }

          // 모든 주소 처리 완료 시
          if (processedCount === data.length) {
            apiCallInProgressRef.current = false

            if (successCount === 0) {
              setShowMap(false)
            } else if (markersCoords.length > 0) {
              const bounds = markersCoords.reduce(
                (b, c) => b.extend(c),
                new window.kakao.maps.LatLngBounds(),
              )

              if (isMounted.current && map) {
                map.setBounds(bounds)

                // 지도 레이아웃 재설정
                setTimeout(() => {
                  if (isMounted.current && map) {
                    map.relayout()
                    setTimeout(() => {
                      if (isMounted.current && map) {
                        map.setBounds(bounds)
                        // 지도 컨테이너를 보이게 설정

                        const clickOverlay = (event: any) => {
                          const compare = data.filter((item) => item.bizCd == event.target.id)[0];
                          if(compare){
                            // redux에 담은 값은 이벤트 호출 시점에서 변경이 안되는듯 하여 useRef로 추가관리
                            if(selectBizCd.current == compare.bizCd){
                              dispatch(clearSelectMapData());
                              selectBizCd.current = "";
                            }else{
                              selectBizCd.current= compare.bizCd;
                              dispatch(setSelectMapData(data.filter((item) => item.bizCd == event.target.id)[0]));
                            }
                          }
                        };

                        const elements = document.querySelectorAll(".marker-box");
                        if (screenType == 'search') {
                          // 검색일경우는 클릭했을경우 화면이동이아님.
                          elements.forEach((el) => el.addEventListener("click", clickOverlay));
                        }
                      }
                    }, 100)
                  }
                }, 100)
              }
            }
          }
        })
      })
    }, 500)

    return () => {
      clearTimeout(timer)
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
      apiCallInProgressRef.current = false
    }
  }, [data])

  // 컴포넌트가 마운트된 후 지도 레이아웃 재설정
  useEffect(() => {
    if (showMap && mapRef.current && mapInitializedRef.current) {
      const layoutTimer = setTimeout(() => {
        if (isMounted.current && mapRef.current) {
          mapRef.current.relayout()
        }
      }, 300)

      return () => clearTimeout(layoutTimer)
    }
  }, [showMap])

  const InfoBox = (selectGolf: GolfPriceItem) => {
    const headerStyle: any = {
      textAlign: 'center',
    };
    const cellStyle: any = {
      textAlign: 'right',
      background: 'white',
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const regionMonthAvgPrice = useSelector((state: RootState) => state.golfSearch.graphData.monthAvgPriceByRegion,).filter((item) => item.areaCd == selectGolf.areaCd && currentYear.toString() == item.year && currentMonth.toString() == item.month)[0] || {};
    const regionAvgPrice = useSelector((state: RootState) => state.golfSearch.graphData.regionAvgPrice,).filter((item) => item.areaCd == selectGolf.areaCd)[0] || {};
    return (
      <div
        style={{
          position: 'absolute', // 절대 위치로 지도 위에 고정
          width: 'max-content',
          height: 'max-contents',
          zIndex: 2, // z-index로 맵 위로 올리기
          top: '50px',
        }}>
        <Table style={{ fontSize: '10px', margin: '0px' }}>
          <tbody>
            <TableRow>
              <TableCellHeader style={headerStyle}>골프장</TableCellHeader>
              <TableCellHeader style={headerStyle}>비회원(주중)</TableCellHeader>
              <TableCellHeader style={headerStyle}>비회원(주말)</TableCellHeader>
              <TableCellHeader style={headerStyle}>회원(주중)</TableCellHeader>
              <TableCellHeader style={headerStyle}>회원(주말)</TableCellHeader>
            </TableRow>
            <TableRow>
              <TableCellHeader style={headerStyle}>{selectGolf.bizNm}</TableCellHeader>
              <TableCell style={cellStyle}>{selectGolf.n01 ? selectGolf.n01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{selectGolf.n02 ? selectGolf.n02.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{selectGolf.m01 ? selectGolf.m01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{selectGolf.m02 ? selectGolf.m02.toLocaleString() : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader style={headerStyle}>{selectGolf.areaNm + '(' + currentMonth + '월)'}</TableCellHeader>
              <TableCell style={cellStyle}>{regionMonthAvgPrice.n01 ? regionMonthAvgPrice.n01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionMonthAvgPrice.n02 ? regionMonthAvgPrice.n02.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionMonthAvgPrice.m01 ? regionMonthAvgPrice.m01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionMonthAvgPrice.m02 ? regionMonthAvgPrice.m02.toLocaleString() : '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCellHeader style={headerStyle}>{selectGolf.areaNm}</TableCellHeader>
              <TableCell style={cellStyle}>{regionAvgPrice.n01 ? regionAvgPrice.n01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionAvgPrice.n02 ? regionAvgPrice.n02.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionAvgPrice.m01 ? regionAvgPrice.m01.toLocaleString() : '-'}</TableCell>
              <TableCell style={cellStyle}>{regionAvgPrice.m02 ? regionAvgPrice.m02.toLocaleString() : '-'}</TableCell>
            </TableRow>
          </tbody>
        </Table>
      </div>);
  }

  return (
    <div
      style={{ position: 'relative' }}
    >
      <div
        id="map"
        style={{
          width: '100%',
          height: '400px',
          display: showMap ? 'block' : 'none',
          marginBottom: '50px',
          marginTop: '50px',
          border: '1px solid rgb(221, 221, 221)',
          borderRadius: '8px',
        }}
      >
      </div>
      {selectGolf && selectGolf.bizCd && <InfoBox {...selectGolf} />}
    </div>
  )
})

DetailMap.displayName = 'DetailMap'
