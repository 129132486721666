import { useEffect, useState } from 'react'
import { useAxios } from '@/context/axiosContext'
import { NoticeListItem } from '../noticeItem'
import './noticePopup.css'
import { Button } from '@/components'
import { useNavigate } from 'react-router-dom'

export const NoticePopup = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const [noticePopup, setNoticePopup] = useState<NoticeListItem[]>([])
  const [visiblePopups, setVisiblePopups] = useState<Record<number, boolean>>(
    {},
  )

  const [isSevenDaysChecked, setIsSevenDaysChecked] = useState<
    Record<number, boolean>
  >({}) // 각 팝업에 대한 7일 체크 상태
  const [isNeverShowChecked, setIsNeverShowChecked] = useState<
    Record<number, boolean>
  >({}) // 각 팝업에 대한 다시 보지 않기 체크 상태
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [popupPosition, setPopupPosition] = useState<number[]>([35]);

  useEffect(() => {
    getNoticePopup()
  }, [])

  // 각 팝업의 visible 상태
  useEffect(() => {
    const newVisiblePopups: Record<number, boolean> = {}
    const newSevenDaysChecked: Record<number, boolean> = {}
    const newNeverShowChecked: Record<number, boolean> = {}

    noticePopup.forEach((item) => {
      const neverShow = localStorage.getItem(`neverShow_${item.noticeSn}`)
      const nextShowDate = localStorage.getItem(`nextShowDate_${item.noticeSn}`)

      // "다시 보지 않기" 설정이 있으면 팝업을 숨김
      newVisiblePopups[item.noticeSn] =
        !neverShow && !(nextShowDate && new Date(nextShowDate) > new Date())
      newSevenDaysChecked[item.noticeSn] =
        !!nextShowDate && new Date(nextShowDate) > new Date()
      newNeverShowChecked[item.noticeSn] = !!neverShow
    })

    setVisiblePopups(newVisiblePopups)
    setIsSevenDaysChecked(newSevenDaysChecked)
    setIsNeverShowChecked(newNeverShowChecked)
  }, [noticePopup])

  const getNoticePopup = async () => {
    try {
      const response = await axios.get(`/notice/popup`, {
        headers: { 'Cache-Control': 'no-cache' },
      })

      const { noticePopup } = response.data.data

      if (noticePopup && noticePopup.length > 0) {
        setNoticePopup(noticePopup)
        setVisiblePopups(new Array(noticePopup.length).fill(true)) // 각 팝업의 visible 상태를 true로 설정
        setIsModalOpen(true) // 데이터가 있을 경우에만 팝업 활성화

        setPopupPosition(() => {
          const positions = [];
          for (let k = 0; k < noticePopup.length; k++) {
            const pos = 35 + (Math.pow(-1, k) * Math.ceil((k + 1) / 2) * 15);
            positions.push(pos);
          }
          return positions;
        });
      }
    } catch (err: any) {
      console.error('Error fetching noticePopup:', err)
    }
  }

  // 로컬 스토리지에 값이 있는지 확인하고 모달을 표시
  useEffect(() => {
    const lastClosedDate = localStorage.getItem('lastClosedDate')
    const nextShowDate = localStorage.getItem('nextShowDate')
    const neverShow = localStorage.getItem('neverShow')

    // "다시 보지 않기" 값이 있으면 팝업을 보이지 않도록 설정
    if (neverShow) {
      setIsModalOpen(false)
    } else if (
      !lastClosedDate ||
      (nextShowDate && new Date(nextShowDate) <= new Date())
    ) {
      setIsModalOpen(true) // 로컬 스토리지에 날짜가 없거나 7일이 지난 경우 모달을 오픈
    }
  }, [])

  // 체크박스 상태 변경 (각 팝업의 상태를 변경)
  const handleSevenDaysCheckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    noticeSn: number,
  ) => {
    setIsSevenDaysChecked((prevState) => ({
      ...prevState,
      [noticeSn]: e.target.checked,
    }))
    setIsNeverShowChecked((prevState) => ({
      ...prevState,
      [noticeSn]: false,
    }))
  }

  const handleNeverShowCheckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    noticeSn: number,
  ) => {
    setIsNeverShowChecked((prevState) => ({
      ...prevState,
      [noticeSn]: e.target.checked,
    }))
    setIsSevenDaysChecked((prevState) => ({
      ...prevState,
      [noticeSn]: false,
    }))
  }

  const handleConfirm = (noticeSn: number) => {
    if (isSevenDaysChecked[noticeSn]) {
      const nextShowDate = new Date()
      nextShowDate.setDate(nextShowDate.getDate() + 7)
      localStorage.setItem(
        `nextShowDate_${noticeSn}`,
        nextShowDate.toISOString(),
      )
    } else {
      localStorage.removeItem(`nextShowDate_${noticeSn}`)
    }

    if (isNeverShowChecked[noticeSn]) {
      localStorage.setItem(`neverShow_${noticeSn}`, 'true')
    } else {
      localStorage.removeItem(`neverShow_${noticeSn}`)
    }

    // 해당 팝업만 닫기
    setVisiblePopups((prevState) => ({ ...prevState, [noticeSn]: false }))
    document.documentElement.style.overflow = 'auto'
  }

  // 팝업을 보여야 하는지 조건 체크
  const showMainPopup =
    noticePopup.length > 0 &&
    noticePopup.some(
      (item) =>
        visiblePopups[item.noticeSn] &&
        !localStorage.getItem(`neverShow_${item.noticeSn}`),
    )

  const handleDetailView = (noticeSn: number) => {
    navigate(`/notice/detail?noticeSn=${noticeSn}`)
  }

  return (
    <>
      {showMainPopup && (
        <div className="popup-overlay">
          {noticePopup.map((item, index) =>
            visiblePopups[item.noticeSn] ? (
              <div key={item.noticeSn} className="popup" style={{ left: `${popupPosition[index]}%`}}>
                <div
                  className="popup-header"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>{item.noticeSj}</span> {/* 제목 */}
                  <span
                    onClick={() => handleDetailView(item.noticeSn)}
                    style={{
                      cursor: 'pointer',
                      color: '#D09D03',
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    공지사항 보러가기
                  </span>{' '}
                  {/* 오른쪽 텍스트 */}
                </div>
                <div className="popup-body">
                  <textarea
                    value={item.noticeCn}
                    id="noticeCn"
                    name="noticeCn"
                    style={{
                      width: '100%',
                      height: '300px',
                      border: 'none', // 테두리 제거
                      outline: 'none', // 포커스 시 테두리 제거
                      borderRadius: '4px', // 모서리를 둥글게
                      resize: 'none', // 사용자가 크기 조정 불가
                      backgroundColor: 'transparent',
                    }}
                  ></textarea>
                </div>
                <div className="popup-footer">
                  <label>
                    <input
                      type="checkbox"
                      checked={isSevenDaysChecked[item.noticeSn] || false} // 각 팝업에 맞는 상태를 가져옵니다.
                      onChange={(e) =>
                        handleSevenDaysCheckBox(e, item.noticeSn)
                      }
                    />
                    7일간 보지 않기
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={isNeverShowChecked[item.noticeSn] || false}
                      onChange={(e) =>
                        handleNeverShowCheckBox(e, item.noticeSn)
                      }
                    />
                    다시 보지 않기
                  </label>
                  <Button
                    type="button"
                    color="neverbe"
                    onClick={() => handleConfirm(item.noticeSn)}
                  >
                    확인
                  </Button>
                </div>
              </div>
            ) : null,
          )}
        </div>
      )}
    </>
  )
}
