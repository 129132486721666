/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
const commonUtils = {
  getSiblings: (elem: HTMLElement): HTMLElement[] => {
    const siblings: HTMLElement[] = []
    let sibling = elem?.parentNode?.firstChild as HTMLElement
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling)
      }
      sibling = sibling.nextSibling as HTMLElement
    }
    return siblings
  },

  hexTorgb: (hex: string, opacity: number): string | undefined => {
    const h = hex.replace('#', '')
    const hh = h.match(new RegExp(`(.{${h.length / 3}})`, 'g'))
    const rgba: number[] = []
    if (!hh) return
    for (let i = 0; i < hh.length; i += 1)
      rgba[i] = parseInt(hh[i].length === 1 ? hh[i] + hh[i] : hh[i], 16)

    if (typeof opacity !== 'undefined') rgba.push(opacity)

    return `rgba(${rgba.join(',')})`
  },

  flatDeep: (arr: any[], d = 1): any[] => {
    return d > 0
      ? arr.reduce((acc, val) => {
          return acc.concat(
            Array.isArray(val) ? commonUtils.flatDeep(val, d - 1) : val,
          ) as any[]
        }, [])
      : arr.slice()
  },

  hasKey: (obj: any, key: string): boolean => {
    return !!Object.prototype.hasOwnProperty.call(obj, key)
  },

  generateDayWiseTimeSeries: (
    baseval: number,
    count: number,
    yrange: { min: any; max: any },
  ) => {
    let i = 0
    let value = baseval
    const series: [number, number][] = []
    while (i < count) {
      const y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

      series.push([value, y])
      value += 86400000
      i += 1
    }
    return series
  },

  formatDate: (dateString: string, isDetail = false) => {
    if (!dateString) return ''

    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    const now = new Date()
    const isToday =
      year === now.getFullYear() &&
      date.getMonth() === now.getMonth() &&
      day === now.getDate().toString().padStart(2, '0')

    // 상세(detail) 페이지일 경우 항상 yyyy.MM.dd hh:mm:ss 형식
    if (isDetail) {
      return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`
    }

    // 오늘 날짜인 경우 시분초만 출력
    if (isToday) {
      return `${hours}:${minutes}:${seconds}`
    }

    // 오늘이 아니면 날짜만 출력
    return `${year}.${month}.${day}`
  },
}

export default commonUtils
