import { useState, useEffect, useCallback } from 'react'
import { useAxios } from '@/context/axiosContext'

interface AuthData {
  resultcode: string
  requestno: string
  enctime: string
  sitecode: string
  responseno?: string
  authtype?: string
  name?: string
  utf8_name?: string
  birthdate?: string
  gender?: string
  nationalinfo?: string
  mobileco?: string
  mobileno?: string
  ci?: string
  di?: string
  businessno?: string
  receivedata?: string
  [key: string]: string | undefined //11
}

export const useNiceAuth = () => {
  const [authData, setAuthData] = useState<AuthData | null>(null)
  const axios = useAxios()

  //인증 요청
  const requestNiceAuth = useCallback(async () => {
    try {
      const response = await axios.post('/certification/nice-test', {})
      const { tokenVersionId, encData, integrityValue } = response.data

      if (!tokenVersionId || !encData || !integrityValue) {
        console.error('API 응답 값이 비어 있습니다.', response.data)
        return
      }

      const form = document.createElement('form')
      form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb'
      form.method = 'POST'

      const addHiddenInput = (name: string, value: string) => {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = value
        form.appendChild(input)
      }

      addHiddenInput('m', 'service')
      addHiddenInput('token_version_id', tokenVersionId)
      addHiddenInput('enc_data', encData)
      addHiddenInput('integrity_value', integrityValue)

      const popupWindow = window.open(
        '/result',
        '_blank',
        'width=800,height=600',
      )

      if (popupWindow) {
        popupWindow.document.body.appendChild(form)
        form.submit()
      } else {
        alert('팝업 창을 열 수 없습니다.')
        console.error('팝업 창을 열 수 없습니다.')
      }
    } catch (err) {
      alert('인증 요청을 실패하였습니다. 잠시 후 다시 시도해 주세요.')
      console.error('인증 요청 실패:', err)
    }
  }, [axios])

  useEffect(() => {
    const handleAuthMessage = (event: MessageEvent) => {
      if (event.origin !== window.location.origin) return
      if (event.data?.type === 'NICE_AUTH_SUCCESS') {
        console.log('인증 성공 :', event.data.data)
        setAuthData(event.data.data)
      }
    }

    window.addEventListener('message', handleAuthMessage)
    return () => window.removeEventListener('message', handleAuthMessage)
  }, [])

  return { authData, requestNiceAuth, setAuthData }
}
