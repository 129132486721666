import Content from '@/components/layout/content';
import MypageComponent from './_components/mypage';
import { SEO } from '@/components/seo/seo';
import React from 'react';
import { StyledMedia } from './_components/style';

const Mypage: React.FC = () => {

    return (
        <>
          <SEO
            title="마이페이지"
            description="꿀프에서 골프 관련 서비스를 확인하세요. 다양한 키워드로 쉽게 검색 가능합니다."
            noIndex={true}
          />
          <Content fullHeight>
            <StyledMedia>
              <MypageComponent />
            </StyledMedia>
          </Content>
        </>)
}

export default Mypage;