import { useDialog } from '@/context/dialogContext'

export const AlertSample = () => {
  const { showAlert, showConfirm } = useDialog()

  const handleAlert = () => {
    showAlert({
      type: 'error',
      title: 'This is a success alert message!',
      message: 'Error!',
      btnName: 'Close',
      onClose() {
        console.log('Alert closed!')
      },
    })
  }

  const handleConfirm = () => {
    showConfirm({
      title: 'Are you sure you want to proceed?',
      // message: 'Confirmation',
      confirmBtnName: 'Yes',
      cancelBtnName: 'No',
      onConfirm() {
        console.log('Confirmed!')
      },
      onCancel() {
        console.log('Cancelled!')
      },
    })
  }

  return (
    <div>
      <button onClick={handleAlert}>Show Alert</button>
      <button onClick={handleConfirm}>Show Confirm</button>
    </div>
  )
}
