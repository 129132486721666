import { useAxios } from "@/context/axiosContext";
import { useAppSelector } from "@/redux/hooks";
import { useCallback } from "react";

interface LogDto {
    logType?:string,
    logMsg?:string,
    stackTrace?:string,
    remarks?:string,
}

export const useLog = () => {
    const axios = useAxios();
    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const insertLog = useCallback(
        async (dto: LogDto) => {
            const data = {
                siteType : '01',    // 00:어드민, 01:골프
                logLayer : 'FE',
                logType : dto.logType || 'ERROR',
                logMsg : dto.logMsg || '',
                stackTrace : dto.stackTrace || '',
                remarks : dto.remarks || '',
            }

            const response = await axios.post('/log/insert', data);
            if(response.status === 200){
                 // 로그 정상 적재시 아무것도 안함.
            }else{
                console.error('insert log error', response);
            }

        }, [axios]);

    return { insertLog };
}