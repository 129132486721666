import { TableStyles } from 'react-data-table-component/dist/DataTable/types'

export const tableStyles: TableStyles = {
  responsiveWrapper: {
    style: {
      overflowY: 'auto',
      maxHeight: '400px',
      '&::-webkit-scrollbar': {
        width: '12px',
        height: '12px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  headRow: {
    style: {
      paddingLeft: '10px',
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: 'white',
    },
  },
  headCells: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: '8px',
      paddingRight: '8px',
      "&[data-column-id='1']": {
        minWidth: '280px',
      },
      "&[data-column-id='2']": {
        minWidth: '30px',
      },
      "&[data-column-id='3']": {
        minWidth: '60px',
      },
      "&[data-column-id='4']": {
        minWidth: '60px',
      },
      "&[data-column-id='5']": {
        minWidth: '60px',
      },
      "&[data-column-id='6']": {
        minWidth: '60px',
      },
      /* '@media (max-width: 768px)': {
        "&[data-column-id='2']": {
          minWidth: '30px',
          maxWidth: '80px',
        },
      },
      '@media (max-width: 465px)': {
        "&[data-column-id='2']": {
          minWidth: '30px',
          maxWidth: '60px',
        },
      }, */
    },
  },
  rows: {
    style: {
      paddingLeft: '10px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      "&[data-column-id='1']": {
        minWidth: '280px',
      },
      "&[data-column-id='2']": {
        minWidth: '30px',
      },
      "&[data-column-id='3']": {
        minWidth: '60px',
      },
      "&[data-column-id='4']": {
        minWidth: '60px',
      },
      "&[data-column-id='5']": {
        minWidth: '60px',
      },
      "&[data-column-id='6']": {
        minWidth: '60px',
      },
      /* '@media (max-width: 768px)': {
        "&[data-column-id='2']": {
          minWidth: '30px',
          maxWidth: '80px',
        },
      },
      '@media (max-width: 465px)': {
        "&[data-column-id='2']": {
          minWidth: '30px',
          maxWidth: '60px',
        },
      }, */
    },
  },
  pagination: {
    style: {
      color: 'black',
    },
    pageButtonsStyle: {
      fill: 'black',
      ':disabled': {
        fill: '#b3b3b3',
      },
    },
  },
}
