import { useAxios } from '@/context/axiosContext'
import {
  GolfPriceItem,
  setGraphData,
  setListData,
  setMonthAvgPriceByRegion,
  setQuarterAvgPrice,
  setRegionAvgPrice,
  setYearAvgPrice,
  setWeekAvgPriceByRegion,
  setWeekGraphData,
} from '@/redux/slices/golfSearchSlice'
import { RootState } from '@/redux/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export interface PeriodAvgData {
  areaCd: string
  areaNm: string
  n01: number
  n02: number
  m01: number
  m02: number
  month: string
  year: string
  quarter: string
  week: string
}

export interface regionAvgPriceItem {
  areaCd: string
  areaNm: string
  n01: number
  n02: number
  m01: number
  m02: number
}

interface GolfPriceResponse {
  common: {
    resultCode: string
    resultMsg: string
  }
  data: {
    listData: GolfPriceItem[] // 골프장 요금 목록
    monthAvgPriceData: PeriodAvgData[] // 월평균 가격 데이터
    monthAvgPriceByRegionData: PeriodAvgData[] // 지역별 월평균 가격 데이터
    regionAvgPriceData: regionAvgPriceItem[] //지역별 현재 가격 데이터
    quarterAvgPriceData: PeriodAvgData[] // 분기 평균 가격 데이터
    yearAvgPriceData: PeriodAvgData[] // 연 평균 가격 데이터
    weekAvgPriceData: PeriodAvgData[] // 주평균 가격 데이터
    weekAvgPriceByRegionData: PeriodAvgData[] // 지역별 주평균 가격 데이터
  }
}

export const mapMonthDataToGraph = (
  monthData: PeriodAvgData[],
  monthsArr: string[],
): number[][] => {
  const updatedGraphData = [
    new Array(12).fill(0), // n01
    new Array(12).fill(0), // n02
    new Array(12).fill(0), // m01
    new Array(12).fill(0), // m02
  ]

  if (monthData && Array.isArray(monthData)) {
    monthData.forEach((item) => {
      // YY-MM
      const formattedMonth = `${item.year.slice(-2)}-${item.month.padStart(2, '0')}`
      const monthIndex = monthsArr.findIndex(
        (month) => month === formattedMonth,
      )

      if (monthIndex >= 0 && monthIndex < 12) {
        updatedGraphData[0][monthIndex] = item.n01 ?? 0
        updatedGraphData[1][monthIndex] = item.n02 ?? 0
        updatedGraphData[2][monthIndex] = item.m01 ?? 0
        updatedGraphData[3][monthIndex] = item.m02 ?? 0
      }
    })
  }
  return updatedGraphData
}

export const mapWeekDataToGraph = (
  weekData: PeriodAvgData[],
  weekArr: string[],
): number[][] => {
  // 주차의 시작일과 종료일 계산
  const updatedGraphData = [
    new Array(weekArr.length).fill(0), // n01
    new Array(weekArr.length).fill(0), // n02
    new Array(weekArr.length).fill(0), // m01
    new Array(weekArr.length).fill(0), // m02
  ]

  if (weekData && Array.isArray(weekData)) {
    weekData.forEach((item) => {
      const { year, week } = item

      // 주차에 해당하는 인덱스 찾기 (YYYY-WW 형식)
      const weekKey = `${year}-${String(week).padStart(2, '0')}`
      const weekIndex = weekArr.findIndex((w) => w === weekKey)

      if (weekIndex >= 0) {
        updatedGraphData[0][weekIndex] = item.n01 ?? 0
        updatedGraphData[1][weekIndex] = item.n02 ?? 0
        updatedGraphData[2][weekIndex] = item.m01 ?? 0
        updatedGraphData[3][weekIndex] = item.m02 ?? 0
      }
    })
  }
  return updatedGraphData
}

export const mapPeriodDataToGraph = (
  periodData: PeriodAvgData[],
  periodType: 'quarter' | 'year',
): number[][] => {
  const currentYear = new Date().getFullYear()
  const periodLength = periodType === 'quarter' ? 12 : 5
  const updatedGraphData = [
    new Array(periodLength).fill(0), // n01
    new Array(periodLength).fill(0), // n02
    new Array(periodLength).fill(0), // m01
    new Array(periodLength).fill(0), // m02
  ]

  const sortedPeriodData = periodData.sort((a, b) => {
    if (periodType === 'quarter') {
      const yearDiff = parseInt(b.year) - parseInt(a.year)
      return yearDiff !== 0
        ? yearDiff
        : parseInt(b.quarter) - parseInt(a.quarter)
    } else {
      return parseInt(b.year) - parseInt(a.year)
    }
  })

  if (sortedPeriodData && Array.isArray(sortedPeriodData)) {
    sortedPeriodData.forEach((item) => {
      const periodIndex =
        periodType === 'quarter'
          ? (parseInt(item.year) - currentYear) * 4 +
            (parseInt(item.quarter) - 1) +
            periodLength -
            1
          : parseInt(item.year) - currentYear + periodLength - 1

      if (periodIndex >= 0 && periodIndex < periodLength) {
        updatedGraphData[0][periodIndex] = item.n01 ?? 0
        updatedGraphData[1][periodIndex] = item.n02 ?? 0
        updatedGraphData[2][periodIndex] = item.m01 ?? 0
        updatedGraphData[3][periodIndex] = item.m02 ?? 0
      }
    })
  }
  return updatedGraphData
}

export const generatePeriod = (
  type: 'month' | 'quarter' | 'year' | 'week',
): string[] => {
  const periodArr: string[] = []
  const currentDate = new Date()

  if (type === 'week') {
    for (let i = 0; i < 12; i++) {
      const prevWeek = new Date(currentDate)
      prevWeek.setDate(prevWeek.getDate() - i * 7)
      const year = prevWeek.getFullYear()
      const week = Math.ceil(
        (prevWeek.getTime() - new Date(year, 0, 1).getTime()) /
          (7 * 24 * 60 * 60 * 1000),
      )
      periodArr.unshift(`${year}-${String(week).padStart(2, '0')}`)
    }
  } else if (type === 'month') {
    for (let i = 0; i < 12; i++) {
      const prevMonth = new Date(currentDate)
      prevMonth.setMonth(prevMonth.getMonth() - i)
      periodArr.unshift(
        `${prevMonth.getFullYear().toString().slice(-2)}-${(
          prevMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, '0')}`,
      )
    }
  } else if (type === 'quarter') {
    for (let i = 0; i < 12; i++) {
      const prevQuarter = new Date(currentDate)
      prevQuarter.setMonth(prevQuarter.getMonth() - i * 3)
      const quarter = Math.floor(prevQuarter.getMonth() / 3) + 1
      const year = prevQuarter.getFullYear().toString().slice(-2)
      periodArr.unshift(`${year}-${quarter}Q`)
    }
  } else if (type === 'year') {
    for (let i = 0; i < 5; i++) {
      const prevYear = new Date(currentDate)
      prevYear.setFullYear(currentDate.getFullYear() - i)
      periodArr.unshift(prevYear.getFullYear().toString())
    }
  }

  return periodArr
}

export const useGolfSearchData = () => {
  const dispatch = useDispatch()
  const axios = useAxios()

  const searchParams = useSelector(
    (state: RootState) => state.golfSearch.searchParams,
  )

  const [data, setData] = useState<GolfPriceItem[]>([])

  const updatedSearchParams = { ...searchParams }

  if (updatedSearchParams.membershipYn === 'A') {
    updatedSearchParams.membershipYn = null
  }

  const getGolfPriceList = async () => {
    try {
      const { data: responseData } = await axios.get<GolfPriceResponse>(
        '/golf/list',
        { params: updatedSearchParams },
      )

      const {
        listData,
        monthAvgPriceData,
        quarterAvgPriceData,
        yearAvgPriceData,
        monthAvgPriceByRegionData,
        regionAvgPriceData,
        weekAvgPriceData,
        weekAvgPriceByRegionData,
      } = responseData.data

      const { resultCode, resultMsg } = responseData.common
      if (resultCode !== 'G0200') {
        console.error(`${resultCode} :: ${resultMsg}`)
      }

      setData(listData)
      dispatch(setListData(listData))

      const monthsArr = generatePeriod('month')
      const updatedGraphData = mapMonthDataToGraph(monthAvgPriceData, monthsArr)
      dispatch(setGraphData(updatedGraphData))

      dispatch(setRegionAvgPrice(regionAvgPriceData))
      dispatch(setMonthAvgPriceByRegion(monthAvgPriceByRegionData))

      const weeksArr = generatePeriod('week')
      const updatedWeekGraphData = mapWeekDataToGraph(
        weekAvgPriceData,
        weeksArr,
      )

      dispatch(setWeekGraphData(updatedWeekGraphData))
      dispatch(setWeekAvgPriceByRegion(weekAvgPriceByRegionData))

      const quarterGraphData = mapPeriodDataToGraph(
        quarterAvgPriceData,
        'quarter',
      )
      dispatch(setQuarterAvgPrice(quarterGraphData))

      const yearGraphData = mapPeriodDataToGraph(yearAvgPriceData, 'year')
      dispatch(setYearAvgPrice(yearGraphData))
    } catch (error) {
      console.error('Error fetching golf price list:', error)
    }
  }

  useEffect(() => {
    getGolfPriceList()
  }, [searchParams])

  return { data }
}
