import image from '@/assets/images/img19.png'
import Content from '@/components/layout/content'
import {
  StyledDesc,
  StyledImg,
  StyledNote,
  StyledSubTitle,
  StyledTitle,
  StyledWrap,
} from './style'
import { useEffect, useState } from 'react'
import { useLog } from '@/hooks/useLog'
import { useLocation } from 'react-router-dom'

interface props{
  error?: Error | null , 
  resetError?:any
}

const ErrorContainer = ({ error , resetError}: props) => {
  const { insertLog } = useLog();

  const location = useLocation();
  const [current, setCurrent] = useState<any>(null);

  useEffect(() => {
    if(error){
      insertLog({
        logType : 'ERROR',
        logMsg : error.message,
        stackTrace : error.stack
      });
    }
  }, [error]);

  useEffect(() => {
    if(current == null || current == location.pathname){
      setCurrent(location.pathname);
    }else if(resetError){
      // 오류 발생시 진입한 화면이 아닐경우 
      resetError();
    }
  }, [location]);

  return (
    <Content fullHeight align="center">
      <StyledWrap>
        <StyledImg>
          <img src={image} alt="500" />
        </StyledImg>
        <StyledTitle>Internal Server Error </StyledTitle>
        <StyledSubTitle>화면 로드중 오류가 발생하였습니다.</StyledSubTitle>
        <StyledDesc>
          {/* 주소를 잘못 입력했거나, 페이지가 이동했을 수 있습니다. 다시
          확인해주세요. */}
        </StyledDesc>
        <StyledNote>
          Error page concept with laptop vector is created by{' '}
          <a
            href="https://www.freepik.com/free-photos-vectors/background"
            target="_blank"
            rel="noopener noreferrer"
          >
            freepik (freepik.com)
          </a>
        </StyledNote>
      </StyledWrap>
    </Content>
  )
}

export default ErrorContainer
