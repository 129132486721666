import { ApexBarChart } from '@/components'
import { GraphDataState } from '@/redux/slices/golfSearchSlice'
import styled from 'styled-components'

interface RegionGraphProps {
  regionAvgPrice: GraphDataState['regionAvgPrice']
}
export const RegionGraph = ({ regionAvgPrice }: RegionGraphProps) => {
  const categories = regionAvgPrice.map((region) => region.areaNm)

  const ApexBarData = {
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: { show: false },
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: 'smooth',
      },
      markers: {
        size: 5,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
        shape: 'circle',
        showNullDataPoints: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: { text: '단위 (원)' },
        labels: {
          formatter: (value: any) => value.toLocaleString('ko-KR'),
        },
      },
    },
    series: [
      {
        name: '비회원(주주말)',
        type: 'bar',
        data: regionAvgPrice.map((region) => region.n01),
      },
      {
        name: '비회원(주말)',
        type: 'bar',
        data: regionAvgPrice.map((region) => region.n02),
      },
      {
        name: '회원(주중)',
        type: 'line',
        data: regionAvgPrice.map((region) => region.m01),
      },
      {
        name: '회원(주말)',
        type: 'line',
        data: regionAvgPrice.map((region) => region.m02),
      },
    ],
  }

  return (
    <>
      {regionAvgPrice.length > 0 && (
        <GraphWrapper>
          <GraphTitle>지역별 평균</GraphTitle>
          <ApexBarChart
            options={ApexBarData.options}
            series={ApexBarData.series}
            height="350px"
            width="100%"
          />
        </GraphWrapper>
      )}
    </>
  )
}

const GraphWrapper = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`

const GraphTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`
