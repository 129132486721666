import {
  CustomNavigation,
  CustomNextButton,
  CustomPrevButton,
} from '@/components/main/banner/bannerSwiper'
import { YoutubeRankData } from '@/pages/youtube/youtubeRankItem'
import { useEffect, useState } from 'react'
import { Grid, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperClass } from 'swiper/react'
import { MainYoutubeListItem } from './mainYoutubeListItem'
import styled from 'styled-components'
import { useDialog } from '@/context/dialogContext'

export interface ListItemProps {
  rankList: YoutubeRankData[]
  setLikeStatus?: (
    formData: FormData,
    chnlId: string,
    currentLikeStatus: string,
  ) => void
}
export const MainYoutubeSwiper = ({
  rankList,
  setLikeStatus,
}: ListItemProps) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)
  const { showAlert } = useDialog()

  useEffect(() => {
    if (swiperInstance) {
    }
  }, [swiperInstance]) // swiperInstance가 바뀔 때마다 실행

  return (
    <YoutubeSwiperBox>
      <MainYoutubeListItem data={rankList} setLikeStatus={setLikeStatus} />
    </YoutubeSwiperBox>
  )
}

const YoutubeSwiperBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  magin-bottom: 20px;

  .swiper-slide {
    width: 100%; /* 기본 너비를 100%로 설정 */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    background-color: #ccc;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: #000;
  }
`
