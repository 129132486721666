// 공통 필드를 가진 인터페이스 정의

export interface GetBoardData {
  srchOpt: string
  srchStr: string
  page: number
  bbsTy?: string
  bbsSj?: string
}

export interface BoardListItem {
  cmpnyCd?: string
  cstId?: string
  nttSn: number
  regId?: string
  regDate: string
  updId?: string
  updDate?: string
  lclasCd?: string
  mlsfcCd?: string
  sclasCd?: string
  bbsTy: string
  bbsSj: string
  nttSj: string
  nttCn: string
  guestName: string
  guestEml: string
  nickName: string
  fileSeq: number
  readngCnt: number
  answerCnt: number
  accessIp: string
  replyYn: string
  replyCn: string
  replyRegId: string
  replyRegDate: string
  replyUpdId: string
  replyUpdDate: string
  useYn: string
  delYn: string
  withDrawYn: string
}

export interface BoardDetailItem {
  bbsSj: string
  nttSn: number
  nttSj: string
  nttCn: string
  nickName: string
  withDrawYn: string
  regId: string
  regDate: string
  updId: string
  updDate: string
  accessIp?: string
  readngCnt?: number
  answerCnt?: number
}

// CommentListItem 정의
export interface CommentListItem {
  commentSn: number
  commentCn: string
  cstId: string
  nickName: string
  withDrawYn: string
  regId: string
  regDate: string
  updId: string
  updDate: string
}

export interface CommentsProps {
  commentListCnt: number
  nttSn: number
  commentList: CommentListItem[]
  pathName: string
  nickName?: string
  cstId?: string
  withDraw?: string
  onDataSaved?: () => void
}

export const fieldNames = ['NO.', '제목', '작성자', '작성일', '조회수']

//등록은 alert 불필요, 수정 | 삭제만 필요
export const successMessages = {
  update: '수정되었어요.',
  delete: '삭제되었어요.',
}

export const errorMessages = {
  insert: '저장에 실패했어요.',
  update: '수정에 실패했어요.',
  delete: '삭제에 실패했어요.',
}

export const confirmMessages = {
  insert: '저장할까요?',
  update: '수정할까요?',
  delete: '삭제할까요?',
  cancel: '취소하시겠어요?',
  updateCancel: '수정한 내용은 저장되지 않아요. 정말 취소하시겠어요?',
}

export const fieldMessages = {
  bbsSj: '말머리를 선택해 주세요.',
  nttSj: '제목을 입력해 주세요.',
  nttCn: '내용을 입력해 주세요.',
}

export const buttonName = {
  confirm: '확인',
  cancel: '취소',
}
export const searchMessages = {
  noItem:
    '게시글이 없습니다. 새로운 게시글을 등록하거나 다른검색어로 다시 시도해 주세요.',
}

export const withDrawnCumtomer = {
  name: '탈퇴한 회원',
  contents: '탈퇴한 회원이 작성한 글이에요.',
}

//게시판 말머리 뱃지
export const getBadgeLabel = (code: string): string => {
  const mapping: { [key: string]: string } = {
    '00': '기타',
    '01': '잡담',
    '02': '질문',
    Y: '중요',
  }

  return mapping[code] || '기타'
}

export const maskFormatString = (input: string): string => {
  if (!input) return '' // 빈 값 처리

  if (input.includes('@')) {
    // 이메일 처리
    const [localPart, domainPart] = input.split('@')
    const maskedLocal = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2)
    const maskedEmail = `${maskedLocal}@${domainPart}`

    return maskedEmail.length > 10
      ? maskedEmail.slice(0, 10) + '...'
      : maskedEmail
  }

  // 일반 문자열 처리
  return input.length > 10 ? input.slice(0, 10) + '...' : input
}

export const maskIpSuffix = (ip: string): string => {
  console.log('maskIpSuffix')
  const ipParts = ip.split('.')

  if (ipParts.length !== 4) {
    return ip // 유효하지 않은 IP 형식은 그대로 반환
  }

  // 마지막 두 옥텟을 마스킹
  ipParts[2] = '***'
  ipParts[3] = '***'

  return ipParts.join('.')
}

// 이메일 마스킹 함수
export const maskEmail = (email: string): string => {
  console.log('maskEmail')
  if (!email || !email.includes('@')) {
    return '' // 유효하지 않은 이메일 처리
  }

  const [localPart, domainPart] = email.split('@')

  const maskedLocal = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2)
  return `${maskedLocal}@${domainPart}`
}

export const getHeaderTitle = (pathname: string): string => {
  if (pathname.includes('/board')) {
    return '문의게시판'
  }

  if (pathname.includes('/community')) {
    return '커뮤니티'
  }

  if (pathname.includes('/blog')) {
    return '소통방'
  }

  return '공지사항'
}
