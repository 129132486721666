import Media from '@/components/ui/media/media';
import MediaBody from '@/components/ui/media/media-body';
import styled, { device, themeGet } from '@/styles/styled';
import { Button } from '@/components';

export const StyledMedia = styled(({ ...rest }) => <Media {...rest} />)`
  position: relative;
  height: 100%;
  align-items: stretch;
  justify-content: center;
`

export const StyledMediaBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
  align-items: center;
  display: none;
  ${device.large} {
    display: flex;
  }
`

export const StyledImage = styled.div`
  width: 600px;
`

export const StyledImgText = styled.div`
  font-size: 12px;
  text-align: center;
  left: 0px;
  bottom: -10px;
  position: absolute;
`

export const StyledContainer = styled.div`
  width: 300px;
  height: 240px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid rgb(192, 204, 218);
`

export const StyledTextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  line-height: 1.5;
  p {
    margin: 0;
    font-size: 14px;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`

export const StyledDivider = styled.div`
  minwidth: 1.5px;
  margin: 6px 0;
  background: #8392a5;
`

export const StyledConfirmWrap = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 40px 16px;
  text-align: center;
  margin-bottom: 50%;
  `
  // @media (max-width: 768px) {
  //   width: 100%;
  // }
  // @media (max-width: 480px) {
  //   padding: 24px 12px;
  // }

export const StyledConfirmTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${themeGet('colors.text')};
  margin-bottom: 10px;
  text-align: left;
  .highlight {
    color: ${themeGet('colors.neverbe')};
    }
    `
    
export const StyledConfirmDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.text3')};
  margin-bottom: 32px;
  text-align: left;
    
  `
  // @media (max-width: 480px) {
  //   font-size: 14px;
  //   margin-bottom: 24px;
  // }
export const StyledWrap = styled.div`
  width: 100%;
  padding-top: 20px;
  max-width: 800px;
  width: 70%;
  margin: 0 auto;
  `
  // @media (max-width: 768px) {
  //   width: 100%;
  // }

export const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${themeGet('colors.text')};
  margin-bottom: 10px;
  text-align: left;
  .highlight {
    color: ${themeGet('colors.neverbe')};
    }
`

export const StyledDesc = styled.p`
  font-size: 14px;
  color: ${themeGet('colors.text2')};
  margin-bottom: 40px;
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 56px;
  background-color: ${themeGet('colors.neverbe')};
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  border: none;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.neverbe')};
  }
`

export const StyledBottomText = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  font-size: 13px;

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const StyledBottomDivider = styled.div`
  width: 1px;
  background-color: ${themeGet('colors.light')};
  height: 16px;
  margin: auto 8px;
`