import ListGroupItem from '@/components/ui/list-group/list-group-item'
import Table from '@/components/ui/table/table'
import React from 'react'
import Textarea from '@/components/forms/form-elements/textarea'
import Checkbox from '@/components/forms/form-elements/checkbox'
import Button from '@/components/ui/button/button'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { useDialog } from '@/context/dialogContext'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import numberUtils from '@/utils/numberUtils'

const Container = styled.div`
  text-align: center;
  margin-top: 50px;
`

const Title = styled.h1`
  text-align: left;
`

const SubTitle = styled.p`
  text-align: left;
`

const SectionTitle = styled.h2`
  text-align: left;
  margin-top: 20px; // 상단 여백
  margin-bottom: 20px; // 하단 여백
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 5px;
`

const Member: React.FC = () => {
  const navigate = useNavigate()
  const isLogin = true
  const axios = useAxios()
  const { showAlert, showConfirm } = useDialog()
  const { setFocus } = useForm<{ [key: string]: any }>()
  const [termsResponses, setTermsResponses] = useState<string[]>([])
  const [checkedItems, setCheckedItems] = useState<boolean[]>([
    false,
    false,
    // false,
  ])
  const [errorMessages, setErrorMessages] = useState<string[]>(['', '', ''])

  useEffect(() => {
    fetchTermsData()
  }, [])

  // 약관 정보 가져오기
  const fetchTermsData = async () => {
    try {
      const response = await axios.get('/agree/selectTermsMasterList')
      setTermsResponses(response.data.map((item: any) => item.termsCont))
    } catch (error) {
      console.error('Error fetching terms:', error)
    }
  }

  // 체크박스 상태 변경
  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prev) => {
      const newCheckedItems = [...prev]
      newCheckedItems[index] = !newCheckedItems[index]
      return newCheckedItems
    })

    // 체크하면 오류 메시지 제거
    setErrorMessages((prev) => {
      const newErrors = [...prev]
      newErrors[index] = ''
      return newErrors
    })
  }

  const buttonHandler = (pathName: string) => {
    if (pathName === 'agree') {
      let hasError = false
      const messages = [
        '이용약관에 동의해주세요.',
        '개인 정보 취급 방침에 동의해주세요.',
        // '개인정보 취급 위탁 동의서에 동의해주세요.',
      ]

      // 0, 1, 2번 인덱스를 순차적으로 확인
      for (let index = 0; index < checkedItems.length; index++) {
        if (!checkedItems[index]) {
          showAlert({
            type: 'warning',
            message: messages[index],
            onClose: () => {
              const focusKey = `memberCheck_${index + 1}`
              setFocus(focusKey)
            },
          })
          hasError = true
          break // 오류가 발생하면 더 이상 반복하지 않음
        }
      }

      // 오류가 없으면 동의 진행
      if (!hasError) {
        // 실제 처리
        const routes: Record<string, string> = {
          agree: '/member/newMember',
          cancle: '/main',
        }
        navigate(routes[pathName] || '/member')
      }
    }

    window.scrollTo(0, 0)
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1 style={{ textAlign: 'left' }}>꿀프 회원가입</h1>
      <p style={{ textAlign: 'left' }}>회원가입 -약관동의</p>
      <Table>
        <thead>
          <tr>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'left' }}>
          {termsResponses.map((terms, index) => (
            <React.Fragment key={index}>
              <SectionTitle>
                {
                  [
                    '이용약관',
                    '개인 정보 취급 방침',
                    // '개인정보 취급 위탁 동의서', // 미사용 약관
                  ][index]
                }
              </SectionTitle>
              <Textarea
                id={`memberArea_${index + 1}`}
                name={`memberArea_${index + 1}`}
                value={terms}
                height={'250px'}
              />
              <div style={{ marginTop: '10px' }}></div>
              <Checkbox
                id={`memberCheck_${index + 1}`}
                name={`memberCheck_${index + 1}`}
                label="동의합니다."
                checked={checkedItems[index]}
                onChange={() => handleCheckboxChange(index)}
              />
              {errorMessages[index] && (
                <ErrorMessage>{errorMessages[index]}</ErrorMessage>
              )}
              <br />
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      <Button color="neverbe" onClick={() => buttonHandler('agree')}>
        동의합니다.
      </Button>
      &nbsp;
      <Button
        color="neverbe"
        variant="outlined"
        onClick={() => navigate('/main')}
      >
        동의하지 않습니다.
      </Button>
    </div>
  )
}

export default Member
