import { Button, Input, Radio } from '@/components'
import { FlexWrapper } from '@/components/layout/flex/flexWrapper'
import { StyledLabel } from '@/components/main/graph/modal/style'
import { useRedis } from '@/hooks/useRedis'
import {
  clearSearchFilters,
  GolfSearchState,
  initialSearchState,
  setAreaCountMap,
  setSearchParams,
} from '@/redux/slices/golfSearchSlice'
import { RootState } from '@/redux/store'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useGolfSearchData } from './useGolfSearchData'

interface MainGolfSearchFilterProps {
  filters: GolfSearchState
  updateFilter: (
    key: keyof GolfSearchState,
    value: string | number | null,
  ) => void
}

export const MainGolfSearchFilter = () => {
  const { data } = useGolfSearchData()
  const dispatch = useDispatch()
  const location = useLocation()
  const { getComCodes } = useRedis()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [areaCd, setAreaCd] = useState<string>('')
  const searchParams = useSelector(
    (state: RootState) => state.golfSearch.searchParams,
  )

  const [filters, setFilters] = useState<GolfSearchState>({
    bizNm: searchParams.bizNm,
    minPrice: searchParams.minPrice,
    maxPrice: searchParams.maxPrice,
    areaCd: searchParams.areaCd,
    dayType: searchParams.dayType,
    months: searchParams.months,
    membershipYn: searchParams.membershipYn,
  })

  // const [areaCountMap, setAreaCountMap] = useState<{ [key: string]: number }>(
  //   {},
  // )

  useEffect(() => {
    if (data.length > 0 && totalCount === 0) {
      setTotalCount(data.length) // 최초 데이터 길이 설정
    }
  }, [data])

  // useEffect(() => {
  //   if (filters.areaCd !== undefined) {
  //     // areaCd가 설정되었을 때만 호출
  //     handleSearchClick()
  //   }
  // }, [filters.areaCd])

  useEffect(() => {
    const prevPage = localStorage.getItem('prevPage')
    if (prevPage && prevPage === 'detailInfo') {
      localStorage.removeItem('prevPage')
    } else {
      if (location.pathname === '/main' || location.pathname === '/') {
        setTimeout(() => {
          setFilters(initialSearchState)
          dispatch(clearSearchFilters)
          dispatch(setSearchParams(initialSearchState))
        }, 100)
      }
    }
  }, [location, dispatch])

  const updateFilter = (
    key: keyof GolfSearchState,
    value: string | number | null,
  ) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      console.log('엔터 입력 - 검색 실행', filters)
      handleSearchClick()
    }
  }

  const handleSearchClick = () => {
    // areaCd가 빈 값이 아니면 검색 시작
    dispatch(
      setSearchParams({
        ...filters,
        _timestamp: Date.now(),
      }),
    )
  }

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState)
  }

  const areaCountMap = useSelector(
    (state: RootState) => state.golfSearch.areaCountMap,
  );

  useEffect(() => {
    if (data.length > 0 && Object.keys(areaCountMap).length === 0) {
      let totCnt = 0;
      const countMap = data.reduce(
        (acc, item) => {
          acc[item.areaCd] = item.areaCount
          totCnt ++;
          return acc
        },
        {} as { [key: string]: number },
      )
      countMap['00'] = totCnt;

      dispatch(setAreaCountMap(countMap));
      // setAreaCountMap(countMap) // areaCountMap 최초 한 번 설정
    }
  }, [data])

  /*  //2025.03.19 ljh 정보제공 목적으로 로그인 후 이용 옵션 제거
  const handleConfirm = () => {
    let msg =
      '로그인 후 골프장검색 메뉴에서 이용할 수 있는 옵션이에요. 로그인화면으로 이동할까요?'
    if (isAuthenticated)
      msg =
        '골프장검색 메뉴에서 이용할 수 있는 옵션이에요. 해당메뉴로 이동할까요?'

    showConfirm({
      title: msg,
      onConfirm() {
        if (isAuthenticated) {
          navigate('/golf/detailSearch')
        } else {
          navigate('/login', {
            state: {
              redirectTo: '/golf/detailSearch',
            },
          })
        }
      },
    })
  }
*/
  return (
    <form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          width: '100%',
          gap: '5px',
        }}
      >
        <Input
          type="text"
          id="searchKeyword"
          name="searchKeyword"
          width={'300px'}
          placeholder="골프장명을 입력해주세요."
          value={filters.bizNm || ''}
          onChange={(e) => updateFilter('bizNm', e.target.value)}
          onKeyDown={handleKeyDown}
          maxLength={100}
          style={{
            paddingRight: '30px',
          }}
        />
        {filters.bizNm && (
          <X
            onClick={() => updateFilter('bizNm', null)}
            style={{
              position: 'absolute',
              right: '160px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              width: '20px',
              height: '20px',
            }}
          />
        )}
        <Button color="neverbe" onClick={handleSearchClick}>
          검색
        </Button>
        <Button variant="outlined" color="neverbe" onClick={toggleVisibility}>
          {isVisible ? '접어두기' : '상세검색'}
        </Button>
      </div>
      {/* <div style={{ flex: 1 }}>
          <StyledLabel htmlFor="region">지역</StyledLabel>
          <Select
            id="region"
            name="region"
            value={filters.areaCd || ''}
            onChange={(e) => updateFilter('areaCd', e.target.value)}
          >
            {regions.map((item) => (
              <option key={item.code} value={item.code}>
                {item.name}
              </option>
            ))}
          </Select>
        </div> */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          marginTop: '20px',
          justifyContent: 'flex-start', // 왼쪽 정렬
        }}
      >
        {
        
        getComCodes('AREA_CD').map((item) => {
          const areaCount = areaCountMap[item.code] || 0 // 기본값 0
          return (
            <Button
              key={item.code}
              variant={
                filters.areaCd && filters.areaCd == item.code ? "contained" : "outlined"
              }
              color="neverbe"
              size="lg"
              borderRadius="20px"
              active={areaCd === item.code}
              onClick={() => {
                updateFilter('areaCd', item.code === '00' ? '' : item.code)
              }}
            >
              {item.remark} ({areaCount})
            </Button>
          )
        })}
      </div>
      {isVisible && (
        <>
          <FlexWrapper
            gap="20px"
            style={{
              marginTop: '10px',
              marginLeft: '10px',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              <StyledLabel>요금 구분</StyledLabel>
              <FlexWrapper>
                <Radio
                  id="weekday"
                  name="dayType"
                  value="weekday"
                  checked={filters.dayType === 'weekday'}
                  onChange={(e) => updateFilter('dayType', e.target.value)}
                  readonly={true}
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  label="주중"
                />
                <Radio
                  id="weekend"
                  name="dayType"
                  value="weekend"
                  checked={filters.dayType === 'weekend'}
                  onChange={(e) => updateFilter('dayType', e.target.value)}
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  label="주말"
                />
                <InputRow style={{ marginLeft: '10px' }}>
                  <Input
                    id="greenFeeMin"
                    name="greenFeeMin"
                    type="number"
                    placeholder="최소 금액"
                    value={filters.minPrice || ''}
                    onChange={(e) =>
                      updateFilter(
                        'minPrice',
                        e.target.value ? Number(e.target.value) : null,
                      )
                    }
                    onKeyDown={handleKeyDown}
                    maxLength={6}
                    step={10000}
                  />
                  <span>~</span>
                  <Input
                    id="greenFeeMax"
                    name="greenFeeMax"
                    type="number"
                    placeholder="최대 금액"
                    value={filters.maxPrice || ''}
                    onChange={(e) =>
                      updateFilter(
                        'maxPrice',
                        e.target.value ? Number(e.target.value) : null,
                      )
                    }
                    onKeyDown={handleKeyDown}
                    maxLength={6}
                    step={10000}
                  />
                </InputRow>
              </FlexWrapper>
            </div>
            <div>
              <StyledLabel>회원제 구분</StyledLabel>
              <FlexWrapper>
                <Radio
                  id="membershipA"
                  name="membershipYn"
                  value="A"
                  checked={filters.membershipYn === 'A'}
                  onChange={(e) => updateFilter('membershipYn', e.target.value)}
                  label="전체"
                  onMouseDown={(e) => {
                    e.preventDefault()
                    //handleConfirm()
                  }}
                />
                <Radio
                  id="membershipY"
                  name="membershipYn"
                  value="Y"
                  checked={filters.membershipYn === 'Y'}
                  onChange={(e) => updateFilter('membershipYn', e.target.value)}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    //handleConfirm()
                  }}
                  label="회원제"
                />
                <Radio
                  id="membershipN"
                  name="membershipYn"
                  value="N"
                  checked={filters.membershipYn === 'N'}
                  onChange={(e) => updateFilter('membershipYn', e.target.value)}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    //handleConfirm()
                  }}
                  label="비회원제"
                />
                <div style={{ marginLeft: '10px' }} />
                <Button color="neverbe" onClick={handleSearchClick}>
                  검색
                </Button>
              </FlexWrapper>
            </div>
          </FlexWrapper>
        </>
      )}
    </form>
  )
}

const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    width: 100%;
  }

  span {
    margin: 0 5px;
  }
`
