import Button from '@/components/ui/button/button'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { useDialog } from '@/context/dialogContext'
import { buttonName } from '../boardItem'

export const BoardHeader = ({ title }: { title: string }) => {
  const [show, setShow] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const { showAlert, showConfirm } = useDialog()
  const isWritePage =
    location.pathname.endsWith('/write') ||
    location.pathname.includes('/detail')

  useEffect(() => {
    if (show) {
      document.body.classList.add('doar-modal-open')
    } else {
      document.body.classList.remove('doar-modal-open')
    }
    return () => {
      document.body.classList.remove('doar-modal-open')
    }
  }, [show])

  const createPost = () => {
    if (pathname === '/board') {
      navigate(`${pathname}/write`)
    }

    if (pathname === '/blog' && !isAuthenticated) {
      showAlert({
        title: '로그인 후 글 작성이 가능해요.',
        btnName: buttonName.confirm,
      })
    }

    if (pathname === '/blog' && isAuthenticated) {
      navigate(`${pathname}/write`)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '10px',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
      <div
        style={{ textAlign: 'center', fontSize: '20px', cursor: 'pointer' }}
        onClick={() => navigate(0)}
      >
        {title}
      </div>
      {!isWritePage && (
        <>
          {/* <Button color="neverbe" onClick={() => navigate(`${pathname}/write`)}>
            글쓰기
          </Button> */}
          <Button color="neverbe" onClick={createPost}>
            글쓰기
          </Button>
        </>
      )}
    </div>
  )
}
