import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'
import { fieldNames, NoticeListItem } from '../noticeItem'
import { Badge, Button, Input, Select, Table } from '@/components'
import { useLocation, useNavigate } from 'react-router-dom'
import Pagination from '@/pages/board/_components/pagination'
import { getBadgeLabel } from '@/pages/board/boardItem'
import commonUtils from '@/utils/commonUtils'
import numberUtils from '@/utils/numberUtils'
import { NoticePopup } from './noticePopup'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

export interface GetNoticeData {
  srchOpt?: string //검색옵션
  srchStr?: string //검색어
  page?: number //페이지
}

export const NoticeList = () => {
  const axios = useAxios()
  const [noticeList, setNoticeList] = useState<NoticeListItem[]>([])

  const navigate = useNavigate()
  const location = useLocation()
  const [totalCount, setTotalCount] = useState(0) //페이지 count
  const [page, setPage] = useState(location.state?.page || 1)
  const [bbsTy, setBbsTY] = useState('01')
  const [bbsSj, setBbsSj] = useState('')
  const [srchData, setSrchData] = useState({
    srchOpt: location.state?.srchOpt || '01',
    srchStr: location.state?.srchStr || '',
  })

  const adminName = '운영자'
  // API 호출을 위한 useEffect 훅
  useEffect(() => {
    // API 호출 함수
    getNoticeList() // 컴포넌트 마운트 시 API 호출
  }, [page]) // 빈 배열을 넣으면 처음 한 번만 호출됨

  const handlePageChange = (page: number) => {
    setPage(page)
    setBbsTY(bbsTy)
  }

  const handleSearch = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setSrchData((prev) => ({ ...prev, [name]: value }))
  }
  const readNotices = JSON.parse(localStorage.getItem('readNotices') || '[]')

  //글상세보기
  const handleDetailView = (noticeSn: number) => {
    navigate(`/notice/detail?noticeSn=${noticeSn}`, {
      state: {
        page: page,
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
      },
    })
  }

  console.log('totalCount', totalCount)
  const isNewNotice = (regDate: string): boolean => {
    const today = new Date()
    const noticeDate = new Date(
      `${regDate.substring(0, 4)}-${regDate.substring(4, 6)}-${regDate.substring(6, 8)}`,
    )
    const diffTime = today.getTime() - noticeDate.getTime()
    const diffDays = diffTime / (1000 * 3600 * 24)
    return diffDays <= 7 // 7일 이내이면 NEW 표시
  }

  const getNoticeList = async () => {
    try {
      const data: GetNoticeData = {
        page: page,
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
      }
      const response = await axios.get(`/notice/list`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })
      const { noticeList, pageCnt } = response.data.data
      // 응답 데이터가 배열인지 확인하고 상태 업데이트
      if (Array.isArray(noticeList)) {
        setNoticeList(noticeList)
      }
      setTotalCount(pageCnt)
    } catch (err: any) {
      console.error('Error fetching noticeList:', err)
    }
  }

  const commonStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center' as const,
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getNoticeList()
    }
  }

  return (
    <>
      <div>
        <Table
          hover={true}
          bordered={true}
          style={{ width: '100%', tableLayout: 'auto', overflow: 'hidden' }}
        >
          <thead>
            {fieldNames.map((header, index) => (
              <th key={index} scope="col" style={commonStyle}>
                {header}
              </th>
            ))}
          </thead>
          <tbody>
            {noticeList.length > 0 ? (
              (() => {
                const noticePosts = noticeList.filter(
                  (item) => item.tpNoticeYn === 'Y',
                ) // 공지글
                const normalPosts = noticeList.filter(
                  (item) => item.tpNoticeYn !== 'Y',
                ) // 일반 글

                const noticeCount = noticePosts.length // 공지글 개수
                const totalNormalPosts = totalCount - noticeCount // 전체 일반 글 개수 (DB에서 가져온 전체 데이터 수)

                const startNumber = totalNormalPosts - (page - 1) * 10 // 현재 페이지에서 시작할 번호

                const sortedNotices = [...noticePosts, ...normalPosts] // 공지글 + 일반 글

                return sortedNotices.map((item, index) => {
                  const isRead = readNotices.includes(item.noticeSn)
                  const isNew = isNewNotice(item.regDate)

                  const displayNumber =
                    item.tpNoticeYn === 'Y'
                      ? '-'
                      : startNumber - (index - noticeCount)

                  return (
                    <tr key={item.noticeSn}>
                      <td style={{ textAlign: 'right', width: '30px' }}>
                        {displayNumber}
                      </td>
                      <td
                        style={{ textAlign: 'left', cursor: 'pointer' }}
                        onClick={() => handleDetailView(item.noticeSn)}
                      >
                        {item.tpNoticeYn === 'Y' && (
                          <Badge color="neverbe">
                            {getBadgeLabel(item.tpNoticeYn)}
                          </Badge>
                        )}
                        &nbsp;&nbsp;
                        {item.noticeSj}
                        &nbsp;&nbsp;
                        {isNew && !isRead && <Badge color="warning">N</Badge>}
                      </td>
                      <td style={{ textAlign: 'center', width: '50px' }}>
                        {adminName}
                      </td>
                      <td style={{ textAlign: 'center', width: '50px' }}>
                        {commonUtils.formatDate(item.regDate, false)}
                      </td>
                      <td style={{ textAlign: 'right', width: '40px' }}>
                        {numberUtils.formatViewCount(item.readngCnt)}
                      </td>
                    </tr>
                  )
                })
              })()
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  게시글이 없습니다. 다른검색어로 다시 시도해 주세요.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div style={{ display: 'flex' }}>
          <div style={{ marginLeft: 'auto' }}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Select
                id="srchOpt"
                name="srchOpt"
                width="100px"
                value={srchData.srchOpt}
                onChange={handleSearch}
              >
                <option value="01">제목</option>
                <option value="02">내용</option>
                <option value="03">제목+내용</option>
              </Select>
              <Input
                id="srchStr"
                name="srchStr"
                placeholder="검색어를 입력하세요."
                width="300px"
                value={srchData.srchStr}
                onChange={handleSearch}
                onKeyDown={handleKeyDown}
              />
              <Button color="neverbe" onClick={getNoticeList}>
                검색
              </Button>
            </div>
          </div>
        </div>
      </div>
      {totalCount > 0 && (
        <Pagination
          totalCount={totalCount}
          onPageChange={handlePageChange}
          paramPage={location.state?.page || 1}
        />
      )}
    </>
  )
}
