import React, { useEffect } from "react";
import { StyledKmcButton, StyledKmcDesc, StyledKmcTitle, StyledKmcWrap } from "./kmcAuthStyle";
import { useNavigate } from "react-router-dom";
import { useNiceAuth } from "@/hooks/useNiceAuth";

interface Props {
    title:string,
    desc:string,
    callBack:any,
}

const KmcAuth: React.FC<Props> = (
    { 
        title='휴대폰인증', 
        desc='본인인증이 필요합니다.',  
        callBack=() => {},
    }
) => {
    const { authData, requestNiceAuth } = useNiceAuth();

    const navigate = useNavigate();

    const callKmcAuth = () => {
        if(typeof callBack === 'function'){
            callBack(authData);
        }else{
            navigate('/main');
        }
    };
    useEffect(() => {
        if(authData != null){
            callKmcAuth();
        }
    },[authData]);

    return (
        <>
        <StyledKmcWrap>
            <StyledKmcTitle>
                {title}
            </StyledKmcTitle>
            <StyledKmcDesc>{desc}</StyledKmcDesc>
            <StyledKmcButton onClick={requestNiceAuth}>휴대폰인증</StyledKmcButton>
        </StyledKmcWrap>
        </>
    );
};

export default KmcAuth;