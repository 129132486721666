import React, { useEffect, useState } from 'react'
import { useAxios } from '@/context/axiosContext'
import { LikdeList, RankList, YoutubeRankData } from './youtubeRankItem'
import { useDialog } from '@/context/dialogContext'
import { YoutubeListItem } from './_components/youtubeListItem'
import { useAppSelector } from '@/redux/hooks'
import styled from 'styled-components'

const NoticeWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 800px;
  padding-top: 5px;
  padding-left: 5px;
`

const AccordionHeader = styled.button`
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`

const NoticeContent = styled.div`
  font-size: 14px;
  color: #555;
  line-height: 1.5;

  &::before {
    content: '⛳';
    margin-right: 5px;
    font-size: 16px;
  }
`

const YoutubeRank: React.FC = () => {
  const axios = useAxios()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const [listItem, setListItem] = useState<YoutubeRankData[]>([])
  const [likedItem, setLikedItem] = useState<YoutubeRankData[]>([])
  const [isOpen, setIsOpen] = useState(true)
  const { showAlert } = useDialog()

  useEffect(() => {
    getRankList()
  }, [])

  useEffect(() => {
    getLikedList()
  }, [listItem])

  const getRankList = async () => {
    const response = await axios.get(`/youtube/getRankList`, {
      headers: { 'Cache-Control': 'no-cache' },
    })

    const { rankList } = response.data.data

    if (rankList && Array.isArray(rankList)) {
      setListItem(rankList)
    }
  }

  const getLikedList = async () => {
    const response = await axios.get(`/youtube/getLikedList`, {
      headers: { 'Cache-Control': 'no-cache' },
    })

    const { likedList } = response.data.data

    if (likedList && Array.isArray(likedList)) {
      setLikedItem(likedList)
    }
  }

  const setLikeStatus = async (
    formData: FormData,
    chnlId: string,
    currentLikeStatus: string,
  ) => {
    try {
      await axios.post(`/youtube/like`, formData)

      // API 성공 후 데이터 업데이트 (likeCount 조정)
      setListItem((prevItems) =>
        prevItems.map((item) =>
          item.chnlId === chnlId
            ? {
                ...item,
                likeCount:
                  currentLikeStatus === 'Y'
                    ? item.likeCount - 1
                    : item.likeCount + 1,
                userLikeYn: currentLikeStatus === 'Y' ? 'N' : 'Y',
              }
            : item,
        ),
      )
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: '좋아요 요청이 되지 않았어요.',
      })
    }
  }

  return (
    <>
      {isAuthenticated && likedItem.length > 0 && (
        <YoutubeListItem
          title={LikdeList.title}
          data={likedItem}
          setLikeStatus={setLikeStatus}
        />
      )}
      <YoutubeListItem
        title={RankList.title}
        noti={RankList.noti}
        data={listItem}
        setLikeStatus={setLikeStatus}
      />
      <NoticeWrapper>
        <AccordionHeader onClick={() => setIsOpen(!isOpen)}>
          안내사항 {isOpen ? '▲' : '▼'}
        </AccordionHeader>
        {isOpen && (
          <>
            <NoticeContent>
              골프 레슨, 필드 영상, 다양한 정보 등 대한민국 골프 유튜브 채널을
              소개합니다.
            </NoticeContent>
            <NoticeContent>
              구독자 10만 이상 유튜브 채널만 출력됩니다. (매일 오전 8시에
              갱신됩니다.)
            </NoticeContent>
            <NoticeContent>
              3개월 이상 활동하지 않는 유튜브 채널은 목록에서 제외됩니다.
            </NoticeContent>
            <NoticeContent>
              구독자 10만 이상인 채널이 목록에 없다면, 문의게시판을 통해
              알려주세요. 확인 후 빠르게 반영하겠습니다.
            </NoticeContent>
          </>
        )}
      </NoticeWrapper>
    </>
  )
}

export default YoutubeRank
