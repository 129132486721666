import { getHeaderTitle } from '../boardItem'
import { BoardHeader } from '../_components/boardHeader'
import { BlogEditor } from './blogEditor'

export const InsertBlog = () => {
  const headerTitle = getHeaderTitle(window.location.pathname)
  return (
    <>
      <BoardHeader title={headerTitle} />
      <BlogEditor />
    </>
  )
}
