import { Button, Modal, ModalBody } from '@/components'
import { useAxios } from '@/context/axiosContext'
import { useAppSelector } from '@/redux/hooks'
import { Ref, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ModalFooter from '@/components/ui/modal/modal-header'
import {
  buttonName,
  CommentsProps,
  confirmMessages,
  fieldMessages,
  successMessages,
} from '../boardItem'
import { AdminComment } from './adminComment'
import { CustomerComment } from './customerComment'
import { InsertComment } from './insertComment'
import { useDialog } from '@/context/dialogContext'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
type ModalAction = 'insert' | 'delete' | 'update'

interface Data {
  //cmpnyCd: string
  nttSn: number
  commentSn: number
  option: string
}

export const Comments = (props: CommentsProps) => {
  const axios = useAxios()
  const navigate = useNavigate()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    nttSn: props.nttSn,
    commentCn: '',
    cstId: user?.cstId, //고객번호 (우선하드코딩) 로그인데이터를 가져오도록 수정필요
    userId: user?.userId, //userId
  })

  const { showAlert, showConfirm } = useDialog()
  const [searchParams] = useSearchParams()
  const nttSn = Number(searchParams.get('nttSn')) // 게시판 번호

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  // 공통 처리 함수
  const handleCommentAction = (action: ModalAction, commentSn?: any) => {
    // 선택된 item이 있다면 해당 item의 COMMENT_SN을 저장

    if (action === 'insert' && !formData.commentCn) {
      showAlert({
        type: 'warning',
        title: fieldMessages.nttCn,
        btnName: buttonName.confirm,
      })
      return
    }

    if (action === 'insert') {
      showConfirm({
        title: confirmMessages.insert,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          insertComment()
        },
      })
    }

    if (action === 'delete') {
      showConfirm({
        title: confirmMessages.delete,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          deleteComment(commentSn)
        },
      })
    }
  }

  const insertComment = async () => {
    try {
      const response = await axios.post(
        `/${props.pathName}/comment/insert`,
        formData,
      )

      navigate(0)
    } catch (err: any) {
      console.error('Error insertComment:', err)
    }
  }

  const deleteComment = async (commentSn: number) => {
    const data: Data = {
      //cmpnyCd: cmpnyCd,
      nttSn: nttSn,
      commentSn: commentSn,
      option: 'DEL',
    }

    try {
      await axios.post(`/${props.pathName}/comment/delete`, data)
      showAlert({
        type: 'success',
        title: successMessages.delete,
        onClose() {
          setTimeout(() => {
            navigate(0)
          }, 100)
        },
      })
    } catch (err: any) {
      console.error('Error deleteComment :', err)
    }
  }

  console.log('commentList', props.commentList)
  return (
    <>
      {props.commentListCnt > 0 && (
        <div style={{ marginBottom: '10px', color: '#979797' }}>
          댓글 [{props.commentListCnt}]
        </div>
      )}

      <AdminComment commentList={props.commentList} />
      <CustomerComment
        commentList={props.commentList}
        handleCommentAction={handleCommentAction}
        isAuthenticated={isAuthenticated}
        userCstId={user?.cstId}
      />
      {/* 문의게시판은 어드민 답변만 달 수 있음, 댓글도 어드민은 추가 가능함, 일반 회원 | 비회원은 댓글 금지 */}
      {props.pathName !== 'board' && (
        <>
          {isAuthenticated && (
            <InsertComment
              formData={formData}
              nickName={props?.nickName}
              handleCommentAction={handleCommentAction}
              handleChange={handleChange}
            />
          )}
        </>
      )}
    </>
  )
}
