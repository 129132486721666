import styled, { themeGet } from '@/styles/styled';
import { Button } from '@/components';

export const StyledKmcWrap = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  padding: 40px 16px;
  text-align: center;
  margin-bottom: 50%;
  `
  // @media (max-width: 480px) {
  //   padding: 24px 12px;
  // }

export const StyledKmcTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: ${themeGet('colors.text')};
  margin-bottom: 10px;
  text-align: left;
  .highlight {
    color: ${themeGet('colors.neverbe')};
    }
  `
    
export const StyledKmcDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.text3')};
  margin-bottom: 32px;
  text-align: left;
    
  `
  // @media (max-width: 480px) {
  //   font-size: 14px;
  //   margin-bottom: 24px;
  // }

export const StyledKmcButton = styled(Button)`
  width: 100%;
  height: 56px;
  background-color: ${themeGet('colors.neverbe')};
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  border: none;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.neverbe')};
  }
`