import React, { useEffect, useState } from "react";
import { useAxios } from '@/context/axiosContext'
import { useNavigate } from "react-router-dom";
import { StyledWrap,  StyledDesc, StyledTitle, StyledConfirmButton, StyledCancelButton } from "./style";
import FormGroup from "@/components/forms/form-elements/form-group";
import { FlexWrapper } from "@/components/layout/flex/flexWrapper";
import Input from "@/components/forms/form-elements/input";
import Label from "@/components/forms/form-elements/label";

import commonUtils from '@/utils/commonUtils';
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@/components/ui/button/button";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";

import { logout } from '@/redux/slices/auth';
import { useDialog } from "@/context/dialogContext";

interface Props {
    kmcResult:any,
}

interface PostData {
    ci: string
    userId: string
    password: string
    password_confm: string
}

const ResetMemberPwd: React.FC<Props> = (
    {
        kmcResult = {
            ci : '',
        }
    }
) => {

    const axios = useAxios();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { showAlert, } = useDialog();

    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        getValues,
        trigger,
        formState: { errors },
    } = useForm<PostData>({
        defaultValues: {
        },
    });

    const [memberId, setMemberId] = useState('');
    const [isMember, setIsMember] = useState(false);


    const getMemberId = async () => {
        const response = await axios.post('/member/getMemberId', {ci : kmcResult.ci});
        if (response.data.common.resultCode === 'MB200') {
            setIsMember(true);
            setMemberId(response.data.data.result || '');
        }else{
            showAlert({
                type : 'error',
                message : response.data.common.resultMsg,
                title :response.data.common.resultCode,
            });
        }
    };

    const updatePassword: SubmitHandler<PostData> = async (data) => {
        data.ci = kmcResult?.ci || '';
        data.userId = memberId;
        const response = await axios.post('/member/updatePassword', data);
        if (response.data.common.resultCode === 'MB200') {
            if(response.data.data.row > 0){
                if(!isAuthenticated){
                    showAlert({
                        type: 'success',
                        message: `변경이 완료되었습니다.`,
                        onClose : () => {
                            navigate('/login');
                        }
                    });

                }else{
                    showAlert({
                        type : 'success',
                        message : `변경이 완료되었습니다.\n다시 로그인 해주세요.`,
                        onClose : async () => {
                            await axios.post('/auth/logout')
                            dispatch(logout());
                            navigate('/login');
                        }
                    });
                }
            }else{
                showAlert({
                    type : 'error',
                    message : '변경 실패하였습니다.',
                });
            }
        }else{
            showAlert({
                type : 'error',
                message : response.data.common.resultMsg,
                title :response.data.common.resultCode,
            });
        }
    };

    useEffect(() => {
        // 로그인중일때는 세션으로 들어와야됨
        if(!isAuthenticated){
            // 비로그인일때 회원조회 - 로그인화면에서 진입
            getMemberId();
        }else{
            // 로그인일때 - 마이페이지에서 진입
            setIsMember(true);
            setMemberId(user?.userId||'');
        }
    }, []);

    return (
        isMember ?
        <StyledWrap>
            <StyledTitle>비밀번호 재설정</StyledTitle>
            <StyledDesc></StyledDesc>
            <form action="#" onSubmit={handleSubmit(updatePassword)} noValidate>
                <FormGroup mb="20px">
                    <FlexWrapper>
                        <div style={{ padding: '5px', width: '20%' }}>
                        <Label display="block" mb="5px" htmlFor="password">
                            비밀번호
                        </Label>
                        </div>
                        <div style={{ width: '60%' }}>
                        <Input
                            {...register('password', { required: true })}
                            id="password"
                            name="password"
                            type="password"
                            placeholder="비밀번호를 입력해주세요."
                        />
                        </div>
                        <div style={{ width: '20%' }}></div>
                    </FlexWrapper>
                    </FormGroup>
                    <FormGroup mb="20px">
                    <FlexWrapper>
                        <div style={{ padding: '5px', width: '20%' }}>
                        <Label display="block" mb="5px" htmlFor="password_confm">
                            비밀번호 확인
                        </Label>
                        </div>
                        <div style={{ width: '60%' }}>
                        <Input
                            {...register('password_confm', {
                            required: true,
                            validate: (value) =>
                                value === getValues('password') ||
                                '비밀번호가 일치하지 않습니다.',
                            onBlur: () => trigger('password_confm'),
                            })}
                            id="password_confm"
                            name="password_confm"
                            type="password"
                            placeholder="비밀번호 확인을 입력해주세요."
                            feedbackText={errors?.password_confm?.message} // 에러 메시지 처리
                            state={
                            commonUtils.hasKey(errors, 'password_confm')
                                ? 'error'
                                : 'success'
                            } // 상태 처리
                            showState={!!commonUtils.hasKey(errors, 'password_confm')} // 상태 노출 여부
                        />
                        </div>
                        <div style={{ width: '20%' }}></div>
                    </FlexWrapper>
                </FormGroup>
                <FormGroup mb="20px" margin={'AlignCenter'}>
                    <Button type="submit" color="neverbe" width="100px">
                        확인
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                        color="neverbe"
                        variant="outlined"
                        width="100px"
                        onClick={() => {navigate(-1);}}
                    >
                        취소
                    </Button>
                </FormGroup>
            </form>
        </StyledWrap>:
        <StyledWrap>
            <StyledTitle>비밀번호 재설정</StyledTitle>
            <StyledDesc>존재하지 않는 회원입니다.</StyledDesc>
            <StyledConfirmButton
                color="neverbe"
                onClick={() => {
                    navigate('/memnber');
                }}>회원가입</StyledConfirmButton>
            <StyledCancelButton
                color="neverbe"
                variant="outlined"
                onClick={() => {
                    navigate('/main');
                }}>메인</StyledCancelButton>
        </StyledWrap>
    );
}

export default ResetMemberPwd;