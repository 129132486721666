import { useAxios } from '@/context/axiosContext'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import FormGroup from '../forms/form-elements/form-group'
import { FlexWrapper } from '../layout/flex/flexWrapper'
import Label from '../forms/form-elements/label'
import Input from '../forms/form-elements/input'
import Button from '../ui/button/button'

interface PostData {
  file?: any
  fileSeq?: string
}

const FileUploadSample: React.FC = () => {
  const axios = useAxios()
  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<PostData>({
    defaultValues: {},
  })

  const [uploadFile, setUploadFile] = useState(null)
  const [binaryFile, setBinaryFile] = useState(null)

  const uploadSample: SubmitHandler<PostData> = async (data) => {
    const formData = new FormData()
    if (uploadFile != null) {
      formData.append('file', uploadFile)
    }
    const response = await axios.post('/sample/fileUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 이 헤더는 axios가 자동으로 설정할 수 있으므로 생략 가능
      },
    })
    console.log('@@@@ upload resp.. ', response)
    if (response.status === 200) {
      alert(response.data.data.fileSeq)
    }
  }
  const downloadSample: SubmitHandler<PostData> = async (data) => {
    const response = await axios.post('/sample/fileDownload', {
      fileSeq: data.fileSeq,
    })
    console.log('@@@@ download resp.. ', response)
    if (response.status === 200) {
      setBinaryFile(response.data.data.file?.binaryFile)
    }
  }

  return (
    <>
      <form action="#" onSubmit={handleSubmit(uploadSample)} noValidate>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="password">
                파일업로드
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('file', {})}
                id="file"
                name="file"
                type="file"
                onChange={(e: any) => {
                  console.log('file tag has changed.. ', e)
                  if (e.target.files && e.target.files.length > 0) {
                    setUploadFile(e.target.files[0])
                  }
                }}
              />
            </div>
            <Button type="submit" color="neverbe" width="100px">
              업로드
            </Button>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px" margin={'AlignCenter'}>
          &nbsp;&nbsp;&nbsp;
        </FormGroup>
      </form>
      <form action="#" onSubmit={handleSubmit(downloadSample)} noValidate>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="password">
                파일번호
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('fileSeq', {})}
                id="fileSeq"
                name="fileSeq"
                onChange={(e: any) => {
                  // 내용 변경되면 초기화하기
                  setBinaryFile(null)
                }}
              />
            </div>
            <Button type="submit" color="neverbe" width="100px">
              가져오기
            </Button>
          </FlexWrapper>
        </FormGroup>
        <FormGroup>
          <FlexWrapper>
            <div>
              <img
                src={binaryFile ? 'data:image/jpeg;base64,' + binaryFile : ''}
              />
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px" margin={'AlignCenter'}>
          &nbsp;&nbsp;&nbsp;
        </FormGroup>
      </form>
    </>
  )
}

export default FileUploadSample
