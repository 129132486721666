import Layout from '@/components/common/layout'
import Loading from '@/components/common/loading'
import { SEO } from '@/components/seo/seo'
import AppRoutes from '@/routes/AppRoutes'
import { BrowserRouter as Router } from 'react-router-dom'
import ErrorBoundary from './components/error/ErrorBoundary'

function App() {
  return (
    <Router>
        <Loading />
        <Layout>
          <SEO />
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
        </Layout>
    </Router>
  )
}

export default App
