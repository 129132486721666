import styled from 'styled-components'

const Container = styled.div`
  margin: 40px auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 24px;
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 26px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
`

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`

const TableCellHeader = styled.td`
  width: 15%;
  background: #f8f8f8;
  font-weight: bold;
  padding: 12px;
  border-right: 1px solid #ddd;
  text-align: left;
`

const TableCell = styled.td`
  width: 35%;
  padding: 12px;
  border-right: 1px solid #ddd;

  &:last-child {
    border-right: none;
  }
`

const PriceRow = styled.tr`
  font-weight: bold;
`

const PriceCellHeader = styled.td`
  background-color: #d09d30;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`

export {
  Container,
  Title,
  Table,
  TableRow,
  TableCellHeader,
  TableCell,
  PriceRow,
  PriceCellHeader,
}
