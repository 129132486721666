import { Button, Input, Select } from '@/components'
import { useEffect, useRef, useState } from 'react'
import { Editor } from '@toast-ui/react-editor'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import '@toast-ui/editor/dist/i18n/ko-kr'
import '@toast-ui/editor/dist/toastui-editor.css'
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import { useAxios } from '@/context/axiosContext'
import { useDialog } from '@/context/dialogContext'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  buttonName,
  confirmMessages,
  errorMessages,
  fieldMessages,
} from '../boardItem'
import { BlogListItem, FileUploadResponse, ThumbnailItem } from '../blogItem'
import { useAppSelector } from '@/redux/hooks'
export type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

export const options = [
  { value: '', label: '말머리 선택' },
  { value: '01', label: '잡담' },
  { value: '02', label: '질문' },
  { value: '00', label: '기타' },
]

export const BlogEditor = () => {
  const axios = useAxios()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation()
  const { showAlert, showConfirm } = useDialog()
  const editorRef = useRef<Editor>(null)
  const [imgUrl, setImgUrl] = useState<string>('')
  const [fileSeqList, setFileSeqList] = useState<number[]>([])
  const [fileNameList, setFileNameList] = useState<string[]>([])
  const [formData, setFormData] = useState<BlogListItem>({
    cmpnyCd: '001',
    cstId: user?.cstId || '',
    nttSn: 0,
    regId: user?.cstId || '',
    regDate: '',
    updId: user?.cstId || '',
    updDate: '',
    bbsTy: '01',
    bbsSj: '',
    nttSj: '',
    nttCn: '',
    imgUrl: imgUrl,
    page: 1,
    srchOpt: '',
    srchStr: '',
    option: '',
    accessIp: '',
    fileSeqList: fileSeqList,
    fileNameList: fileNameList,
  })

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current
        .getInstance()
        .addHook(
          'addImageBlobHook',
          async (
            blob: Blob,
            callback: (url: string, altText: string) => void,
          ) => {
            const { fileSeq, fileUrl } = await uploadImageToServer(blob)

            if (fileUrl) {
              // 먼저 이미지 URL을 ToastUI에 삽입하도록 callback을 사용
              callback(fileUrl, '')
              // 이미지 삽입 후 커스텀 속성을 추가
              // const editorInstance = editorRef.current?.getInstance()
              // if (editorInstance) {
              //   // 에디터 콘텐츠에서 마지막에 삽입된 이미지 태그를 찾아서 속성 추가
              //   const currentHTML = editorInstance.getHTML()
              //   // const updatedHTML = currentHTML.replace(
              //   //   `<img src="${fileUrl}" alt="" />`,
              //   //   `<img src="${fileUrl}" alt="uploaded image" data-id="${fileSeq}" data-category="uploaded" />`,
              //   // )
              //   const updatedHTML = currentHTML.replace(
              //     new RegExp(`<img src="${fileUrl}"([^>]*)>`, 'g'),
              //     (match, p1) => {
              //       // 기존 속성 뒤에 data-id와 data-category를 추가
              //       return `<img src="${fileUrl}" alt="uploaded image" ${p1} data-id="${fileSeq}" data-category="uploaded" />`
              //     },
              //   )
              //   // 속성이 추가된 HTML로 업데이트
              //   editorInstance.setHTML(updatedHTML)
              // }
            }
            return false
          },
        )
    }
  }, [])

  const mySanitizer = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html')

    doc.querySelectorAll('img').forEach((img) => {
      img.setAttribute('data-category', 'uploaded') // 'data-category' 속성 추가
      img.setAttribute('data-id', '0')
    })

    return doc.body.innerHTML
  }

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      fileSeqList: fileSeqList,
      fileNameList: fileNameList,
    }))
  }, [fileSeqList, fileNameList])

  const uploadImageToServer = async (
    blob: Blob,
  ): Promise<{ fileUrl: string; fileSeq: number | null }> => {
    const formData = new FormData()
    formData.append('file', blob)

    try {
      const response = await axios.post<{ data: FileUploadResponse }>(
        '/blog/fileUpload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )

      const fileUrl =
        process.env.REACT_APP_BASE_URL + '/upload/' + response.data.data.fileNm
      const fileSeq = response.data.data.fileSeq
      const fileName = response.data.data.fileNm

      setImgUrl(fileUrl)
      setFileSeqList((prevList) => [...prevList, fileSeq])
      setFileNameList((prevList) => [...prevList, fileName])

      return { fileUrl, fileSeq }
    } catch (error) {
      console.error('이미지 업로드 실패:', error)
      return { fileUrl: '', fileSeq: null }
    }
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleEditorChange = () => {
    const editorNttCn = editorRef.current?.getInstance()
    if (editorNttCn) {
      const nttCn = editorNttCn.getHTML() // 에디터에서 입력한 HTML 내용 가져오기
      setFormData((prev) => ({ ...prev, nttCn }))
    }
  }

  const handleModalAction = (type: 'insert' | 'cancel') => {
    if (type === 'insert') {
      const requiredFields: { field: keyof BlogListItem; message: string }[] = [
        { field: 'bbsSj', message: fieldMessages.bbsSj },
        { field: 'nttSj', message: fieldMessages.nttSj },
        { field: 'nttCn', message: fieldMessages.nttCn },
      ]

      // 필수 입력값 확인
      for (const { field, message } of requiredFields) {
        if (!formData[field]) {
          showAlert({
            title: message,
            message: message,
            btnName: '확인',
          })
          return
        }
      }

      showConfirm({
        title: confirmMessages.insert,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          insertBlog()
        },
      })
    }

    if (type === 'cancel') {
      showConfirm({
        title: confirmMessages.cancel,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          setTimeout(() => {
            navigate(`/blog`, { state: location.state }) // 500ms 후에 페이지 이동
          }, 100)
        },
      })
    }
  }

  const insertBlog = async () => {
    try {
      await axios.post(`/blog/insert`, formData) // POST 요청

      setTimeout(() => {
        navigate(`/blog`, { state: location.state }) // 성공 후 페이지 이동
      }, 300)
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: errorMessages.insert,
        message: err.response?.data?.message,
        btnName: buttonName.confirm,
      })
    }
  }

  return (
    <>
      <div
        style={{
          marginBottom: '10px',
          display: 'flex',
          gap: '5px',
        }}
      >
        <Select
          id="bbsSj"
          name="bbsSj"
          width="200px"
          value={formData.bbsSj}
          onChange={handleChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <Input
          id="nttSj"
          name="nttSj"
          placeholder="제목을 입력하세요."
          value={formData.nttSj}
          onChange={handleChange}
        />
      </div>
      <>
        <Editor
          ref={editorRef}
          useCommandShortcut={true}
          placeholder="내용을 입력해 주세요!"
          previewStyle="vertical" // 미리보기 스타일 지정
          height="500px" // 에디터 창 높이
          initialEditType="wysiwyg" // 초기 입력모드 설정 (기본 markdown)
          onChange={handleEditorChange}
          language="ko-KR"
          hideModeSwitch={true} // 하단의 타입 선택 탭 숨기기
          plugins={[colorSyntax, codeSyntaxHighlight]}
          customHTMLSanitizer={mySanitizer}
        />
        <div
          style={{
            display: 'flex',
            gap: '5px',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
        >
          <Button color="neverbe" onClick={() => handleModalAction('insert')}>
            저장
          </Button>
          <Button color="neverbe" onClick={() => handleModalAction('cancel')}>
            취소
          </Button>
        </div>
      </>
    </>
  )
}
