type ModalAction = 'insert' | 'delete' | 'update'

interface InsertCommentProps {
  formData: {
    commentCn: string
  }
  nickName?: string
  userNickName?: string
  handleCommentAction: (action: ModalAction, item?: any) => void
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void
}

export const InsertComment: React.FC<InsertCommentProps> = ({
  formData,
  nickName,
  handleCommentAction,
  handleChange,
}) => {
  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <div
          style={{
            padding: '10px',
            border: '2px solid #f1f1f1',
            height: 'auto',
            borderRadius: '6px',
            marginBottom: '10px',
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{nickName}</div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }} />
          <textarea
            id="commentCn"
            name="commentCn"
            value={formData.commentCn}
            onChange={handleChange}
            onInput={(e) => {
              const target = e.target as HTMLTextAreaElement
              target.style.height = 'auto' // 높이를 초기화하여 스크롤 높이를 정확히 계산
              target.style.height = `${target.scrollHeight}px` // 내용에 맞게 높이 설정
            }}
            style={{
              width: '100%',
              padding: '10px',
              border: 'none', // 테두리 제거
              outline: 'none', // 포커스 시 테두리 제거
              borderRadius: '4px', // 모서리를 둥글게
              resize: 'none', // 사용자가 크기 조정 불가
              overflow: 'hidden', // 스크롤 숨기기
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              color: '#979797',
              cursor: 'pointer',
            }}
          >
            <div onClick={() => handleCommentAction('insert')}>댓글쓰기</div>
          </div>
        </div>
      </div>
    </>
  )
}
