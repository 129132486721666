import React from 'react'
import styled from 'styled-components'
import Logo from '@/assets/images/neverbe.jpg'
import GNB from '@/components/common/gnb'
import HeaderAuth from '@/components/main/signin/header/headerAuth'
import { useNavigate } from 'react-router-dom'

const HeaderContainer = styled.header`
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  height: 76px;
  position: sticky;
  top: 0;
  z-index: 100;
`

const HeaderContent = styled.div`
  max-width: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  `
  // @media (max-width: 768px) {
  //   max-width: 90%;
  // }

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 40px;
    width: auto;
    cursor: pointer;
  }
`

const GNBContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
`

const HeaderAuthContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Header: React.FC = () => {
  const navigate = useNavigate()
  return (
    <HeaderContainer>
      <HeaderContent>
        <LogoContainer>
          <img src={Logo} alt="꿀프" onClick={() => navigate('/')} />
        </LogoContainer>
        <GNBContainer>
          <GNB />
        </GNBContainer>
        <HeaderAuthContainer>
          <HeaderAuth />
        </HeaderAuthContainer>
      </HeaderContent>
    </HeaderContainer>
  )
}

export default Header
