import { useNavigate } from "react-router-dom"

export const NoticeHeader = ({ title }: { title: string }) => {
    const navigate = useNavigate()
    
    return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '10px',
        alignItems: 'center',
        marginTop: '50px',
      }}
    >
        <div
            style={{ textAlign: 'center', fontSize: '20px', cursor: 'pointer' }}
            onClick={() => navigate(0)}
        >
            {title}
        </div>
      </div>
    )
}