import Content from '@/components/layout/content';
import { SEO } from '@/components/seo/seo';
import React from 'react';
import { StyledMedia } from '../mypage/_components/style';
import ManagementComponent from './_components/management';

const Management: React.FC = () => {

    return (
        <>
          <SEO
            title="계정관리"
            description="꿀프에서 골프 관련 서비스를 확인하세요. 다양한 키워드로 쉽게 검색 가능합니다."
            noIndex={true}
          />
          <Content fullHeight>
            <StyledMedia>
              <ManagementComponent />
            </StyledMedia>
          </Content>
        </>)
}

export default Management;