import React, { useEffect, useState } from "react";
import { useAxios } from '@/context/axiosContext'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FindMemberId from "./findMemberId";
import ResetMemberPwd from "./resetMemberPwd";
import { useAppSelector } from "@/redux/hooks";
import { useDialog } from "@/context/dialogContext";
import KmcAuth from "./kmcAuth";


const Management: React.FC = () => {

    const { type } = useParams();
    const navigate = useNavigate();

    const { showAlert, } = useDialog();

    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const [kmcResult, setKmcResult] = useState<any>(null);
    
    const kmcCallback = (data:any) => {
        console.log('kmc call..', data);
        setKmcResult(data);
    };

    useEffect(() => {
        // id 찾기 > 비밀번호 재설정으로 진입할경우 화면 리프레시용
        setKmcResult(null);
    }, [type]);

    useEffect(() => {
        if(isAuthenticated){
            showAlert({
                type : 'warning',
                message : '잘못된 접근입니다.',
                onClose : () => {
                    navigate(-1);
                }
            });
        }
    },[]);

    return (
        kmcResult == null ?
        <KmcAuth 
            title={type == 'id' ? '아이디 찾기' : type == 'pwd' ? '비밀번호 재설정' : ''} 
            desc={type == 'id' ? '아이디를 찾기 위해 본인인증이 필요합니다.' : type == 'pwd' ? '비밀번호 재설정을 위해 본인인증이 필요합니다.' : ''}
            callBack={kmcCallback} 
        />:
        type == 'id' ?
        <FindMemberId kmcResult={kmcResult}/>:
        type == 'pwd' ? 
        <ResetMemberPwd  kmcResult={kmcResult}/>:<></>
    );
}

export default Management;