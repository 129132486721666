import { useAppSelector } from '@/redux/hooks'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { InsertForm } from './insertForm'
import { useAxios } from '@/context/axiosContext'
import { useDialog } from '@/context/dialogContext'
import { PrivacyContents } from './privacyPolicy'
import { buttonName, confirmMessages, errorMessages } from '../boardItem'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
type FormData = {
  nttSj: string
  nttCn: string
  bbsSj: string
  guestName?: string
  guestEml?: string
  guestInsertCheck?: boolean
}

export const InsertGuest = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const { showAlert, showConfirm } = useDialog()
  const [showPolicyPopup, setShowPolicyPopup] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [showSaveButton, setShowSaveButton] = useState(true) // true: 저장/취소 || false : 닫기
  const [moveList, setMoveList] = useState(false) // true: 저장/취소 || false : 닫기
  const { pathname } = useLocation()
  const [error, setError] = useState<string | null>(null) // 에러 상태
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    bbsTy: '01',
    nttSj: '',
    nttCn: '',
    bbsSj: '',
    cstId: isAuthenticated ? user?.cstId : '',
    guestName: '',
    guestEml: '',
    guestInsertCheck: false,
  })

  const [loading, setLoading] = useState(false) // 로딩 상태
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault() // 기본 폼 제출 동작 방지
    // 이메일 유효성 검사 추가
    const value = formData.guestEml // 이메일 필드 값 가져오기

    // 필수 입력 필드와 메시지 매핑
    const requiredFields: { field: keyof FormData; message: string }[] = [
      { field: 'bbsSj', message: '말머리를 선택하세요.' },
      { field: 'nttSj', message: '제목을 입력하세요.' },
      { field: 'nttCn', message: '내용을 입력하세요.' },
      { field: 'guestName', message: '작성자를 입력하세요.' },
      { field: 'guestEml', message: '이메일을 입력하세요.' },
      {
        field: 'guestInsertCheck',
        message: '[필수] 개인정보 수집 및 이용 동의가 필요합니다.',
      },
    ]

    // 필수 입력값 확인
    for (const { field, message } of requiredFields) {
      if (!formData[field]) {
        showAlert({
          type: 'warning',
          title: message,
          btnName: '확인',
        })
        return
      }
    }
    // 이메일 유효성 검사
    // if (!value.includes('@')) {
    // 한글 미포함, 두글자이상 영숫자_-
    const emailRegex = /^[a-zA-Z0-9._-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(value)){
      showAlert({
        type: 'warning',
        title: '유효하지 않은 이메일 입니다. 올바른 이메일을 입력해 주세요.',
        btnName: '확인',
      })
      return // 유효하지 않은 경우, 이후 로직 실행 중단
    }

    showConfirm({
      title: confirmMessages.insert,
      confirmBtnName: '확인',
      cancelBtnName: '취소',
      onConfirm() {
        insertBoard()
      },
    })
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  const handleList = () => {
    showConfirm({
      title: confirmMessages.cancel,
      confirmBtnName: buttonName.confirm,
      cancelBtnName: buttonName.cancel,
      onConfirm() {
        insertBoard()
      },
    })
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // 숨겨진 파일 입력창 열기
    }
  }

  const openPrivacyPolicy = () => {
    showAlert({
      type: 'warning',
      title: '[필수] 개인정보 수집 및 이용 동의',
      message: <PrivacyContents />,
      btnName: buttonName.confirm,
    })
  }

  const insertBoard = async () => {
    try {
      await axios.post(`/${pathName}/insert`, formData) // POST 요청
      setTimeout(() => {
        navigate(`/${pathName}`)
      }, 300)
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: errorMessages.insert,
        message: err.response?.data?.message,
        btnName: buttonName.confirm,
      })
    }
  }

  return (
    <>
      <InsertForm
        formData={formData}
        modalMessage={modalMessage}
        showSaveButton={showSaveButton}
        moveList={moveList}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleCheckboxChange={handleCheckboxChange}
        handleButtonClick={handleButtonClick}
        handleList={handleList}
        openPrivacyPolicy={openPrivacyPolicy}
        showPolicyPopup={showPolicyPopup}
        setShowPolicyPopup={setShowPolicyPopup}
        navigate={navigate}
        insertBoard={insertBoard}
        isAuthenticated={isAuthenticated}
      />
    </>
  )
}
