import { Button, Input, Select } from '@/components'
import { SearchData } from './boardList'
type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

interface BoardSearchProps {
  handleSearch: (e: React.ChangeEvent<TInput>) => void
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
  handleSearchSubmit: () => void
  srchData: SearchData
}

export const BoardSearch: React.FC<BoardSearchProps> = ({
  handleSearch,
  handleKeyDown,
  handleSearchSubmit,
  srchData,
}) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginLeft: 'auto' }}>
        <div style={{ display: 'flex', gap: '5px' }}>
          <Select
            id="srchOpt"
            name="srchOpt"
            width="100px"
            value={srchData.srchOpt}
            onChange={handleSearch}
          >
            <option value="01">제목</option>
            <option value="02">내용</option>
            <option value="03">작성자</option>
            <option value="04">제목+내용</option>
          </Select>
          <Input
            id="srchStr"
            name="srchStr"
            placeholder="검색어를 입력하세요."
            width="300px"
            value={srchData.srchStr}
            onChange={handleSearch}
            onKeyDown={handleKeyDown}
          />
          <Button color="neverbe" onClick={handleSearchSubmit}>
            검색
          </Button>
        </div>
      </div>
    </div>
  )
}
