import { combineReducers, configureStore } from '@reduxjs/toolkit'

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from './slices/auth'
import golfSearchReducer from './slices/golfSearchSlice'
import themeReducer from './slices/theme'
import redisReducer from './slices/redisSlice'

const persistConfig = {
  key: 'doar',
  version: 1.1,
  blacklist: ['ui', 'golfSearch'],
  storage,
}

export const rootReducer = combineReducers({
  theme: themeReducer,
  auth: authReducer,
  golfSearch: golfSearchReducer,
  redis: redisReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
