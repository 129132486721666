import { useLocation, useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'
import { BlogListItem, removeHtmlTags } from '../blogItem'
import {
  GetBoardData,
  maskIpSuffix,
  searchMessages,
  withDrawnCumtomer,
} from '../boardItem'
import commonUtils from '@/utils/commonUtils'
import Pagination from '../_components/pagination'
import { BoardSearch } from '../_components/boardSearch'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
interface BlogBoardListProps {
  imgPosition?: 'left' | 'right'
}
export const BlogBoardList: React.FC<BlogBoardListProps> = ({
  imgPosition,
}) => {
  const axios = useAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const [page, setPage] = useState(location.state?.page ?? 1) //null 또는 undefined일 때만 1이 기본값으로 설정
  const [boardList, setBoardList] = useState<BlogListItem[]>([])
  const [thumbnailList, setThumbnail] = useState<string[]>([])
  const [totalCount, setTotalCount] = useState(0) //페이지 count
  const [fileSeqList, setFileSeqList] = useState<string[]>([])

  const filePath = process.env.REACT_APP_BASE_URL + '/upload'
  console.log('filePath', filePath)
  const [srchData, setSrchData] = useState({
    srchOpt: location.state?.srchOpt || '01',
    srchStr: location.state?.srchStr || '',
  })

  useEffect(() => {
    getBlogBoardList()
  }, [page])

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  // 검색 버튼 클릭 시 또는 Enter 키 입력 시 getBoardList 실행
  const handleSearchSubmit = () => {
    getBlogBoardList()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getBlogBoardList()
    }
  }

  const handleSearch = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setSrchData((prev) => ({ ...prev, [name]: value }))
  }

  // 글상세보기
  const handleDetailView = (nttSn: number) => {
    navigate(`/blog/detail?nttSn=${nttSn}`, { state: fileSeqList })
  }

  const getBlogBoardList = async () => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: page,
      }

      const response = await axios.get(`/blog/list`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { blogList, pageCnt, thumbnailList, fileSeqList } =
        response.data.data
      console.log('getBlogBoardList', response.data.data)
      if (Array.isArray(blogList)) {
        setBoardList(blogList)
        setFileSeqList(fileSeqList)
      }
      setTotalCount(pageCnt)
      setThumbnail(thumbnailList)
    } catch (err: any) {
      console.error('Error fetching List:', err)
    }
  }

  return (
    <>
      {boardList.length > 0 ? (
        boardList.map((item) => (
          <div
            key={item.nttSn}
            style={{
              display: 'flex',
              flexDirection: imgPosition === 'left' ? 'row' : 'row-reverse',
              alignItems: 'center',
              padding: '5px',
              borderBottom: '1px solid #ddd',
            }}
          >
            {item.thumbnailUrl && (
              <img
                src={filePath + item.thumbnailUrl}
                alt="thumbnail"
                style={{
                  padding: '10px',
                  width: '300px',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                  marginRight: imgPosition === 'left' ? '16px' : '0',
                  marginLeft: imgPosition === 'right' ? '16px' : '0',
                  cursor: 'pointer',
                }}
                onClick={() => handleDetailView(item.nttSn)}
              />
            )}

            <div style={{ flex: 1, padding: '10px' }}>
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => handleDetailView(item.nttSn)}
              >
                {removeHtmlTags(item.nttSj)}
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: '#666',
                  marginBottom: '8px',
                  height: '100px',
                  overflow: 'hidden', // 넘치는 텍스트 숨기기
                  display: '-webkit-box', // 플렉스 박스처럼 동작
                  WebkitBoxOrient: 'vertical', // 수직 방향으로 텍스트 정렬
                  WebkitLineClamp: 3, // 최대 3줄로 표시 (필요에 맞게 조정)
                  textOverflow: 'ellipsis', // 말줄임표
                }}
              >
                {removeHtmlTags(item.nttCn)}
              </div>
              <div style={{ color: '#979797' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        작성자 :&nbsp;
                        {item.withDrawYn
                          ? withDrawnCumtomer.name
                          : item?.nickName}
                      </div>
                      <div
                        style={{
                          margin: '0 8px',
                          borderLeft: '1px solid #ddd',
                          height: '16px',
                        }}
                      />
                      <div>
                        작성일 :&nbsp;
                        {item?.regDate
                          ? commonUtils.formatDate(item.regDate, false)
                          : ''}
                      </div>
                      <div
                        style={{
                          margin: '0 8px',
                          borderLeft: '1px solid #ddd',
                          height: '16px',
                        }}
                      />
                      <div>조회 : {item?.readngCnt}</div>
                      {/* 
                      <div
                        style={{
                          margin: '0 8px',
                          borderLeft: '1px solid #ddd',
                          height: '16px',
                        }}
                      />
                      <div style={{ marginLeft: '8px', cursor: 'pointer' }}>
                        댓글 {item?.answerCnt}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: '5px',
            height: '150px',
            borderBottom: '1px solid #ddd',
            borderTop: '1px solid #ddd',
          }}
        >
          {searchMessages.noItem}
        </div>
      )}

      <div style={{ marginTop: '10px' }}>
        <BoardSearch
          handleSearch={handleSearch}
          handleSearchSubmit={handleSearchSubmit}
          handleKeyDown={handleKeyDown}
          srchData={srchData}
        />
      </div>
      {totalCount > 0 && (
        <Pagination
          totalCount={totalCount}
          onPageChange={handlePageChange}
          paramPage={page || 1}
        />
      )}
    </>
  )
}
