export interface YoutubeRankData {
  cmpnyCd: string
  cstId: string
  regId: string
  regDate: string
  updId: string
  updDate: string
  chnlId: string
  hnlNm: string
  useYn: string
  chnlDesc: string
  title: string
  customUrl: string
  publishedAt: string
  description: string
  thumbnailsDefault: string
  thumbnailsHigh: string
  thumbnailsMedium: string
  subscriberCount: number
  videoCount: number
  viewCount: number
  iddenSubscriberCountYn: string
  likesYn: string
  page: number
  chnlRank: number
  likeCount: number
  userLikeYn: string
}

export const RankList = {
  title: '구독자별 국내 유투브 순위',
  noti: '매일 오전 8시에 갱신 됩니다.',
}

export const LikdeList = {
  title: '내가 좋아요 한 유튜브 목록',
}
