import { MonthGraph } from '@/pages/golf/detailInfo/_components/monthGraph'
import { RegionGraph } from '@/pages/golf/detailSearch/_components/graphs/regionGraph'
import { GraphDataState } from '@/redux/slices/golfSearchSlice'
import styled from 'styled-components'

interface DetailInfoGraphProps {
  monthAvgPriceByRegionData: GraphDataState['monthAvgPriceByRegion']
  monthAvgPriceData: GraphDataState['monthData']
  regionAvgPriceData: GraphDataState['regionAvgPrice']
  areaNm?: string
  areaCd?: string
}
export const DetailInfoGraph = ({
  monthAvgPriceByRegionData,
  monthAvgPriceData,
  regionAvgPriceData,
  areaNm,
  areaCd,
}: DetailInfoGraphProps) => {
  const safeGraphData =
    monthAvgPriceData && monthAvgPriceData.length ? monthAvgPriceData : []
  const isMonthDataEmpty = safeGraphData.every((arr) =>
    arr.every((item) => item === 0),
  )

  return (
    <GraphWrapper>
      {!isMonthDataEmpty && (
        <MonthGraph
          monthData={monthAvgPriceData}
          monthAvgPriceByRegion={monthAvgPriceByRegionData}
          areaCd={areaCd}
          isDualGraph={true}
          areaNm={areaNm}
        />
      )}
      <RegionGraph regionAvgPrice={regionAvgPriceData} />
    </GraphWrapper>
  )
}

const GraphWrapper = styled.div`
  width: 100%;
`
