import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Radio, Select } from '@/components'
import { StyledBottomText, StyledDesc, StyledTitle, StyledWrap } from "./style";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAxios } from '@/context/axiosContext'
import { FlexWrapper } from "@/components/layout/flex/flexWrapper";
import { useNavigate } from "react-router-dom";
import { usePostcodeSearch } from "@/hooks/usePostcodeSearch";
import { useNiceAuth } from "@/hooks/useNiceAuth";
import { useDialog } from "@/context/dialogContext";
import { StyledRequired } from "../../newMember/_components/style";

interface Props {
  data: PostData
}

interface PostData {
  cstId: string
  cmpnyCd: string
  joinDivCd: string
  snsId: string
  userId: string
  password: string
  password_confm?: string
  ci: string
  name: string
  nickName: string
  gender: string
  birth: string
  birthYy?: string
  birthMm?: string
  birthDd?: string
  email: string
  email1?: string
  email2?: string
  smsOptIn: string
  jobCd: string
  homePostCd: string
  homeAddrNum?: string
  homeAddr: string
  homeDaddr: string
  homeArCd: string
  homeExcNo: string
  homeSubNo: string
  mnc: string
  mblNo: string
  mblNetCd: string
  mblExcNo: string
  mblSubNo: string
  department: string
  jbps: string
  lastLogin: string
  accessIp: string
  pwErrCount: string
  state: string
  withDrawYn: string
  refreshToken: string
  regId: string
  updId: string
}

const MypageForm: React.FC<Props> = (
  { data }
) => {

  const axios = useAxios();
  const navigate = useNavigate();
  const { showAlert, showConfirm } = useDialog();
  const [userInfo, setUserInfo] = useState<PostData>(data);
  const [originNickName, setOriginNickName] = useState<string>(userInfo.nickName);
  const [nickNameChk, setNickNameChk] = useState(true);

  const { data: PostHomeData, handleOpenPopup: handleOpenPopup1 } = usePostcodeSearch();

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm<PostData>({
  });

  const { authData, requestNiceAuth, setAuthData } = useNiceAuth();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    if(name == 'nickName'){
      setNickNameChk(false);
    }

    setUserInfo({
      ...userInfo,
      [name]: value, // 변경된 input 값만 업데이트
    });

  };

  const handleChangeNumChk = (e: any) => {
    const { name, value } = e.target

    // 숫자만 허용하는 정규식
    if (/^\d*$/.test(value)) {
      setUserInfo({
        ...userInfo,
        [name]: value, // 변경된 input 값만 업데이트
      });
    }
  };

  const selectHandler = (data: any) => {
    let selectedValue = data.target.value

    if (selectedValue === '') {
      selectedValue = ''
    }
    setValue('email2', selectedValue)
  };

  const fnChkDupNickName = async () => {
    try {
      const tmp_data = {
        nickName: getValues('nickName'),
      }
      const response = await axios.get('/member/checkNickName', {
        params: { ...tmp_data },
      }); // GET 요청
      if ('N' == response.data) {
        showAlert({
          type : 'warning',
          message : '이미 사용중인 닉네임 입니다.',
        });
      } else {
        showConfirm({
          message : '사용가능한 닉네임 입니다. \n사용하시겠습니까?',
          onConfirm : () => {
            setNickNameChk(true);
          },
        });
      }
    } catch (error) {
      console.error('Error fnChkDupNickName request:', error);
    }
  };

  const validateForm = (data:PostData) => {
    if(!data.name || data.name == ''){
      showAlert({
        type : 'warning',
        message : '이름을 확인해주세요',
        onClose : () =>  {
          setFocus('name');
        }
      });
      return false;
    }
    if(!data.mblNo || data.mblNo == ''){
      showAlert({
        type : 'warning',
        message : '휴대폰을 확인해주세요',
        onClose : () =>  {
          setFocus('mblNo');
        }
      });
      return false;
    }
    if(!data.gender || data.gender == ''){
      showAlert({
        type : 'warning',
        message : '성별을 확인해주세요',
        onClose : () =>  {
          setFocus('gender');
        }
      });
      return false;
    }
    if(!data.birth || data.birth == '' || data.birth.length < 4){
      showAlert({
        type : 'warning',
        message : '생년월일을 확인해주세요',
        onClose : () =>  {
          setFocus('birthYy');
        }
      });
      return false;
    }
    if(!data.birth || data.birth == '' || data.birth.length < 6){
      showAlert({
        type : 'warning',
        message : '생년월일을 확인해주세요',
        onClose : () =>  {
          setFocus('birthMm');
        }
      });
      return false;
    }
    if(!data.birth || data.birth == '' || data.birth.length < 8){
      showAlert({
        type : 'warning',
        message : '생년월일을 확인해주세요',
        onClose : () =>  {
          setFocus('birthDd');
        }
      });
      return false;
    }
    if(!data.nickName || data.nickName == ''){
      showAlert({
        type : 'warning',
        message : '닉네임을 확인해주세요',
        onClose : () =>  {
          setFocus('nickName');
        }
      });
      return false;
    }
    if(data.nickName != originNickName && !nickNameChk){
      showAlert({
        type : 'warning',
        message : '닉네임 중복 확인을해주세요',
        onClose : () =>  {
          setFocus('nickName');
        }
      });
      return false;
    }
    if(!data.smsOptIn || data.smsOptIn == ''){
      showAlert({
        type : 'warning',
        message : 'SNS서비스를 확인해주세요',
        onClose : () =>  {
          setFocus('smsOptIn');
        }
      });
      return false;
    }
    if(!data.homePostCd || data.homePostCd == ''){
      showAlert({
        type : 'warning',
        message : '자택주소를 확인해주세요',
        onClose : () =>  {
          setFocus('homePostCd');
        }
      });
      return false;
    }
    if(!data.homeAddr || data.homeAddr == ''){
      showAlert({
        type : 'warning',
        message : '자택주소를 확인해주세요',
        onClose : () =>  {
          setFocus('homeAddr');
        }
      });
      return false;
    }
    if(!data.homeDaddr || data.homeDaddr == ''){
      showAlert({
        type : 'warning',
        message : '자택주소를 확인해주세요',
        onClose : () =>  {
          setFocus('homeDaddr');
        }
      });
      return false;
    }

    return true;
  }

  const updateMemberInfo: SubmitHandler<PostData> = (data) => {
    if(!validateForm(data)){
      return;
    }

    showConfirm({
      message : '회원정보를 변경하시겠습니까?',
      onConfirm : async () => {
        if(data.email1 && data.email2){
          data.email = data.email1 + '@' + data.email2;
        }
        const response = await axios.post('/member/updateMemberInfo', data);
        if (response.data.common.resultCode === 'MB200') {
          if(response.data.data.row > 0){
            showAlert({
                type : 'success',
                message : '변경이 완료되었습니다.',
                onClose : () =>  {
                  navigate('/main');
                }
            });
          }else{
              showAlert({
                type : 'error',
                message : '변경 실패하였습니다.',
                onClose : () =>  {
                  
                }
            });
          }
        }else{
          showAlert({
            type : 'error',
            message : response.data.common.resultMsg,
            title :response.data.common.resultCode,
        });
        }
      },
    });
  };

  const responseNiceAuth = () => {
    if(authData?.resultcode == '0000' && authData?.ci == userInfo.ci){
      // 동일인일때
      const name = authData?.name || '';
      const birth = authData?.birthdate || '99999999';
      const birthYy = birth.substring(0,4);
      const birthMm = birth.substring(4,6);
      const birthDd = birth.substring(6,8);
      const mobileNo = authData?.mobileno || '00000000000';
      const mblNetCd = mobileNo.substring(0,3);
      const mblExcNo = mobileNo.substring(3,mobileNo.length-4);
      const mblSubNo = mobileNo.substring(mobileNo.length-4, mobileNo.length);
      const gender = authData?.gender == '1' ? 'M' : authData?.gender == '2' ? 'F' : '';

      // 화면에 보여지는 값 변경
      setUserInfo({
        ...userInfo,
        name,birth,birthYy,birthMm,birthDd,mblNetCd,mblExcNo,mblSubNo,gender
      });

      // 실제 값 변경
      setValue('name',name);
      setValue('birthYy',birthYy);
      setValue('birthMm',birthMm);
      setValue('birthDd',birthDd);
      setValue('mblNetCd',mblNetCd);
      setValue('mblExcNo',mblExcNo);
      setValue('mblSubNo',mblSubNo);
      setValue('gender',gender);
      // 중복호출 방지
      setAuthData(null);

    }else{
      showAlert({
        type : 'error',
        message : '본인인증에 실패하였습니다.',
      });
    }
  }

  const init = () => {
    // 화면 첫 진입시 보여지는것과 데이터 세팅의 간극을 없애기 위함  
    reset(data);
  }

  useEffect(()=>{
    if(authData != null){
      responseNiceAuth();
    }
  },[authData]);

  useEffect(() => {
    init();
  }, []);

  return (
    <StyledWrap>
      <StyledTitle>회원정보수정</StyledTitle>
      <StyledDesc></StyledDesc>
      <form action="#" onSubmit={handleSubmit(updateMemberInfo)} noValidate>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="userId">
                아이디
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                id="userId"
                name="userId"
                type="text"
                maxLength={16}
                minLength={6}
                value={userInfo.userId}
                disabled
              />
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="name">
                이름
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('name', { required: true })}
                id="name"
                name="name"
                type="text"
                maxLength={20}
                value={userInfo.name}
                disabled
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="secondary"
                variant="outlined"
                width="100px"
                onClick={requestNiceAuth}
              >
                인증하기
              </Button>
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="mblNetCd">
                휴대폰
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('mblNo')}
                readOnly
                id="mblNo"
                name="mblNo"
                type="text"
                value={userInfo.mblNetCd+userInfo.mblExcNo+userInfo.mblSubNo}
              />
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="gender">
                성별
              </Label>
            </div>
            <div
              style={{
                width: '20%',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Radio
                id="radio1"
                value={'M'}
                label="남성"
                name="gender2"
                checked={userInfo.gender == 'M'}
                disabled
              ></Radio>
            </div>
            <div
              style={{
                width: '20%',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <Radio
                id="radio2"
                value={'F'}
                label="여성"
                name="gender2"
                checked={userInfo.gender == 'F'}
                disabled
              ></Radio>
              <Input
                {...register('gender', { required: true })}
                id="gender"
                type="hidden"
                name="gender"
                value={userInfo.gender}
              />
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="birth">
                생년월일
              </Label>
            </div>
            <div style={{ width: '18%' }}>
              <Input
                placeholder="YYYY"
                mb="10px"
                id="birthYy"
                name="birthYy"
                maxLength={4}
                value={userInfo.birth?.substring(0, 4)}
                disabled
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;년&nbsp;&nbsp;</p>

            <div style={{ width: '18%' }}>
              <Input
                placeholder="MM"
                mb="10px"
                id="birthMm"
                name="birthMm"
                maxLength={2}
                value={userInfo.birth?.substring(4, 6)}
                disabled
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;월&nbsp;&nbsp;</p>

            <div style={{ width: '18%' }}>
              <Input
                placeholder="DD"
                mb="10px"
                id="birthDd"
                name="birthDd"
                maxLength={2}
                value={userInfo.birth?.substring(6, 8)}
                disabled
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;&nbsp;일</p>
            <div style={{ padding: '10px'}}></div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="nickName">
                <StyledRequired>*</StyledRequired> 닉네임
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('nickName')}
                id="nickName"
                name="nickName"
                type="text"
                placeholder="닉네임을 입력해주세요."
                maxLength={20}
                value={userInfo.nickName}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="dark"
                active
                width="100px"
                onClick={fnChkDupNickName}
              >
                중복확인
              </Button>
            </div>
          </FlexWrapper>
        </FormGroup>
        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="radio3">
                <StyledRequired>*</StyledRequired> SMS서비스
              </Label>
            </div>
            <div style={{ width: '15%' }}>
              <Radio
                {...register('smsOptIn')}
                id="radio4"
                name="smsOptIn"
                label="수신합니다."
                value={'Y'}
                checked={userInfo.smsOptIn == 'Y'}
                onChange={handleInputChange}
              ></Radio>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div style={{ width: '15%' }}>
              <Radio
                {...register('smsOptIn')}
                id="radio5"
                name="smsOptIn"
                label="거부합니다."
                value={'N'}
                checked={userInfo.smsOptIn != 'Y'}
                onChange={handleInputChange}
              ></Radio>
            </div>
          </FlexWrapper>
        </FormGroup>


        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="homePostCd">
                <StyledRequired>*</StyledRequired> 자택 주소
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homePostCd')}
                id="homePostCd"
                name="homePostCd"
                type="text"
                placeholder="우편번호"
                maxLength={8}
                value={PostHomeData?.zonecode || userInfo.homePostCd}
              />
            </div>
            <div style={{ padding: '10px', width: '20%' }}>
              <Button
                color="secondary"
                variant="outlined"
                width="100px"
                onClick={handleOpenPopup1}
              >
                주소검색
              </Button>
            </div>
          </FlexWrapper>
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}></div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homeAddr')}
                mb="10px"
                placeholder="기본주소"
                id="homeAddr"
                name="homeAddr"
                value={PostHomeData?.address || userInfo.homeAddr}
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}></div>
            <div style={{ width: '60%' }}>
              <Input
                {...register('homeDaddr')}
                mb="10px"
                placeholder="상세주소"
                id="homeDaddr"
                name="homeDaddr"
                value={userInfo.homeDaddr}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="homeArCd">
                자택 전화 번호
              </Label>
            </div>
            <div style={{ width: '14%' }}>
              <Select
                mb={'10px'}
                {...register('homeArCd')}
                id="homeArCd"
                name="homeArCd"
                width="150px"
                value={userInfo.homeArCd}
                onChange={handleInputChange}
              >
                <option value="02">02</option>
                <option value="031">031</option>
                <option value="032">032</option>
                <option value="033">033</option>
                <option value="041">041</option>
                <option value="042">042</option>
                <option value="043">043</option>
                <option value="051">051</option>
                <option value="052">052</option>
                <option value="053">053</option>
                <option value="054">054</option>
                <option value="055">055</option>
                <option value="061">061</option>
                <option value="062">062</option>
                <option value="063">063</option>
                <option value="064">064</option>
                <option value="070">070</option>
              </Select>
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('homeExcNo')}
                mb="10px"
                id="homeExcNo"
                name="homeExcNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={userInfo.homeExcNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;-&nbsp;</p>
            <div style={{ width: '21%' }}>
              <Input
                {...register('homeSubNo')}
                mb="10px"
                id="homeSubNo"
                name="homeSubNo"
                maxLength={4}
                onChange={handleChangeNumChk}
                value={userInfo.homeSubNo}
                placeholder="숫자만 입력해주세요."
              />
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="email1">
                이메일
              </Label>
            </div>
            <div style={{ width: '24%' }}>
              <Input
                {...register('email1')}
                mb="10px"
                id="email1"
                name="email1"
                value={userInfo.email1 || (userInfo.email && userInfo.email.split('@', 2)[0])}
                onChange={handleInputChange}
              />
            </div>
            <p style={{ marginBottom: '10px' }}>&nbsp;@&nbsp;</p>
            <div style={{ width: '15%' }}>
              <Input
                {...register('email2')}
                mb="10px"
                id="email2"
                name="email2"
                value={userInfo.email2 || (userInfo.email && userInfo.email.split('@', 2)[1])}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ width: '20%' }}>
              <Col>
                <Select
                  mb={'10px'}
                  id="selEmail"
                  name="selEmail"
                  onChange={selectHandler}
                >
                  <option value="">직접입력</option>
                  <option value="naver.com">naver.com</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="hanmail.net">hanmail.net</option>
                  <option value="kakao.com">kakao.com</option>
                  <option value="daum.net">daum.net</option>
                  <option value="hotmail.com">hotmail.com</option>
                  <option value="yahoo.co.kr">yahoo.co.kr</option>
                </Select>
              </Col>
            </div>
          </FlexWrapper>
        </FormGroup>

        <FormGroup mb="20px">
          <FlexWrapper>
            <div style={{ padding: '5px', width: '20%' }}>
              <Label display="block" mb="5px" htmlFor="jobCd">
                직업
              </Label>
            </div>
            <div style={{ width: '60%' }}>
              <Select {...register('jobCd')} id="jobCd" name="jobCd" value={userInfo.jobCd} onChange={handleInputChange}>
                <option value="">직업을 선택해 주세요</option>
                <option value="01">교육</option>
                <option value="02">연구개발</option>
                <option value="03">법률</option>
                <option value="04">회계/세무</option>
                <option value="05">숙박/음식</option>
                <option value="06">오락/문화/스포츠</option>
                <option value="07">통신</option>
                <option value="08">운송/물류</option>
                <option value="09">건설</option>
                <option value="10">농업/임업/광업/수산업</option>
                <option value="11">방송/언론/광고</option>
                <option value="12">유통/무역</option>
                <option value="13">의약</option>
                <option value="14">의료기기</option>
                <option value="15">개인병원</option>
                <option value="16">종합병원</option>
                <option value="17">자영업</option>
                <option value="18">정부</option>
                <option value="19">비영리/사회복지/행정</option>
                <option value="20">제1금융</option>
                <option value="21">제2금융</option>
                <option value="22">제조업1(금속/제철)</option>
                <option value="23">제조업2(의복/섬유/목재)</option>
                <option value="24">제조업3(석유/화학/전기)</option>
                <option value="25">컴퓨터/인터넷</option>
                <option value="26">골프업계</option>
                <option value="27">무직</option>
                <option value="99">기타</option>
              </Select>
            </div>
            <div style={{ width: '20%' }}></div>
          </FlexWrapper>
        </FormGroup>
        <div style={{ padding: '30px 0 0 0', textAlign: 'center' }}>
          <FormGroup mb="20px" margin={'AlignCenter'}>
            <Button type="submit" color="neverbe" width="100px">
              확인
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              color="neverbe"
              variant="outlined"
              width="100px"
              onClick={() => { navigate('/main'); }}
            >
              취소
            </Button>
          </FormGroup>
        </div>
        <StyledBottomText></StyledBottomText>
      </form>
    </StyledWrap>
  );
}

export default MypageForm;