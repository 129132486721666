import ErrorContainer from '@/components/error/error-404'
import { SigninContainer } from '@/components/main/signin/signin'
import AboutUs from '@/pages/about/aboutUs'
import DetailBoard from '@/pages/board/_components/detailBoard'
import InsertBoard from '@/pages/board/_components/insertBoard'
import BlogBoard from '@/pages/board/blog/blogBoard'
import DetailBlog from '@/pages/board/blog/detailBlog'
import { InsertBlog } from '@/pages/board/blog/insertBlog'
import CommunityBoard from '@/pages/board/community/communityBoard'
import QnaBoard from '@/pages/board/qna/qnaBoard'
import { DetailInfo } from '@/pages/golf/detailInfo/detailInfo'
import { DetailSearch } from '@/pages/golf/detailSearch/detailSearch'
import Main from '@/pages/main/main'
import NewMember from '@/pages/member/agree/newMember'
import CompleteMember from '@/pages/member/completeMember/completeMember'
import Management from '@/pages/member/management/management'
import Member from '@/pages/member/member'
import Mypage from '@/pages/member/mypage/mypage'
import NewMember2 from '@/pages/member/newMember/newMember'
import NiceAuthResult from '@/pages/niceAuth/niceAuthResult'
import { NoticeDetail } from '@/pages/notice/_components/noticeDetail'
import Notice from '@/pages/notice/notice'
import ResultPage from '@/pages/sample/result/resultPage'
import Sample from '@/pages/sample/sample'
import Survey from '@/pages/survey/survey'
import YoutubeRank from '@/pages/youtube/youtubeRank'

export const routes = [
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/main',
    element: <Main />,
    label: '',
  },
  {
    path: '/about',
    element: <AboutUs />,
    label: '서비스소개',
  },
  {
    path: '/golf/detailSearch',
    element: <DetailSearch />,
    label: '골프장검색',
    // requireAuth: true,  //2025.03.19 ljh 정보제공 목적으로 로그인 후 이용 옵션 제거
  },
  {
    path: '/board',
    element: <QnaBoard />,
    label: '문의게시판',
  },
  {
    path: '/blog',
    element: <BlogBoard />,
    label: '소통방',
  },
  {
    path: '/youtube',
    element: <YoutubeRank />,
    label: '골플루언서',
  },
  {
    path: '/survey',
    element: <Survey />,
    label: '투표방',
    notReady: true,
  },
  {
    path: '/community',
    element: <CommunityBoard />,
    label: '커뮤니티',
    notReady: true,
  },
  {
    path: '/notice',
    element: <Notice />,
    label: '공지사항',
  },
  {
    path: '/sample',
    element: <Sample />,
    label: '',
  },
  {
    path: '/sample/result',
    element: <ResultPage />,
    label: '',
  },
  {
    path: '/member',
    element: <Member />,
    label: '',
  },
  {
    path: '/member/agree',
    element: <NewMember />,
  },
  {
    path: '/member/newMember',
    element: <NewMember2 />,
  },
  {
    path: '/member/completeMember',
    element: <CompleteMember />,
  },
  {
    path: '/board/write',
    element: <InsertBoard />,
  },
  {
    path: '/community/write',
    element: <InsertBoard />,
  },
  {
    path: '/blog/write',
    element: <InsertBlog />,
  },
  {
    path: '/blog/detail',
    element: <DetailBlog />,
  },
  {
    path: '/board/detail',
    element: <DetailBoard />,
  },
  {
    path: '/community/detail',
    element: <DetailBoard />,
  },

  {
    path: '/login',
    element: <SigninContainer />,
    hideHeader: false,
    hideFooter: false,
    isFullWidth: true,
  },
  {
    path: '/mypage',
    element: <Mypage />,
    hideHeader: false,
    hideFooter: false,
  },
  {
    path: '/mypage/:type',
    element: <Mypage />,
    hideHeader: false,
    hideFooter: false,
  },
  {
    path: '/member/management/:type',
    element: <Management />,
    hideHeader: false,
    hideFooter: false,
  },
  {
    path: '/notice/detail',
    element: <NoticeDetail />,
  },
  {
    path: '/niceAuth/niceAuthResult',
    element: <NiceAuthResult />,
  },
  {
    path: '/golf/detailInfo',
    element: <DetailInfo />,
  },
  {
    path: '*',
    element: <ErrorContainer />,
    label: '',
  },
]
