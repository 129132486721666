import React, { Component } from 'react';
import ErrorContainer from './error-500-rendering';


interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<{children: React.ReactNode}, State> {
  constructor(props: {children: React.ReactNode}) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * getDerivedStateFromError 호출 후 componentDidCatch 수행
   * 
   * getDerivedStateFromError 에서 hasError를 변경해서 대응하는 오류 화면을 보여줌 
   * 
   * 화면 렌더링시에 발생하는 오류에 대해서만 잡는다. 
   *    ex) <Input value={nullValue.substring(0,3)} <!-- null값을 참조할경우 -->
   */

  static getDerivedStateFromError(error: Error) {
    return { hasError: true , error};
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    /**
     * 오류 샘플 error
     * TypeError: Cannot read properties of null (reading 'substring')
        at MypageForm (mypageForm.tsx:581:1)
        at renderWithHooks (react-dom.development.js:15486:1)
        at mountIndeterminateComponent (react-dom.development.js:20103:1)
        at beginWork (react-dom.development.js:21626:1)
        at beginWork$1 (react-dom.development.js:27465:1)
        at performUnitOfWork (react-dom.development.js:26596:1)
        at workLoopSync (react-dom.development.js:26505:1)
        at renderRootSync (react-dom.development.js:26473:1)
        at recoverFromConcurrentError (react-dom.development.js:25889:1)
        at performConcurrentWorkOnRoot (react-dom.development.js:25789:1)
     * 오류샘플 name
        TypeError
     * 오류샘플 cause
        undefined..
     * 오류샘플 message
        Cannot read properties of null (reading 'substring')
     * 오류샘플 stack
        TypeError: Cannot read properties of null (reading 'substring')
        at MypageForm (http://localhost:3000/main.8bef7874c17d501c1b11.hot-update.js:667:40)
        at renderWithHooks (http://localhost:3000/static/js/bundle.js:99066:22)
        at mountIndeterminateComponent (http://localhost:3000/static/js/bundle.js:103037:17)
        at beginWork (http://localhost:3000/static/js/bundle.js:104340:20)
        at beginWork$1 (http://localhost:3000/static/js/bundle.js:109299:18)
        at performUnitOfWork (http://localhost:3000/static/js/bundle.js:108569:16)
        at workLoopSync (http://localhost:3000/static/js/bundle.js:108492:9)
        at renderRootSync (http://localhost:3000/static/js/bundle.js:108465:11)
        at recoverFromConcurrentError (http://localhost:3000/static/js/bundle.js:107957:24)
        at performConcurrentWorkOnRoot (http://localhost:3000/static/js/bundle.js:107870:26)
     *    
     */
    // console.log('Error caught in ErrorBoundary:', error);
    // console.log('name:', error.name);
    // console.log('cause:', error.cause);
    // console.log('message:', error.message);
    // console.log('stack:', error.stack);
    // console.log('Component Stack:', info.componentStack);
    // console.log('digest:', info.digest);
    // console.log(window.location.href);
    // console.log(navigator.userAgent);
  }

  resetError = () => {
    this.setState({ hasError : false });
  }

  render() {
    if (this.state.hasError) {
      return <>
      <ErrorContainer error={this.state.error} resetError={this.resetError}></ErrorContainer>
      </>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;