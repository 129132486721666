import { Button } from '@/components'
import { useAxios } from '@/context/axiosContext'
import { DetailMap } from '@/pages/golf/_components/detailMap'
import { DetailInfoGraph } from '@/pages/golf/detailInfo/_components/detailInfoGraph'
import {
  Container,
  PriceCellHeader,
  PriceRow,
  Table,
  TableCell,
  TableCellHeader,
  TableRow,
  Title,
} from '@/pages/golf/detailInfo/_components/style'
import {
  generatePeriod,
  mapMonthDataToGraph,
  PeriodAvgData,
  regionAvgPriceItem,
} from '@/pages/main/_components/golfSearch/useGolfSearchData'
import { GraphDataState } from '@/redux/slices/golfSearchSlice'
import numberUtils from '@/utils/numberUtils'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface CommonResponse {
  resultCode: string
  resultMsg: string
}

interface GolfDetailData {
  cmpnyCd: string
  bizCd: string //업체코드
  bizNm: string //업체명
  bizGubn: string //업체구분
  ntnCd: string
  areaCd: string //지역구분
  areaNm: string //지역명
  baseDomain: string //기본도메인
  chrgeDomain: string //요금도메인
  addr: string //주소
  phone: string | null //전화번호
  email: string | null //이메일
  useYn: string //사용여부
  mshipYn: string | null //회원제여부
  batchYn: string //배치여부
  uri: string
  rem: string
  cstRem: string
  schrTag: string | null
  etc1: string | null
  etc2: string | null
  etc3: string | null
  etc4: string | null
  etc5: string | null
  regId: string
  updId: string
  regDate: string
  updDate: string
  page: string | null
  pageStart: string | null
  pageEnd: string | null
  rnum: string | null
  srchStr: string | null
  seq: string | null
  chngDt: string | null
  manualYn: string | null //수기관리여부
  n01: number | null // 비회원(주중) 가격
  n02: number | null // 비회원(주말) 가격
  m01: number | null // 회원(주중) 가격
  m02: number | null // 회원(주말) 가격
}

interface ApiResponse {
  common: CommonResponse
  data: {
    golfBizData: GolfDetailData
    golfPriceData: GolfDetailData
    monthAvgPriceData: PeriodAvgData[] // 월평균 가격 데이터
    monthAvgPriceByRegionData: PeriodAvgData[] // 지역별 월평균 가격 데이터
    regionAvgPriceData: regionAvgPriceItem[] //지역별 현재 가격 데이터
  }
}

interface GolfDetailState {
  golfBizData: GolfDetailData | null
  golfPriceData: GolfDetailData | null
  monthAvgPriceData: GraphDataState['monthData']
  monthAvgPriceByRegionData: GraphDataState['monthAvgPriceByRegion']
  regionAvgPriceData: GraphDataState['regionAvgPrice']
}

export const DetailInfo = () => {
  const location = useLocation()
  const axios = useAxios()

  const navigate = useNavigate()

  const { bizCd, areaCd } = location.state || {}
  const [data, setData] = useState<GolfDetailState>({
    golfBizData: null,
    golfPriceData: null,
    monthAvgPriceData: [],
    monthAvgPriceByRegionData: [],
    regionAvgPriceData: [],
  })

  useEffect(() => {
    if (bizCd) {
      getDetailData()
    }
  }, [])

  const getDetailData = async () => {
    try {
      const { data: responseData } = await axios.get<ApiResponse>(
        '/golf/detail',
        { params: { bizCd, areaCd } },
      )

      if (responseData.common.resultCode === 'G0200') {
        const {
          golfBizData,
          golfPriceData,
          monthAvgPriceData,
          monthAvgPriceByRegionData,
          regionAvgPriceData,
        } = responseData.data

        const monthsArr = generatePeriod('month')
        const updatedGraphData = mapMonthDataToGraph(
          monthAvgPriceData,
          monthsArr,
        )

        setData({
          golfBizData: golfBizData,
          golfPriceData: golfPriceData,
          monthAvgPriceData: updatedGraphData,
          monthAvgPriceByRegionData: monthAvgPriceByRegionData,
          regionAvgPriceData: regionAvgPriceData,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleBack = () => {
    const prevPage = location.state.prevPage
    localStorage.setItem('prevPage', 'detailInfo')
    navigate(-1)
  }

  return (
    <>
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title>골프장 상세 정보</Title>
          <Button onClick={handleBack} color="neverbe" height={'38px'}>
            {location.state.prevPage === 'detail' ? '골프장검색' : '메인'}으로
            돌아가기
          </Button>
        </div>

        {data.golfBizData && (
          <Table>
            <tbody>
              <TableRow>
                <TableCellHeader>골프장</TableCellHeader>
                <TableCell>{data.golfBizData.bizNm}</TableCell>
                <TableCellHeader>지역</TableCellHeader>
                <TableCell>{data.golfBizData.areaNm}</TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader>홈페이지</TableCellHeader>
                <TableCell>
                  <a
                    href={data.golfBizData.baseDomain}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.golfBizData.baseDomain}
                  </a>
                </TableCell>
                <TableCellHeader>회원제 유형</TableCellHeader>
                <TableCell>
                  {data.golfBizData.mshipYn === 'Y'
                    ? '회원제'
                    : data.golfBizData.mshipYn === 'N'
                      ? '비회원제'
                      : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader>이메일</TableCellHeader>
                <TableCell>{data.golfBizData.email || '-'}</TableCell>
                <TableCellHeader>전화번호</TableCellHeader>
                <TableCell>{data.golfBizData.phone || '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader>주소</TableCellHeader>
                <TableCell colSpan={3}>{data.golfBizData.addr}</TableCell>
              </TableRow>
              <TableRow>
                <TableCellHeader>비고</TableCellHeader>
                <TableCell colSpan={3}>{data.golfBizData.cstRem}</TableCell>
              </TableRow>
            </tbody>
          </Table>
        )}
        {data.golfPriceData && (
          <Table style={{ marginTop: '50px' }}>
            <tbody>
              <PriceRow>
                <PriceCellHeader colSpan={4}>요금정보</PriceCellHeader>
              </PriceRow>
              {data.golfPriceData?.n01 !== null &&
                data.golfPriceData?.n02 !== null && (
                  <TableRow>
                    <TableCellHeader>비회원(주중)</TableCellHeader>
                    <TableCell>
                      {data.golfPriceData.n01 > 0
                        ? numberUtils.formatCurrency(data.golfPriceData.n01)
                        : '갱신중'}
                    </TableCell>
                    <TableCellHeader>비회원(주말)</TableCellHeader>
                    <TableCell>
                      {data.golfPriceData.n02 > 0
                        ? numberUtils.formatCurrency(data.golfPriceData.n02)
                        : '갱신중'}
                    </TableCell>
                  </TableRow>
                )}
              {data.golfPriceData?.m01 !== null &&
                data.golfPriceData?.m02 !== null && (
                  <TableRow>
                    <TableCellHeader>회원(주중)</TableCellHeader>
                    <TableCell>
                      {data.golfPriceData.m01 > 0
                        ? numberUtils.formatCurrency(data.golfPriceData.m01)
                        : '갱신중'}
                    </TableCell>
                    <TableCellHeader>회원(주말)</TableCellHeader>
                    <TableCell>
                      {data.golfPriceData.m02 > 0
                        ? numberUtils.formatCurrency(data.golfPriceData.m02)
                        : '갱신중'}
                    </TableCell>
                  </TableRow>
                )}
            </tbody>
          </Table>
        )}
        <div style={{ marginTop: '20px' }}> * 출처 : 공식 홈페이지</div>
      </Container>
      <DetailInfoGraph
        regionAvgPriceData={data.regionAvgPriceData}
        monthAvgPriceByRegionData={data.monthAvgPriceByRegionData}
        monthAvgPriceData={data.monthAvgPriceData}
        areaNm={data.golfBizData?.areaNm}
      />
      {data.golfBizData && (
        <DetailMap
          data={[
            { addr: data.golfBizData.addr, 
              bizNm: data.golfBizData.bizNm, 
              // 인터페이스 변경으로 채울수 있는 값 채워주기
              areaCd: data.golfBizData.areaCd, 
              areaNm: data.golfBizData.areaNm,
              bizCd: data.golfBizData.bizCd,
              m01: data.golfBizData.m01 || -1,
              m02: data.golfBizData.m02 || -1,
              n01:data.golfBizData.n01 || -1,
              n02: data.golfBizData.n02 || -1,
              mshipYn: data.golfBizData.mshipYn || 'N',
              areaCount: -1,
            },
          ]}
        />
      )}
    </>
  )
}
