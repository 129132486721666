import { useAxios } from '@/context/axiosContext'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { NoticeListItem } from '../noticeItem'
import { Badge, Button } from '@/components'
import { NoticeHeader } from './noticeHeader'
import { getBadgeLabel } from '@/pages/board/boardItem'
import commonUtils from '@/utils/commonUtils'

interface Data {
  cmpnyCd: string
  noticeSn: number
}

export const NoticeDetail = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const location = useLocation()
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const [noticeDetail, setNoticeDetail] = useState<NoticeListItem | null>(null)
  const [searchParams] = useSearchParams() // 쿼리 파라미터 사용
  const noticeSn = Number(searchParams.get('noticeSn')) // 게시판 번호
  const cmpnyCd = '001'
  const adminName = '운영자'

  const [height, setHeight] = useState('auto') // 상태로 높이를 관리
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // 높이 초기화
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // 내용에 따라 높이 조정
    }
  }, [noticeDetail?.noticeCn])
  useEffect(() => {
    // 초기 렌더링 시 텍스트 영역의 높이를 자동으로 맞추기 위해 한 번 실행
    if (noticeDetail?.noticeSj) {
      setHeight('auto')
      setHeight(`${document.getElementById('noticeSj')?.scrollHeight}px`)
    }
  }, [noticeDetail?.noticeSj])

  useEffect(() => {
    if (noticeSn) {
      getNoticeDetail() // nttSn 값이 있을 때 API 호출
    }
  }, [noticeSn])

  const handleCopy = () => {
    const url = window.location.href // 현재 페이지 URL
    copyToClipboard(url)
  }

  //detailView
  const getNoticeDetail = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        noticeSn: noticeSn,
      }

      if (!cmpnyCd || !noticeSn) {
        console.error('필수 파라미터가 없습니다.')
        return
      }

      const response = await axios.get(`/notice/detail`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { noticeDetail } = response.data.data
      setNoticeDetail(noticeDetail)

      console.log('noticeDetail', noticeDetail)
      // 응답 데이터가 배열인지 확인하고 상태 업데이트
    } catch (err: any) {
      console.error('Error fetching boardDetail:', err)
    }
  }

  return (
    <>
      <NoticeHeader title={'공지사항'} />
      <div
        style={{
          marginBottom: '10px',
          width: '100%',
          height: 'auto',
          padding: '29px 29px 0',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: '#ebecef',
        }}
      >
        {noticeDetail?.tpNoticeYn === 'Y' && (
          <Badge color="neverbe">{getBadgeLabel('Y')}</Badge>
        )}
        <div
          style={{
            fontSize: '20px',
            fontWeight: '600',
            whiteSpace: 'pre-wrap', // 자동 줄바꿈
            wordWrap: 'break-word', // 긴 단어 줄바꿈
            overflow: 'hidden',
            marginBottom: '5px',
            marginTop: '5px',
          }}
        >
          {noticeDetail?.noticeSj}
        </div>
        <div style={{ color: '#979797' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <div>
                작성자 :{' '}
                {noticeDetail?.regId !== adminName
                  ? adminName
                  : noticeDetail?.regId}
              </div>
              <div style={{ display: 'flex' }}>
                <div>
                  작성일 :{' '}
                  {commonUtils.formatDate(noticeDetail?.regDate || '', true)}
                </div>
                <div
                  style={{
                    margin: '0 8px',
                    borderLeft: '1px solid #ddd',
                    height: '16px',
                  }}
                />
                <div>조회 : {noticeDetail?.readngCnt}</div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{ marginLeft: '8px', cursor: 'pointer' }}
                onClick={handleCopy}
              >
                URL복사
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            boxShadow: '0 0 0 0.5px #ebecef',
            borderColor: '#ebecef',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
        <div
          style={{
            marginBottom: '20px',
          }}
        />
        <textarea
          ref={textareaRef}
          id="noticeCn"
          name="NOTICE_CN"
          value={noticeDetail?.noticeCn}
          style={{
            width: '100%',
            height: '800px',
            padding: '0 0 3.75em 0',
            border: 'none', // 테두리 제거
            outline: 'none', // 포커스 시 테두리 제거
            borderRadius: '4px', // 모서리를 둥글게
            resize: 'none', // 사용자가 크기 조정 불가
            overflow: 'hidden', // 스크롤 숨기기
            backgroundColor: 'transparent',
          }}
        />
      </div>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            color="neverbe"
            onClick={() => navigate(`/notice`, { state: location.state })}
          >
            목록
          </Button>
        </div>
      </div>
    </>
  )
}
