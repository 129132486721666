const numberUtils = {
  // 숫자에 천 단위 구분 기호(콤마) 추가
  formatNumberWithCommas: (
    number: number | string | null | undefined,
  ): string => {
    if (number === null || number === undefined) {
      return ''
    }

    const num = typeof number === 'string' ? parseFloat(number) : number

    if (isNaN(num)) {
      return String(number)
    }

    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  // 숫자를 통화 형식으로 포맷 (예: "1,234,567 원")
  formatCurrency: (number: number | null | undefined): string => {
    if (number === null || number === undefined) {
      return ''
    }
    return numberUtils.formatNumberWithCommas(number) + ' 원' // 통화 형식으로 반환
  },

  // 조회수 변경
  formatViewCount: (count: number): string => {
    // 1,000 미만 그대로 출력 (콤마추가)
    if (count < 1000) {
      return count.toLocaleString()
    }

    // 1,000이상 1,000,000미만 K로 변환
    if (count < 1_000_000) {
      return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }

    // 1,000,000이상 1,000,000,000미만 M으로 변환
    if (count < 1_000_000_000) {
      return (count / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
    }

    // 그 이상은 B로 변환
    return (count / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
  },
}

export default numberUtils
