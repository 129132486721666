import { ApexBarChart } from '@/components'
import { generatePeriod } from '@/pages/main/_components/golfSearch/useGolfSearchData'
import {
  GraphDataState,
  WeekAvgPriceByRegionItem,
} from '@/redux/slices/golfSearchSlice'
import styled from 'styled-components'

interface WeekGraphProps {
  weekData: GraphDataState['weekData']
  weekAvgPriceByRegion?: GraphDataState['weekAvgPriceByRegion']
  isWeekDualGraph?: boolean
  areaCd?: string | null
  areaNm?: string
}

export const WeekGraph = ({
  weekData,
  weekAvgPriceByRegion,
  isWeekDualGraph,
  areaCd,
  areaNm,
}: WeekGraphProps) => {
  const safeWeekGraphData = weekData?.length ? weekData : []
  const safeWeekRegionGraphData = weekAvgPriceByRegion?.length
    ? weekAvgPriceByRegion
    : []

  const isWeekNonMemberDataEmpty = safeWeekGraphData[0]?.every(
    (item) => item === 0,
  )
  const isWeekMemberDataEmpty = safeWeekGraphData[2]?.every(
    (item) => item === 0,
  )

  const weeksArr = generatePeriod('week')

  const mapWeekRegionAvgToGraph = (
    regionData: WeekAvgPriceByRegionItem[] = [],
  ): number[][] => {
    return regionData.reduce(
      (acc, item) => {
        if (!item || !item.year || !item.week) return acc

        const weekString = item.week
          ? item.week.toString().padStart(2, '0')
          : '00'
        const weekIndex = weeksArr.findIndex(
          (w) => w === `${item.year}-${weekString}`,
        )
        if (weekIndex >= 0) {
          acc[0][weekIndex] = item.n01 || 0
          acc[1][weekIndex] = item.n02 || 0
          acc[2][weekIndex] = item.m01 || 0
          acc[3][weekIndex] = item.m02 || 0
        }
        return acc
      },
      Array.from({ length: 4 }, () => new Array(weeksArr.length).fill(0)),
    )
  }

  const regionWeekGraphData = mapWeekRegionAvgToGraph(safeWeekRegionGraphData)

  /** 가격 비교 텍스트 생성 */
  const getPriceDifferenceText = (
    currentPrice: number,
    regionPrice: number,
  ): React.ReactElement => {
    const difference = currentPrice - regionPrice
    const areaText = areaNm
      ? `${areaNm} `
      : !areaCd || areaCd === '00'
        ? '전국 '
        : '같은 지역 '
    alert(areaText)
    return difference === 0 ? (
      <>{areaText} 평균과 같은 가격입니다.</>
    ) : (
      <>
        {areaText} 평균보다{' '}
        <span style={{ color: difference > 0 ? '#ff4d4f' : '#1890ff' }}>
          {Math.abs(difference).toLocaleString('ko-KR')}원
        </span>{' '}
        {difference > 0 ? '높아요' : '낮아요'}
      </>
    )
  }

  const commonOptions: ApexCharts.ApexOptions = {
    chart: { type: 'line', height: 350, toolbar: { show: false } },
    plotOptions: { bar: { columnWidth: '55%' } },
    dataLabels: { enabled: false },
    stroke: { width: 2, curve: 'smooth' },
    markers: { size: 5, strokeWidth: 2, hover: { size: 7 } },
    xaxis: {
      categories: weeksArr,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      title: { text: '단위 (원)' },
      labels: { formatter: (v: any) => v.toLocaleString('ko-KR') },
    },
    fill: { opacity: 1 },
  }

  const colorPalette = {
    member: ['#FFB6C1', '#FF69B4'],
    region: ['#9370DB', '#8FBC8F'],
  }

  const nonWeekMemberData = [
    {
      name: '비회원 전국(주중)',
      data: safeWeekGraphData[0] || [0],
      color: colorPalette.region[0],
      type: 'line',
      dashArray: 5,
    },
    {
      name: '비회원 전국(주말)',
      data: safeWeekGraphData[1] || [0],
      color: colorPalette.region[1],
      type: 'line',
      dashArray: 5,
    },
    {
      name: `${safeWeekRegionGraphData[0]?.areaNm}(주중)`,
      data: regionWeekGraphData[0] || [0],
    },
    {
      name: `${safeWeekRegionGraphData[0]?.areaNm}(주말)`,
      data: regionWeekGraphData[1] || [0],
    },
  ]

  const memberWeekData = [
    {
      name: '회원(주중)',
      data: safeWeekGraphData[2] || [0],
      color: colorPalette.member[0],
    },
    {
      name: '회원(주말)',
      data: safeWeekGraphData[3] || [0],
      color: colorPalette.member[1],
    },
    {
      name: `${safeWeekRegionGraphData[0]?.areaNm}(주중)`,
      data: regionWeekGraphData[2] || [0],
      color: colorPalette.region[0],
      type: 'line',
      dashArray: 5,
    },
    {
      name: `${safeWeekRegionGraphData[0]?.areaNm}(주말)`,
      data: regionWeekGraphData[3] || [0],
      color: colorPalette.region[1],
      type: 'line',
      dashArray: 5,
    },
  ]

  return (
    <GraphWrapper>
      {!isWeekNonMemberDataEmpty && (
        <ApexBarChart
          options={{
            ...commonOptions,
            title: {
              text: '비회원',
              align: 'left',
              style: { fontSize: '16px', fontWeight: 'bold' },
            },
          }}
          height="350px"
          width="100%"
          series={nonWeekMemberData}
        />
      )}

      {!isWeekMemberDataEmpty &&
        (isWeekDualGraph || isWeekNonMemberDataEmpty) && (
          <ApexBarChart
            options={{
              ...commonOptions,
              title: {
                text: '회원',
                align: 'left',
                style: { fontSize: '16px', fontWeight: 'bold' },
              },
            }}
            height="350px"
            width="100%"
            series={memberWeekData}
          />
        )}
    </GraphWrapper>
  )
}

const GraphWrapper = styled.div`
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
`

const GraphTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
`

const GraphText = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`
