import { Badge, Button, Modal, ModalBody, ModalFooter } from '@/components'
import { Comments } from '../comment/comments'
import { BoardHeader } from './boardHeader'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import {
  BoardListItem,
  buttonName,
  CommentListItem,
  confirmMessages,
  getBadgeLabel,
  getHeaderTitle,
  successMessages,
} from '../boardItem'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import { DetailBoardButton } from './detailBoardButton'
import { DetailContents } from './detailContents'
import { DetailBoardReply } from './detailBoardReply'
import { useDialog } from '@/context/dialogContext'

export type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement

interface Data {
  cmpnyCd: string
  nttSn: number
}

const DetailBoard: React.FC = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const headerTitle = getHeaderTitle(window.location.pathname)
  const { pathname } = useLocation()
  const { showAlert, showConfirm } = useDialog()
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const [isCopied, copyToClipboard] = useCopyToClipboard()
  const { user } = useAppSelector((state) => state.auth)
  const commentRef = useRef<HTMLDivElement>(null)

  const [boardDetail, setBoardDetail] = useState<BoardListItem | null>(null)
  const [isDisabled, setIsDisabled] = useState(true) // 초기값은 비활성화
  const [searchParams] = useSearchParams() // 쿼리 파라미터 사용
  const nttSn = Number(searchParams.get('nttSn')) // 게시판 번호
  const cmpnyCd = '001'

  const [commentList, setCommentList] = useState<CommentListItem[]>([])
  const [commentListCnt, setCommentListCnt] = useState(0)
  const [height, setHeight] = useState('auto') // 상태로 높이를 관리
  const userId = user?.userId
  const isGuest = boardDetail?.cstId === ''

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto' // 높이 초기화
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px` // 내용에 따라 높이 조정
    }
  }, [boardDetail?.replyCn])

  useEffect(() => {
    if (nttSn || pathName) {
      getBoardDetail() // nttSn 값이 있을 때 API 호출
      getCommentList()
    }
  }, [nttSn, pathName])

  const handleCopy = () => {
    const url = window.location.href // 현재 페이지 URL
    copyToClipboard(url)
  }

  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target

    setBoardDetail((prevState) => {
      if (!prevState) return null

      return {
        ...prevState,
        [name]: value,
      } as BoardListItem // 모든 필드를 포함하는 상태를 유지
    })
  }

  //detailView
  const getBoardDetail = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      if (!cmpnyCd || !nttSn) {
        console.error('필수 파라미터가 없습니다.')
        return
      }

      const response = await axios.get(`/${pathName}/detail`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { boardDetail } = response.data.data

      console.log('response', response)
      setBoardDetail(boardDetail)
      // 응답 데이터가 배열인지 확인하고 상태 업데이트
    } catch (err: any) {
      console.error('Error fetching boardDetail:', err)
    }
  }

  const getCommentList = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      const response = await axios.get(`/${pathName}/comment/list`, {
        params: { ...data },
      })

      const { commentList } = response.data.data

      if (Array.isArray(commentList)) {
        setCommentList(commentList)
        setCommentListCnt(commentList?.length)
      }
      console.log('getCommentList', commentList)
    } catch (err: any) {
      console.error('Error fetching commentList:', err)
    }
  }

  const handleModalAction = (type: 'update' | 'delete' | 'cancel' | 'save') => {
    if (type === 'delete') {
      showConfirm({
        title: confirmMessages.delete,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          deleteBoard()
        },
      })
    } else if (type === 'cancel') {
      showConfirm({
        title: confirmMessages.cancel,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          setTimeout(() => {
            navigate(0) // 500ms 후에 페이지 이동
          }, 100)
        },
      })
    } else if (type === 'save') {
      showConfirm({
        title: confirmMessages.update,
        confirmBtnName: buttonName.confirm,
        cancelBtnName: buttonName.cancel,
        onConfirm() {
          updateBoard()
        },
      })
    }
  }

  const updateBoard = async () => {
    try {
      const updatedData = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
        nttSj: boardDetail?.nttSj, // 수정된 제목
        nttCn: boardDetail?.nttCn, // 수정된 내용
        useYn: 'Y',
      }

      await axios.post(`/${pathName}/update`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      showAlert({
        type: 'success',
        title: successMessages.update,
        btnName: buttonName.confirm,
        onClose() {
          setTimeout(() => {
            navigate(0)
          }, 100)
        },
      })
    } catch (err: any) {
      console.error('Error updating board:', err)
    }
  }

  const deleteBoard = async () => {
    try {
      const data: Data = {
        cmpnyCd: cmpnyCd,
        nttSn: nttSn,
      }

      if (!cmpnyCd || !nttSn) {
        return
      }

      await axios.post(`/${pathName}/delete`, data, {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 데이터를 보내도록 명시
        },
      })

      showAlert({
        type: 'success',
        title: successMessages.delete,
        btnName: buttonName.confirm,
        onClose() {
          setTimeout(() => {
            navigate(`/${pathName}`)
          }, 100)
        },
      })
    } catch (err: any) {
      console.error('Error fetching deleteBoard:', err)
    }
  }

  const handleEditMode = () => {
    setIsDisabled(false)
  }

  const scrollToComment = () => {
    commentRef.current?.scrollIntoView({
      behavior: 'smooth', // 부드러운 스크롤
    })
  }

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  return (
    <>
      <BoardHeader title={headerTitle} />
      <div
        style={{
          marginBottom: '10px',
          width: '100%',
          height: 'auto',
          padding: '29px 29px 0',
          borderRadius: '6px',
          border: '1px solid',
          borderColor: '#ebecef',
        }}
      >
        <Badge color="neverbe">
          {getBadgeLabel(boardDetail?.bbsSj || '기타')}
        </Badge>
        <DetailContents
          isDisabled={isDisabled}
          boardDetail={boardDetail}
          height={height}
          handleChange={handleChange}
          isGuest={isGuest}
          scrollToComment={scrollToComment}
          handleCopy={handleCopy}
        />
      </div>
      <DetailBoardReply boardDetail={boardDetail} textareaRef={textareaRef} />
      <DetailBoardButton
        commentRef={commentRef}
        isDisabled={isDisabled}
        boardDetail={boardDetail}
        handleEditMode={handleEditMode}
        handleModalAction={handleModalAction}
        pathName={pathName}
        userId={userId}
      />
      <Comments
        withDraw={boardDetail?.withDrawYn}
        commentListCnt={commentListCnt}
        nttSn={nttSn}
        commentList={commentList}
        pathName={pathName}
        cstId={user?.cstId}
        nickName={user?.nickName}
      />
    </>
  )
}

export default DetailBoard
