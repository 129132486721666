import { Badge } from '@/components'
import { StyledTable } from '@/components/ui/table/style'
import { useAxios } from '@/context/axiosContext'
import { BoardTable } from '@/pages/board/_components/boardTable'
import { BoardListItem, getBadgeLabel } from '@/pages/board/boardItem'
import { StyledTD } from '@/pages/main/_components/board/style'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface GetBoardData {
  srchOpt?: string //검색옵션
  srchStr?: string //검색어
  page?: number //페이지
  bbsTy: string //게시판타입 (01:자유게시판)
  bbsSj: string //게시판말머리(01:잡담)
}

export const MainBoard = () => {
  const axios = useAxios()
  const navigate = useNavigate()

  const [srchData] = useState({
    srchOpt: '',
    srchStr: '',
  })

  const [boardList, setBoardList] = useState<BoardListItem[]>([])

  useEffect(() => {
    getBoardList()
  }, [])

  //boardList
  const getBoardList = async () => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: 1,
        bbsTy: '01',
        bbsSj: '',
      }

      const response = await axios.get('/board/list', {
        params: { ...data },
      })

      const { boardList } = response.data.data
      if (Array.isArray(boardList)) {
        setBoardList(boardList)
      }
    } catch (err: any) {
      console.error('Error fetching boardList:', err)
    }
  }

  //글상세보기
  const handleDetailView = (nttSn: number, answerCnt: number) => {
    window.scrollTo(0, 0)
    navigate(`/board/detail?nttSn=${nttSn}&answerCnt=${answerCnt}`)
  }

  const moveQnaBoard = () => {
    window.scrollTo(0, 0)
    navigate(`/board`)
  }

  return (
    <MainBoardWrapper>
      <BoardTitle onClick={moveQnaBoard}>
        <div>{'문의게시판'}</div>
        <div
          style={{
            cursor: 'pointer',
            fontSize: '13px',
            color: '#888888',
            fontWeight: 'normal',
          }}
          onClick={moveQnaBoard}
        >
          {'더보기'}
        </div>
      </BoardTitle>
      <StyledTable
        style={{
          tableLayout: 'fixed',
          width: '100%',
          marginTop: '2px',
          marginBottom: '0px',
        }}
      >
        <tbody>
          {boardList.length > 0 ? (
            boardList.slice(0, 5).map((item) => (
              <tr key={item.nttSn}>
                <StyledTD
                  style={{ width: 'auto' }}
                  onClick={() => handleDetailView(item.nttSn, item?.answerCnt)}
                >
                  <Badge color="neverbe">{getBadgeLabel(item.bbsSj)}</Badge>
                  &nbsp;&nbsp;
                  {item.nttSj}
                  {item.answerCnt ? ` [${item.answerCnt}]` : ''}
                  &nbsp;&nbsp;
                  {item.replyYn === 'Y' && (
                    <Badge color="success">답변완료</Badge>
                  )}
                </StyledTD>
                <StyledTD style={{ width: '100px' }}>{item.nickName}</StyledTD>
              </tr>
            ))
          ) : (
            <tr>
              <StyledTD colSpan={2} style={{ padding: '20px 10px' }}>
                아직 등록된 문의가 없습니다. 첫 번째로 문의를 남겨보세요!
              </StyledTD>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </MainBoardWrapper>
  )
}

export const BoardTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  cursor: pointer;
`

export const MainBoardWrapper = styled.div`
  border: 1px solid #ddd;
  padding: 13.5px 15px 5px 15px;
  border-radius: 8px;
  width: 100%;
  border-bottom: 1px solid #ddd; /* 테두리 유지 */
  box-sizing: border-box; /* padding이 border에 포함되도록 설정 */
`
