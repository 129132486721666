import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'

const ResultPage = () => {
  const location = useLocation()
  const axios = useAxios()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    getCertData(params)
  }, [location])

  const getCertData = async (params: any) => {
    try {
      const requestData = {
        token_version_id: params.get('token_version_id'),
        enc_data: params.get('enc_data'),
        integrity_value: params.get('integrity_value'),
      }

      const response = await axios.get('/certification/nice-test-callback', {
        params: { ...requestData },
      })

      if (response.data && response.data.decryptedData) {
        if (window.opener) {
          window.opener.postMessage(
            { type: 'NICE_AUTH_SUCCESS', data: response.data.decryptedData },
            window.location.origin,
          )
        }
        window.close()
      }
    } catch (err) {
      console.error('인증 데이터 가져오기 실패:', err)
    }
  }

  return <div>인증처리</div>
}

export default ResultPage
