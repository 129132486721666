import { useDialog } from '@/context/dialogContext'
import { BoardHeader } from '../_components/boardHeader'
import { getHeaderTitle } from '../boardItem'
import { CommunityBoardList } from './communityBoardList'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const CommunityBoard: React.FC = () => {
  const headerTitle = getHeaderTitle(window.location.pathname)

// TODO 임시 블록기능
  const { showAlert } = useDialog();
  const navigate = useNavigate();
  useEffect(() => {
    showAlert({
      type:'warning',
      message: '서비스 준비중입니다.',
      // title?: string | React.ReactNode
      btnName: '확인',
      onClose: () => {
        navigate(-1);
      }
  });
  }, []);

  return (
    <>
    {/*   TODO 임시 주석처리
      <BoardHeader title={headerTitle} />
      <CommunityBoardList /> */}
    </>
  )
}

export default CommunityBoard
