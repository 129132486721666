import { useEffect, useState } from 'react'
import { BoardListItem, GetBoardData } from '../boardItem'
import { useAxios } from '@/context/axiosContext'
import { BoardList } from '../_components/boardList'
import { useLocation } from 'react-router-dom'

export const QnaBoardList = () => {
  const axios = useAxios()
  const location = useLocation()
  const [page, setPage] = useState(location.state?.page || 1)
  const [listData, setListData] = useState<BoardListItem[]>([])
  const [totalCount, setTotalCount] = useState(0) //페이지 count
  const [srchData, setSrchData] = useState({
    srchOpt: location.state?.srchOpt || '01',
    srchStr: location.state?.srchStr || '',
  })

  useEffect(() => {
    // API 호출 함수
    getQnaBoardList(page); // 컴포넌트 마운트 시 API 호출
  }, [page]) // 빈 배열을 넣으면 처음 한 번만 호출됨

  const getQnaBoardList = async (pageParam:number) => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: pageParam,
      }

      const response = await axios.get(`/board/list`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { boardList, pageCnt } = response.data.data

      if (Array.isArray(boardList)) {
        setListData(boardList)
      }
      setTotalCount(pageCnt)
    } catch (err: any) {
      console.error('Error fetching List:', err)
    }
  }

  return (
    <>
      <BoardList
        srchData={srchData}
        setSrchData={setSrchData}
        setPage={setPage}
        boardList={listData}
        pageCnt={totalCount}
        getBoardList={getQnaBoardList}
        boardSrchData={{
          srchOpt: srchData.srchOpt,
          srchStr: srchData.srchStr,
          page: page,
        }}
      />
    </>
  )
}
