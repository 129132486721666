import AxiosSample from '@/pages/sample/axiosSample'
import CounterComponent from '@/components/sample/counterComponent'
import PostSample from '@/components/sample/postSample'
import { NiceApiTest } from './niceApiTest'
import { AlertSample } from '@/components/sample/alertSample'
import FileUploadSample from '@/components/sample/fileUploadSample'

const Sample: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>환경 정보</h1>
      <p>
        현재 환경 : {process.env.REACT_APP_ENV}
        <br />
        API BASE URL : {process.env.REACT_APP_API_BASE_URL}
      </p>
      <hr />
      <h1>Hook Sample</h1>
      <CounterComponent />
      <hr />
      <h1>Axios Sample</h1>
      <AxiosSample />
      <hr />
      <PostSample />
      <hr />
      <NiceApiTest />
      <hr />
      <AlertSample />
      <hr />
      <FileUploadSample />
    </div>
  )
}

export default Sample
