export interface NoticeListItem {
  cmpnyCd: string
  noticeSn: number
  lclasCd?: string
  mlsfcCd?: string
  sclasCd?: string
  noticeSj: string
  noticeCn: string
  fileSeq: number
  readngCnt: number
  answerCnt: number
  tpNoticeYn: string
  noticeFromDate: string
  noticeToDate: string
  popupYn?: string
  popupFromDate?: string
  popupToDate?: string
  useYn: string
  openYn: string
  regId: string
  regDate: string
  updId: string
  updDate: string
  rnum: number
}

export const fieldNames = ['NO', '제목', '작성자', '작성일', '조회수']
