import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useLoading } from '@/context/loadingContext'

// 애니메이션 정의
const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const move = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`

// 스타일 정의
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 반투명 딤 처리 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 최상위 레이어 */
`

const Container = styled.div`
  text-align: center;
  color: #fff;
`

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin-bottom: 20px;
`

const LoadingText = styled.div`
  span {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    animation: ${move} 1.5s linear infinite;
  }
  span:nth-child(1) {
    animation-delay: 0s;
  }
  span:nth-child(2) {
    animation-delay: 0.1s;
  }
  span:nth-child(3) {
    animation-delay: 0.2s;
  }
  span:nth-child(4) {
    animation-delay: 0.3s;
  }
  span:nth-child(5) {
    animation-delay: 0.4s;
  }
  span:nth-child(6) {
    animation-delay: 0.5s;
  }
  span:nth-child(7) {
    animation-delay: 0.6s;
  }
  span:nth-child(8) {
    animation-delay: 0.7s;
  }
  span:nth-child(9) {
    animation-delay: 0.8s;
  }
  span:nth-child(10) {
    animation-delay: 0.9s;
  }
`

// 컴포넌트 정의
const Loading = () => {
  const { isLoading } = useLoading()

  if (!isLoading) return null

  return (
    <Overlay>
      <Container>
        <Spinner />
        <LoadingText>
          <span>L</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </LoadingText>
      </Container>
    </Overlay>
  )
}

export default Loading
