import { Button } from '@/components'
import React from 'react'
import styled from 'styled-components'
import { display, size } from 'styled-system'

interface ConfirmProps {
  title?: string | React.ReactNode
  message: string | React.ReactNode
  confirmBtnName: string
  cancelBtnName: string
  onConfirm: () => void
  onCancel: () => void
}

const DimBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`

const ConfirmWrapper = styled.div`
  position: fixed;
  // position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 350px;
  min-height: 150px;
  max-height: 350px;
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center; /* 세로로 중앙 정렬 */
  padding: 30px;
`

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: center;
  white-space: pre-wrap !important;
  margin-bottom: '10px';
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
`
const MessageWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  max-height: 300px;
  padding-bottom: 10px;
`

const Message = styled.div`
  font-size: 14px;
  color: #666;
  margin: 3px 0 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-center;
  justify-content: center;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 10px;
`

const Confirm: React.FC<ConfirmProps> = ({
  title,
  message,
  confirmBtnName,
  cancelBtnName,
  onConfirm,
  onCancel,
}) => (
  <>
    <DimBackground />
    <ConfirmWrapper>
      {title && <Title>{title}</Title>}
      {message && <Message>{message}</Message>}
      <ButtonGroup>
        <Button onClick={onConfirm} color="neverbe" width={'100%'}>
          {confirmBtnName}
        </Button>
        <Button
          onClick={onCancel}
          color="neverbe"
          variant="outlined"
          width={'100%'}
        >
          {cancelBtnName}
        </Button>
      </ButtonGroup>
    </ConfirmWrapper>
  </>
)

export default Confirm
