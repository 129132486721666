import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { useDialog } from '@/context/dialogContext'

interface RouteGuardProps {
  element: React.ReactElement
  path: string
  requireAuth?: boolean
}

const RouteGuard: React.FC<RouteGuardProps> = ({
  element,
  path,
  requireAuth = false,
}) => {
  const location = useLocation()
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />
  } else if (path == '/' && isAuthenticated) {
    return <Navigate to="/main" replace />
  }

  return element
}

export default RouteGuard
