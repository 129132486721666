import { useAxios } from '@/context/axiosContext'
import { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { InsertForm } from './insertForm'
import { useDialog } from '@/context/dialogContext'
import {
  buttonName,
  confirmMessages,
  errorMessages,
  fieldMessages,
  getHeaderTitle,
} from '../boardItem'

type TInput = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
type FormData = {
  nttSj: string
  nttCn: string
  bbsSj: string
}

export const InsertMember: React.FC = () => {
  const axios = useAxios()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathName = pathname.split('/')[1] // 예: '/type1/list' -> 'type1'
  const headerTitle = getHeaderTitle(window.location.pathname)
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)
  const [postResponse, setPostResponse] = useState<any>(null)
  const [loading, setLoading] = useState(false) // 로딩 상태
  const [error, setError] = useState<string | null>(null) // 에러 상태
  const [showSaveButton, setShowSaveButton] = useState(true) // true: 저장/취소 || false : 닫기
  const [moveList, setMoveList] = useState(false) // true: 저장/취소 || false : 닫기
  const [modalMessage, setModalMessage] = useState('') // Modal 메시지 상태

  const [formData, setFormData] = useState({
    cmpnyCd: '001',
    bbsTy: '01',
    nttSj: '',
    nttCn: '',
    bbsSj: '',
    cstId: user?.cstId,
    userId: user?.userId,
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { showAlert, showConfirm } = useDialog()
  const [fileName, setFileName] = useState<string>('') // 선택된 파일 이름을 저장
  //input 값 변경 핸들러
  const handleChange = (e: React.ChangeEvent<TInput>) => {
    const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement // 타입 캐스팅
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  //폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // 필수 입력 필드와 메시지 매핑
    const requiredFields: { field: keyof FormData; message: string }[] = [
      { field: 'bbsSj', message: fieldMessages.bbsSj },
      { field: 'nttSj', message: fieldMessages.nttSj },
      { field: 'nttCn', message: fieldMessages.nttCn },
    ]

    // 필수 입력값 확인
    for (const { field, message } of requiredFields) {
      if (!formData[field]) {
        showAlert({
          type: 'warning',
          message: message,
          btnName: '확인',
        })
        return
      }
    }

    showConfirm({
      title: confirmMessages.insert,
      confirmBtnName: '확인',
      cancelBtnName: '취소',
      onConfirm() {
        insertBoard()
      },
    })
  }

  const insertBoard = async () => {
    try {
      await axios.post(`/${pathName}/insert`, formData) // POST 요청
      setTimeout(() => {
        navigate(`/${pathName}`)
      }, 300)
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: errorMessages.insert,
        message: err.response?.data?.message,
        btnName: buttonName.confirm,
      })
    }
  }

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // 숨겨진 파일 입력창 열기
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setFileName(file.name) // 선택된 파일 이름 저장
    }
  }

  const handleList = () => {
    showConfirm({
      title: confirmMessages.cancel,
      message: confirmMessages.cancel,
      confirmBtnName: '확인',
      cancelBtnName: '취소',
      onConfirm() {
        navigate(`/${pathName}`)
      },
    })
  }

  return (
    <>
      <InsertForm
        formData={formData}
        fileName={fileName}
        modalMessage={modalMessage}
        showSaveButton={showSaveButton}
        moveList={moveList}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleFileChange={handleFileChange}
        handleButtonClick={handleButtonClick}
        handleList={handleList}
        navigate={navigate}
        insertBoard={insertBoard}
        isAuthenticated={isAuthenticated}
      />
    </>
  )
}
