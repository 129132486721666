import App from '@/App'
import { AxiosProvider } from '@/context/axiosContext'
import { DialogProvider } from '@/context/dialogContext'
import { LoadingProvider, useLoading } from '@/context/loadingContext'
import '@/index.css'
import PersistProvider from '@/redux/providers/persist-provider'
import ThemeProvider from '@/redux/providers/theme-provider'
import { store } from '@/redux/store'
import reportWebVitals from '@/reportWebVitals'
import { setupAxiosInterceptors } from '@/utils/axiosInstance'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'

const Root = () => {
  const { startLoading, stopLoading } = useLoading()
  setupAxiosInterceptors(startLoading, stopLoading)

  if(process.env.NODE_ENV === 'production'){
    // TODO 운영환경에서 console.log 사용처리 정하기 
    // console.log = () => {
    //   // console.log prod 에서는 사용하지 않음. 
    // };
  }

  return <App />
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistProvider>
        <ThemeProvider>
          <LoadingProvider>
            <DialogProvider>
              <AxiosProvider>
                <HelmetProvider>
                  <Root />
                </HelmetProvider>
              </AxiosProvider>
            </DialogProvider>
          </LoadingProvider>
        </ThemeProvider>
      </PersistProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')!,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
