import Content from '@/components/layout/content'
import VerifyContainer from './_components'

const CompleteMember = () => {
  return (
    <>
      <Content fullHeight align="center">
        <VerifyContainer />
      </Content>
    </>
  )
}

export default CompleteMember
