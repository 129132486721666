import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'
import { BlogListItem } from '@/pages/board/blogItem'
import { getBadgeLabel, GetBoardData } from '@/pages/board/boardItem'
import { StyledTable, StyledTD } from '@/pages/main/_components/board/style'
import { Badge } from '@/components'
import { marginBottom } from 'styled-system'
import { BoardTitle, MainBoardWrapper } from '../board/mainBoard'

export const MainBlog = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axios = useAxios()
  const [page, setPage] = useState(location.state?.page ?? 1)
  const [blogList, setBlogList] = useState<BlogListItem[]>([])
  const [srchData, setSrchData] = useState({
    srchOpt: location.state?.srchOpt || '01',
    srchStr: location.state?.srchStr || '',
  })

  useEffect(() => {
    getBlogBoardList()
  }, [])

  const getBlogBoardList = async () => {
    try {
      const data: GetBoardData = {
        srchOpt: srchData.srchOpt,
        srchStr: srchData.srchStr,
        page: page,
      }

      const response = await axios.get(`/blog/list`, {
        headers: { 'Cache-Control': 'no-cache' },
        params: { ...data },
      })

      const { blogList } = response.data.data
      console.log('getBlogBoardList', response.data.data)
      if (Array.isArray(blogList)) {
        setBlogList(blogList)
      }
    } catch (err: any) {
      console.error('Error fetching List:', err)
    }
  }

  const handleDetailView = (nttSn: number) => {
    window.scrollTo(0, 0)
    navigate(`/blog/detail?nttSn=${nttSn}`)
  }

  const moveBlogBoard = () => {
    window.scrollTo(0, 0)
    navigate(`/blog`)
  }

  return (
    <>
      <MainBoardWrapper>
        <BoardTitle onClick={moveBlogBoard}>
          {'소통방'}
          <div
            style={{
              cursor: 'pointer',
              fontSize: '13px',
              color: '#888888',
              fontWeight: 'normal',
            }}
            onClick={moveBlogBoard}
          >
            {'더보기'}
          </div>
        </BoardTitle>
        <StyledTable
          style={{
            tableLayout: 'fixed',
            width: '100%',
            marginTop: '2px',
            marginBottom: '0px',
          }}
        >
          <tbody style={{ fontSize: '14px' }}>
            {blogList.length > 0 ? (
              blogList.slice(0, 5).map((item) => (
                <tr key={item.nttSn}>
                  <StyledTD
                    style={{ width: 'auto' }}
                    onClick={() => handleDetailView(item.nttSn)}
                  >
                    <Badge color="neverbe">
                      {getBadgeLabel(item.bbsSj ? item?.bbsSj : '')}
                    </Badge>
                    &nbsp;&nbsp;
                    {item.nttSj}
                    {item.answerCnt ? ` [${item.answerCnt}]` : ''}
                    &nbsp;&nbsp;
                  </StyledTD>
                  <StyledTD style={{ width: '100px' }}>
                    {item.nickName}
                  </StyledTD>
                </tr>
              ))
            ) : (
              <tr>
                <StyledTD colSpan={2} style={{ padding: '20px 10px' }}>
                  아직 등록된 문의가 없습니다. 첫 번째로 문의를 남겨보세요!
                </StyledTD>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </MainBoardWrapper>
    </>
  )
}
