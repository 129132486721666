import { useDialog } from '@/context/dialogContext'
import { useAppSelector } from '@/redux/hooks'
import { routes } from '@/routes/routes'
import React, { useState } from 'react'
import { Menu, X } from 'react-feather'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const GNBContainer = styled.nav`
  display: flex;
  gap: 20px;
`
// @media (max-width: 768px) {
//   display: none;
// }

const MenuItem = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: #333333;
  font-size: 1rem;
  font-weight: 600;
  transition: color 0.3s ease;

  &.active {
    color: #ff7043;
  }

  &:hover {
    color: #ff7043;
  }

  &:focus {
    outline: none;
  }
`

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
`
// @media (max-width: 768px) {
//   display: block;
//   position: relative;
//   z-index: 110;
// }

const MobileMenuOverlay = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  a {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;
    margin: 15px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;

    &:hover {
      color: #ff7043;
    }
  }
`

const CloseButton = styled(X)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  z-index: 110;
`

const GNB: React.FC = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { isAuthenticated } = useAppSelector((state) => state.auth)
  const location = useLocation()
  const navigate = useNavigate()
  const { showConfirm, showAlert } = useDialog()

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev)
  }

  // const handleMenuClick = (path: string, requireAuth?: boolean) => {
  const handleMenuClick = (route: any) => {
    const path = route.path || '/'
    if (route.notReady) {
      showAlert({
        type: 'warning',
        message: '서비스 준비중입니다.',
        // title?: string | React.ReactNode
        btnName: '확인',
        onClose: () => {},
      })
      return
    }
    /* //2025.03.19 ljh 정보제공 목적으로 로그인 후 이용 옵션 제거
    if (route.requireAuth && !isAuthenticated) {
      alert(path)
      if (path === '/golf/detailSearch') {
        showConfirm({
          title:
            '로그인 후 골프장검색 메뉴에서 이용할 수 있는 옵션이에요. 로그인화면으로 이동할까요?',
          onConfirm() {
            navigate('/login', {
              state: {
                redirectTo: '/golf/detailSearch',
              },
            })
          },
        })
      }
      return
    }
    */

    if (location.pathname === path) {
      navigate(0)
    } else {
      navigate(path)
    }
    setMobileMenuOpen(false)
  }

  const isMenuItemActive = (path: string) => {
    return location.pathname.startsWith(path)
  }

  return (
    <>
      <MobileMenuButton onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <X /> : <Menu />}
      </MobileMenuButton>

      <MobileMenuOverlay isOpen={isMobileMenuOpen}>
        <CloseButton onClick={toggleMobileMenu} />
        {routes
          .filter((route) => route.label)
          .map((route, index) => (
            <MenuItem
              key={index}
              onClick={() =>
                // handleMenuClick(route.path ?? '/', route.requireAuth)
                handleMenuClick(route)
              }
              className={isMenuItemActive(route.path ?? '') ? 'active' : ''}
            >
              {route.label}
            </MenuItem>
          ))}
      </MobileMenuOverlay>

      <GNBContainer>
        {routes
          .filter((route) => route.label)
          .map((route, index) => (
            <MenuItem
              key={index}
              onClick={() =>
                // handleMenuClick(route.path ?? '/', route.requireAuth)
                handleMenuClick(route)
              }
              className={isMenuItemActive(route.path ?? '') ? 'active' : ''}
            >
              {route.label}
            </MenuItem>
          ))}
      </GNBContainer>
    </>
  )
}

export default GNB
