import { DetailMap } from '@/pages/golf/_components/detailMap'
import { DetailSearchFilter } from '@/pages/golf/detailSearch/_components/detailSearchFilter'
import { DetailSearchGraph } from '@/pages/golf/detailSearch/_components/detailSearchGraph'
import { DetailSearchResult } from '@/pages/golf/detailSearch/_components/detailSearchResult'
import { MainGolfSearchFilter } from '@/pages/main/_components/golfSearch/mainGolfSearchFilter'
import { MainGolfSearchResult } from '@/pages/main/_components/golfSearch/mainGolfSearchResult'
import { DetailSearchRegionGraph } from '@/pages/golf/detailSearch/_components/detailSearchRegionGraph'
import {
  GolfSearchState,
  initialSearchState,
  setSearchParams,
} from '@/redux/slices/golfSearchSlice'
import { RootState } from '@/redux/store'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const DetailSearch = () => {
  const listData = useSelector(
    (state: RootState) => state.golfSearch.golfListData.listData,
  )

  return (
    <DetailContent>
      <DetailSearchWrapper>
        {/* <DetailSearchFilter />
      <DetailSearchResult /> */}
        {/*<DetailSearchRegionGraph />*/}
        <MainGolfSearchFilter />
        <MainGolfSearchResult />
        <DetailMap data={listData} screenType="search" />
        <DetailSearchGraph />
      </DetailSearchWrapper>
    </DetailContent>
  )
}

const DetailSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justifycontent: flex-end;
`

const DetailContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
