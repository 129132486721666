import { MainGolfSearchFilter } from '@/pages/main/_components/golfSearch/mainGolfSearchFilter'
import { MainGolfSearchResult } from '@/pages/main/_components/golfSearch/mainGolfSearchResult'
import styled from 'styled-components'

export const MainGolfSearch = () => {
  return (
    <MainGolfSearchWrapper>
      <MainGolfSearchFilter />
      <MainGolfSearchResult />
    </MainGolfSearchWrapper>
  )
}

const MainGolfSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
