import { MonthGraph } from '@/pages/golf/detailSearch/_components/graphs/monthGraph'
import { PeriodGraph } from '@/pages/golf/detailSearch/_components/graphs/periodGraph'
import { RegionGraph } from '@/pages/golf/detailSearch/_components/graphs/regionGraph'
import { WeekGraph } from '@/pages/golf/detailSearch/_components/graphs/weekGraph'
import { RootState } from '@/redux/store'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

export const DetailSearchGraph = () => {
  const {
    monthData,
    monthAvgPriceByRegion,
    quarterData,
    yearData,
    weekData,
    weekAvgPriceByRegion,
  } = useSelector((state: RootState) => state.golfSearch.graphData)

  const { areaCd } = useSelector(
    (state: RootState) => state.golfSearch.searchParams,
  )

  const { regionAvgPrice } = useSelector(
    (state: RootState) => state.golfSearch.graphData,
  )

  const safeGraphData = monthData && monthData.length ? monthData : []
  const isMonthDataEmpty = safeGraphData.every((arr) =>
    arr.every((item) => item === 0),
  )

  const safeWeekData = weekData && weekData.length ? weekData : [[], [], [], []]

  const safeWeekAvgPriceByRegion =
    weekAvgPriceByRegion && weekAvgPriceByRegion.length
      ? weekAvgPriceByRegion
      : []

  const isWeekDataEmpty = safeWeekData.every((arr) =>
    arr.every((item) => item === 0),
  )

  return (
    <GraphWrapper>
      {/*!isMonthDataEmpty && (
        <MonthGraph
          monthData={monthData}
          monthAvgPriceByRegion={monthAvgPriceByRegion}
          areaCd={areaCd}
          isDualGraph={true}
        />
      )*/}
      {!isWeekDataEmpty && (
        <WeekGraph
          weekData={weekData}
          weekAvgPriceByRegion={weekAvgPriceByRegion}
          areaCd={areaCd}
          isWeekDualGraph={true}
        />
      )}
      {/*<PeriodGraph data={quarterData} type="quarter" />*/}
      {/*<PeriodGraph data={yearData} type="year" />*/}
      <RegionGraph regionAvgPrice={regionAvgPrice} />
    </GraphWrapper>
  )
}

const GraphWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`
