import { useEffect, useState } from 'react'
import { ArrowUp } from 'react-feather'
import styled from 'styled-components'

const ScrollTopButtonWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 15%;

  
  z-index: 20;
  `
  // @media (max-width: 768px) {
  //   right: 2%;
  // }

const ArrowUpStyled = styled(ArrowUp)`
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
`

const ScrollTopButton = () => {
  const [showTopButton, setShowTopButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 200)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return showTopButton ? (
    <ScrollTopButtonWrapper onClick={scrollToTop}>
      <ArrowUpStyled />
    </ScrollTopButtonWrapper>
  ) : null
}

export default ScrollTopButton
