import styled from 'styled-components'
import SignupForm from './_components'

const NewMember = () => {
  return (
    // <StyledMedia>
    //   <StyledSignin>
    <SignupFormWrapper>
      <SignupForm />
    </SignupFormWrapper>
    //   </StyledSignin>
    // </StyledMedia>
  )
}

const SignupFormWrapper = styled.div`
  max-width: 800px;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`
export default NewMember
