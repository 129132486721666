import { useEffect, useState } from 'react'
import { YoutubeRankData } from '../youtubeRankItem'
import LOGO from '../../../assets/images/logoSample.jpeg'
import './youtubeRank.css'
import { useAppSelector } from '@/redux/hooks'
import { useDialog } from '@/context/dialogContext'

interface ListItemProps {
  title: string
  noti?: string
  data: YoutubeRankData[]
  setLikeStatus: (
    formData: FormData,
    chnlId: string,
    currentLikeStatus: string,
  ) => void
}

export const YoutubeListItem: React.FC<ListItemProps> = ({
  title,
  noti,
  data,
  setLikeStatus,
}) => {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth)
  const { showAlert } = useDialog()

  const [likesState, setLikesState] = useState(
    data.reduce(
      (acc, item) => {
        acc[item.chnlId] = isAuthenticated ? item.userLikeYn || 'N' : 'N' // 'Y'면 좋아요, 'N'이면 좋아요 없음
        return acc
      },
      {} as Record<string, string>,
    ),
  )

  useEffect(() => {
    setLikesState(
      data.reduce(
        (acc, item) => {
          acc[item.chnlId] = isAuthenticated ? item.userLikeYn || 'N' : 'N'
          return acc
        },
        {} as Record<string, string>,
      ),
    )
  }, [data])

  const formatSubscribers = (num: number): string => {
    if (num >= 10000) {
      return (num / 10000).toFixed(1) + '만' // 반올림
    }
    return num.toLocaleString()
  }

  const formatVideos = (num: number): string => {
    if (num >= 1000) {
      return Math.floor(num / 100) / 10 + '천' // 내림 (예: 1576 → 1.5천)
    }
    return num.toLocaleString()
  }

  const openYouTubeChannel = (chnlId: string) => {
    const url = `https://www.youtube.com/channel/${chnlId}`
    window.open(url, '_blank')
  }

  const handleClick = (chnlId: string) => {
    if (!isAuthenticated) {
      showAlert({
        type: 'error',
        title: '로그인 후 좋아요를 눌러주세요.',
      })
      return
    }
    const newLikesYn = likesState[chnlId] === 'Y' ? 'N' : 'Y'

    setLikesState((prevState) => ({
      ...prevState,
      [chnlId]: newLikesYn,
    }))

    if (user?.cstId) {
      const formData = new FormData()
      formData.append('cstId', user.cstId)
      formData.append('chnlId', chnlId)
      formData.append('likesYn', newLikesYn)
      setLikeStatus(formData, chnlId, likesState[chnlId])
    } else {
      showAlert({
        type: 'error',
        title: '로그인 후 좋아요를 눌러주세요.',
      })
    }
  }

  return (
    <>
      <div className="ranking-header">
        <div className="ranking-title">
          <span>{title}</span>
          {noti && <span style={{ fontSize: '14px' }}>{noti}</span>}
        </div>
      </div>
      <div className="ranking-container">
        {data.map((item, index) => (
          <div key={index} className="ranking-item">
            <div className="rank">{item.chnlRank}</div>
            <div
              className="logo"
              onClick={() => openYouTubeChannel(item.chnlId)}
            >
              <img src={item.thumbnailsDefault} alt={LOGO} />
            </div>
            <div
              className="channel-info"
              onClick={() => openYouTubeChannel(item.chnlId)}
            >
              <div className="channel-name">{item.title}</div>
              <div className="subscribers">
                {`구독자 ${formatSubscribers(item.subscriberCount)}명 | `}
                {`동영상 ${formatVideos(item.videoCount)}개`}
              </div>
            </div>
            <div className="channel-description channel-description-multi">
              {item.chnlDesc}
            </div>
            <div className="likes">
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(item.chnlId)}
                width="30"
                height="30"
              >
                <g data-name="Layer 1" id="Layer_1">
                  <path
                    d="M16 29s13-8.35 13-14.15c0-5.03-3.4-8.66-7.5-8.66-2.86 0-4.91 2.11-5.5 3.56-0.59-1.45-2.64-3.56-5.5-3.56-4.1 0-7.5 3.63-7.5 8.66C3 20.65 16 29 16 29z"
                    fill={likesState[item.chnlId] === 'Y' ? '#ff0000' : 'none'} // likesYn에 따라 색상 변경
                    stroke={
                      likesState[item.chnlId] === 'Y' ? 'none' : '#101820'
                    }
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
            <div className="like-count">{formatVideos(item.likeCount)}</div>
          </div>
        ))}
      </div>
    </>
  )
}
