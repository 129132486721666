import commonUtils from '@/utils/commonUtils'
import {
  BoardListItem,
  maskEmail,
  maskFormatString,
  maskIpSuffix,
  withDrawnCumtomer,
} from '../boardItem'

interface DetailBoardInfoProps {
  isGuest: boolean
  boardDetail: BoardListItem | null
  scrollToComment: () => void
  handleCopy: () => void
}
export const DetailBoardInfo: React.FC<DetailBoardInfoProps> = ({
  isGuest,
  boardDetail,
  scrollToComment,
  handleCopy,
}) => {
  return (
    <div style={{ color: '#979797' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <div>
            작성자 :&nbsp;
            {boardDetail?.withDrawYn === 'Y'
              ? withDrawnCumtomer.name
              : isGuest
                ? boardDetail?.guestName
                : boardDetail?.nickName}
            &nbsp; ({boardDetail?.accessIp})
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              작성일 :&nbsp;
              {boardDetail?.regDate
                ? commonUtils.formatDate(boardDetail.regDate, true)
                : ''}
            </div>
            <div
              style={{
                margin: '0 8px',
                borderLeft: '1px solid #ddd',
                height: '16px',
              }}
            />
            <div>조회 : {boardDetail?.readngCnt}</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          {
            // TODO 댓글이 있는 게시판에서 활용할때 조건 수정 필요
            false ? (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                  </svg>
                </div>
                <div
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={scrollToComment}
                >
                  댓글 {boardDetail?.answerCnt}
                </div>
                <div
                  style={{
                    margin: '0 8px',
                    borderLeft: '1px solid #ddd',
                    height: '16px',
                  }}
                />
              </>
            ) : (
              <></>
            )
          }

          <div
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={handleCopy}
          >
            URL복사
          </div>
        </div>
      </div>
    </div>
  )
}
