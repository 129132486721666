import commonUtils from '@/utils/commonUtils'
import { CommentListItem } from '../boardItem'
import './adminComment.css' // CSS 파일 import

type CommentProps = {
  commentList: CommentListItem[]
}

export const AdminComment = ({ commentList }: CommentProps) => {
  const adminName = '관리자'
  const adminComments = commentList.filter(
    (comment) => comment.regId === 'ADMIN',
  )
  return (
    <>
      {adminComments.map((item) => (
        <>
          <div key={item.commentSn} className="admin-chat-container">
            <div className="admin-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div className="admin-speech-bubble">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  className="amind-chat-title"
                  style={{ fontWeight: 'bold' }}
                >
                  {adminName}
                </div>
                <div>{commonUtils.formatDate(item.regDate, true)}</div>
              </div>
              <div className="admin-chat-content">{item.commentCn}</div>
            </div>
          </div>
        </>
      ))}
    </>
  )
}
