import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GolfSearchState {
  bizNm?: string | null
  minPrice?: number | null
  maxPrice?: number | null
  areaCd?: string | null
  dayType?: string | null
  months?: number | null
  membershipYn: string | null
  _timestamp?: number | null // 타임스탬프 필드 추가
}

export interface GraphDataState {
  monthData: number[][] | []
  quarterData: number[][] | []
  yearData: number[][] | []
  regionAvgPrice: RegionAvgPriceItem[]
  monthAvgPriceByRegion: MonthAvgPriceByRegionItem[]
  weekData: number[][] | []
  weekAvgPriceByRegion: WeekAvgPriceByRegionItem[]
}

export interface RegionAvgPriceItem {
  areaCd: string
  areaNm: string
  n01: number
  n02: number
  m01: number
  m02: number
}

export interface MonthAvgPriceByRegionItem {
  areaCd: string
  areaNm: string
  n01: number
  n02: number
  m01: number
  m02: number
  month: string
  year: string
}

export interface WeekAvgPriceByRegionItem {
  areaCd: string
  areaNm: string
  n01: number
  n02: number
  m01: number
  m02: number
  year: string
  week: string
}

export interface GolfPriceItem {
  bizCd: string
  bizNm: string // 골프장명
  areaCd: string //지역코드
  areaNm: string // 지역명
  n01: number // 비회원 주중 요금
  n02: number // 비회원 주말 요금
  m01: number // 회원 주중 요금
  m02: number // 회원 주말 요금
  mshipYn: string // 회원제 여부
  addr: string //주소
  areaCount: number //지역별 count
}

export interface GolfPriceState {
  listData: GolfPriceItem[]
}

export const initialSearchState: GolfSearchState = {
  bizNm: null,
  maxPrice: null,
  areaCd: null,
  dayType: 'weekday',
  months: null,
  membershipYn: 'A',
}

const initialGraphState: GraphDataState = {
  monthData: [],
  quarterData: [],
  yearData: [],
  regionAvgPrice: [],
  monthAvgPriceByRegion: [],
  weekData: [],
  weekAvgPriceByRegion: [],
}

const initialListState: GolfPriceState = {
  listData: [],
}

const initialSelectMapData: GolfPriceItem = {
  addr: '',
  areaCd: '',
  areaCount: -1,
  areaNm: '',
  bizCd: '',
  bizNm: '',
  m01: -1,
  m02: -1,
  mshipYn: '',
  n01: -1,
  n02: -1,
}

const initialAreaCountMap:{[key: string]: number} = {
}

const golfSearchSlice = createSlice({
  name: 'golfSearch',
  initialState: {
    searchParams: initialSearchState,
    graphData: initialGraphState,
    golfListData: initialListState,
    selectMapData: initialSelectMapData,
    areaCountMap: initialAreaCountMap,
  },
  reducers: {
    setSearchParams(state, action: PayloadAction<GolfSearchState>) {
      state.searchParams = action.payload
    },
    clearSearchFilters(state) {
      state.searchParams = initialSearchState
    },
    setGraphData(state, action: PayloadAction<number[][]>) {
      state.graphData.monthData = action.payload
    },
    clearGraphData(state) {
      state.graphData.monthData = []
    },
    setRegionAvgPrice: (state, action: PayloadAction<RegionAvgPriceItem[]>) => {
      state.graphData.regionAvgPrice = action.payload
    },
    setMonthAvgPriceByRegion(
      state,
      action: PayloadAction<MonthAvgPriceByRegionItem[]>,
    ) {
      state.graphData.monthAvgPriceByRegion = action.payload
    },
    setListData(state, action: PayloadAction<GolfPriceItem[]>) {
      state.golfListData.listData = action.payload
    },
    setQuarterAvgPrice(state, action: PayloadAction<number[][]>) {
      state.graphData.quarterData = action.payload
    },
    setYearAvgPrice(state, action: PayloadAction<number[][]>) {
      state.graphData.yearData = action.payload
    },
    setWeekAvgPriceByRegion(
      state,
      action: PayloadAction<WeekAvgPriceByRegionItem[]>,
    ) {
      state.graphData.weekAvgPriceByRegion = action.payload
    },
    setWeekGraphData(state, action: PayloadAction<number[][]>) {
      state.graphData.weekData = action.payload
    },
    setSelectMapData(state, action: PayloadAction<GolfPriceItem>) {
      state.selectMapData = action.payload;
    },
    clearSelectMapData(state) {
      state.selectMapData = initialSelectMapData;
    },
    setAreaCountMap(state, action: PayloadAction<{[key: string]: number}>){
      state.areaCountMap = action.payload;
    }
  },
})

export const {
  setSearchParams,
  clearSearchFilters,
  setGraphData,
  clearGraphData,
  setRegionAvgPrice,
  setMonthAvgPriceByRegion,
  setListData,
  setQuarterAvgPrice,
  setYearAvgPrice,
  setWeekAvgPriceByRegion,
  setWeekGraphData,
  setSelectMapData,
  clearSelectMapData,
  setAreaCountMap,
} = golfSearchSlice.actions

export default golfSearchSlice.reducer
