import { useAxios } from "@/context/axiosContext"
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { ComCode, setComCodeList, setComMsgList } from "@/redux/slices/redisSlice";

export const useRedis = () => {
    const axios = useAxios();
    const dispatch = useAppDispatch();
    const { comCodeList, comMsgList } = useAppSelector((state)=>state.redis);

    const getComCodes = (key:string) => {
        return comCodeList[key] || [];
    }

    const getComCodeValue = (key:string, value:string) => {
        return comCodeList[key] ? comCodeList[key].find(code => code.code === value) : undefined;
    }

    const refreshComCodes = async () => {
        // TODO 마지막 업데이트 시간 비교, axios 호출 
        const response:any = await axios.post('/redis/getComCodeList', {});
        if(response.status == 200){
            // TODO 성공코드 변경필요 
            dispatch(setComCodeList(response.data.data.list));
        }
    }

    const getComMsgs = (msgCd:string) => {
        return comMsgList[msgCd] || {};
    }

    const refreshComMsgs = async () => {
        // TODO 마지막 업데이트 시간 비교, axios 호출 
        const response:any = await axios.post('/redis/getMsgList', {});
        if(response.status == 200){
            // TODO 성공코드 변경필요 
            dispatch(setComMsgList(response.data.data.list));
        }
    }

    return {refreshComCodes, getComCodes, getComCodeValue, getComMsgs, refreshComMsgs};
}