import { useAppSelector } from '@/redux/hooks'
import { InsertMember } from './insertMember'
import { InsertGuest } from './insertGuest'
import { BoardHeader } from '../_components/boardHeader'
import { getHeaderTitle } from '../boardItem'

const InsertBoard = () => {
  const headerTitle = getHeaderTitle(window.location.pathname)
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  return (
    <>
      <BoardHeader title={headerTitle} />
      {isAuthenticated ? <InsertMember /> : <InsertGuest />}
    </>
  )
}

export default InsertBoard
