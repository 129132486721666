import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { routes } from '@/routes/routes'
import RouteGuard from '@/routes/RouteGuard'
import { useAxios } from '@/context/axiosContext'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { logout } from '@/redux/slices/auth'
import { useRedis } from '@/hooks/useRedis'

const AppRoutes = () => {
  const axios = useAxios()
  const dispatch = useAppDispatch()
  const { isAuthenticated, user } = useAppSelector((state) => state.auth)

  const { refreshComCodes, refreshComMsgs } = useRedis()

  const loginCheck = async () => {
    const response: any = await axios.get('/auth/refresh-token', {})
    if (response.data.common.resultCode == 'T0202') {
      // TODO 실패코드가 바뀔수도 있음
      // 만료된 토큰일경우 로그아웃
      if (isAuthenticated) {
        dispatch(logout())
      }
    }
  }

  useEffect(() => {
    loginCheck()

    refreshComCodes()
    refreshComMsgs()
  }, [])

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <RouteGuard
              element={route.element}
              path={route.path}
              //requireAuth={route.requireAuth}   //2025.03.19 ljh 정보제공 목적으로 로그인 후 이용 옵션 제거
            />
          }
        />
      ))}
    </Routes>
  )
}

export default AppRoutes
