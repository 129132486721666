import { Input } from '@/components'
import { FlexWrapper } from '@/components/layout/flex/flexWrapper'
import { useNiceAuth } from '@/hooks/useNiceAuth'
import { useMemo } from 'react'

export const NiceApiTest = () => {
  const { authData, requestNiceAuth } = useNiceAuth()

  const { mobile1, mobile2, name } = useMemo(() => {
    const mobileno = authData?.mobileno || ''
    const name = authData?.name || ''
    return {
      mobile1: mobileno.slice(3, 7),
      mobile2: mobileno.slice(7),
      name: name,
    }
  }, [authData])

  return (
    <div>
      <h1>NiceApiTest</h1>
      <button onClick={requestNiceAuth}>인증 요청</button>
      <FlexWrapper>
        <Input
          id="name"
          name="name"
          value={name}
          onChange={(e) => console.log(e)}
        />
        <Input
          id="mobileno1"
          name="mobileno2"
          value={mobile1}
          onChange={(e) => console.log(e)}
        />
        <Input
          id="mobileno2"
          name="mobileno2"
          value={mobile2}
          onChange={(e) => console.log(e)}
        />
      </FlexWrapper>

      {authData && (
        <p>
          인증 완료: {JSON.stringify(authData)}
          <br /> mobileno : {authData.mobileno}
        </p>
      )}
    </div>
  )
}
