import { Badge } from '@/components'
import { BoardListItem } from '../boardItem'
import commonUtils from '@/utils/commonUtils'

interface DetailBoardReplyProps {
  boardDetail?: BoardListItem | null
  textareaRef: React.RefObject<HTMLTextAreaElement>
}

export const DetailBoardReply: React.FC<DetailBoardReplyProps> = ({
  boardDetail,
  textareaRef,
}) => {
  return (
    <>
      {boardDetail?.replyYn === 'Y' && (
        <>
          {/* <div
            style={{
              boxShadow: '0 0 0 0.5px #ebecef',
              borderColor: '#ebecef',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          /> */}
          <div
            style={{
              marginBottom: '10px',
              width: '100%',
              height: 'auto',
              padding: '29px 29px 0',
              borderRadius: '6px',
              border: '1px solid',
              borderColor: '#ebecef',
            }}
          >
            <div style={{ color: '#979797' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Badge color="success">답변완료</Badge>
                  </div>
                  <div>
                    &nbsp;
                    {boardDetail?.replyRegDate
                      ? commonUtils.formatDate(boardDetail?.replyRegDate, true)
                      : ''}
                  </div>
                </div>
              </div>
            </div>
            <textarea
              id="replyCn"
              name="REPLY_CN"
              ref={textareaRef}
              disabled={true}
              style={{
                marginTop: '10px',
                width: '100%',
                height: 'auto',
                padding: '0 0 20px 0',
                border: 'none', // 테두리 제거
                outline: 'none', // 포커스 시 테두리 제거
                resize: 'none', // 사용자가 크기 조정 불가
                overflow: 'hidden', // 스크롤 숨기기
                backgroundColor: 'transparent',
              }}
            >
              {boardDetail?.replyCn}
            </textarea>
          </div>
        </>
      )}
    </>
  )
}
