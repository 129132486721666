import { createSlice } from "@reduxjs/toolkit"

export interface ComCode {
    cmpnyCd: string
    codeType: string
    codeTypeNm: string
    code: string
    codeNm: string
    engCodeNm: string
    displayOrder: number
    mainTable: string
    mainColumn: string
    upCode: string
    upCodeType: string
    useYn: string
    remark: string
    regId: string
    regDate: string
    updId: string
    updDate: string
}

export interface ComMsg {
    cmpnyCd: string
    msgCd: string
    msgExp: string
    typeDivCd: string
    regId: string
    regDate: string
    updId: string
    updDate: string
}

export interface ComCodeList{
    [key:string] : ComCode[]
}

const initialComCodeList: ComCodeList = {
    '' : [],
}

export interface ComMsgList {
    [key:string] : ComMsg
}

const initialComMsgMasterList: ComMsgList = {
}

const redisSlice = createSlice({
    name: 'redis',
    initialState: {
        comCodeList: initialComCodeList,
        comCodeLastUpdate: -1,
        comMsgList: initialComMsgMasterList,
        comMsgLastUpdate: -1,
    },
    reducers: {
        setComCodeList(state, action){
            state.comCodeList = action.payload;
            state.comCodeLastUpdate = Date.now();
        },
        setComMsgList(state, action){
            state.comMsgList = action.payload;
            state.comMsgLastUpdate = Date.now();
        }
    }
});

export const { setComCodeList, setComMsgList } = redisSlice.actions;
export default redisSlice.reducer;