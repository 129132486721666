import { useDialog } from '@/context/dialogContext'
import { useAppDispatch } from '@/redux/hooks'
import { logout } from '@/redux/slices/auth'
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'

// 타입 확장
interface CustomAxiosInternalRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean
  _disableLoading?: boolean
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: Number(process.env.REACT_APP_DEFAULT_TIMEOUT),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // 쿠키를 요청에 포함시킴
})

export const setupAxiosInterceptors = (
  startLoading: () => void,
  stopLoading: () => void,
) => {
  const dispatch = useAppDispatch()

  const { showAlert } = useDialog()

  axiosInstance.interceptors.request.use((config) => {
    if (!config._disableLoading) {
      startLoading()
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => {
      if (
        !(response.config as CustomAxiosInternalRequestConfig)?._disableLoading
      ) {
        stopLoading()
      }
      return response
    },
    async (err) => {
      if (!(err.config as CustomAxiosInternalRequestConfig)?._disableLoading) {
        stopLoading()
      }

      if (!err.response) {
        console.error('Server unreachable:', err.message)
        return Promise.reject({
          code: 'NETWORK_ERROR',
          message: '서버에 연결할 수 없습니다.',
        })
      }

      if (err.response) {
        const status = err.response.status
        const message =
          err.response.data?.message || err.message || 'Unknown error'
        const originalRequest = err.config

        if (status === 401) {
          // 401 에러 발생 시
          try {
            const refreshResponse = await axiosInstance.get(
              '/auth/refresh-token',
              {},
            )

            // 리프레시 토큰 요청 성공
            if (refreshResponse.data.common.resultCode == 'T0200') {
              return axiosInstance(originalRequest) // 요청 재전송
            } else {
              dispatch(logout())
              window.location.href = '/'
            }
          } catch (refErr) {
            // 리프레시 토큰 요청 중 오류 발생
            console.error('Error during refresh token request:', refErr)
            await axios.post('/auth/logout')
            dispatch(logout())
            window.location.href = '/'
          }
        } else {
          console.error(`Error ${status}: ${message}`)
        }
      } else if (err.request) {
        console.error('No response received:', err.request)
      } else {
        console.error('Error setting up request:', err.message)
      }

      return Promise.reject(err)
    },
  )
}

export default axiosInstance
