import BannerSwiper from '@/components/main/banner/bannerSwiper'
import { MainBoard } from '@/pages/main/_components/board/mainBoard'
import { MainGolfSearch } from '@/pages/main/_components/golfSearch/mainGolfSearch'
import { RegionGraph } from '@/pages/main/_components/graph/regionGraph'
import { MainGraph } from '@/pages/main/_components/graph/mainGraph'
import React from 'react'
import styled from 'styled-components'
import { NoticePopup } from '../notice/_components/noticePopup'
import { RootState } from '@/redux/store'
import { useSelector } from 'react-redux'
import MainBanner from '@/components/main/banner/mainBanner'
import { MainYoutube } from './_components/youtube/mainYoutube'
import { MainBlog } from './_components/blog/mainBlog'

const Main: React.FC = () => {
  const { regionAvgPrice } = useSelector(
    (state: RootState) => state.golfSearch.graphData,
  )

  return (
    <MainWrapper>
      {/* <SideBannerWrapper position="left">
        <MainBanner
          link="https://admin.neverbe.co.kr"
          imageUrl="/images/membership_neverbe.png"
        />
      </SideBannerWrapper> */}

      <MainContent>
        {/* < BannerSwiper
          bannerList={[
            {
              id: '1',
              title: '꿀프배너01',
              // imgUrl: 'banner01.jpg',
              //url: 'https://www.hanatour.com/',
            },
            {
              id: '2',
              title: '꿀프배너02',
              // imgUrl: 'banner02.jpg',
              //url: 'https://kr.callawaygolf.com/',
            },
            // {
            //   id: '3',
            //   title: '꿀프배너03',
            // imgUrl: 'banner03.jpg',
            //url: 'https://kr.callawaygolf.com/',
            // },
            // {
            //   id: '3',
            //   title: '티스테이션',
            //   //imgUrl: 'main_banner_03.png',
            //   //url: 'https://www.tstation.com/',
            // },
          ]}
        > */}

        <MainGolfSearch />
        <RegionGraph regionAvgPrice={regionAvgPrice} />
        <ContentWrapper>
          {/* <MainGraph /> */}
          <LeftContainer>
            <MainYoutube />
          </LeftContainer>
          <RightContainer>
            <MainBoard />
            <MainBlog />
          </RightContainer>
        </ContentWrapper>
      </MainContent>
      <NoticePopup />
      {/* TODO 우측 배너 영역 제거 */}
      {/* <SideBannerWrapper position="right">
      <MainBanner
          link="https://www.starbucks.co.kr/"
          imageUrl="/images/banner/sample_banner_03.png"
        /> */}
      {/* <div
          style={{
            width: '100%',
            maxWidth: '200px',
            height: '500px',
            margin: '0 15px',
            backgroundColor: '#f0f0f0',
          }}
        ></div>
      </SideBannerWrapper> */}
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`

const SideBannerWrapper = styled.div<{ position: 'left' | 'right' }>`
  position: fixed;
  top: 0;
  ${(props) => props.position}: 0;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
// @media (max-width: 768px) {
//   display: none;
// }

const MainContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 10px; /* 좌우 간격 조절 */
  width: 100%;
  align-items: stretch;
`

// 왼쪽 영역 (MainYoutube)
const LeftContainer = styled.div`
  flex: 1;
  min-width: 50%;
  height: 100%;
  display: flex;
  min-width: calc(50% - 5px); /* gap을 고려한 최소 너비 */
  flex-direction: column;
`

// 오른쪽 영역 (MainBoard + MainBlog)
const RightContainer = styled.div`
  flex: 1;
  min-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: calc(50% - 5px); /* gap을 고려한 최소 너비 */
  gap: 10px; /* 내부 간격 조절 */
`

// const BoardWrapper = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   height: auto;
// `

// @media (max-width: 768px) {
//   flex-direction: column;
//   gap: 20px;
// }

export default Main
