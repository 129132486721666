import { MainYoutubeSwiper } from './mainYoutubeSwiper'
import { useAxios } from '@/context/axiosContext'
import { useEffect, useState } from 'react'
import { RankList, YoutubeRankData } from '@/pages/youtube/youtubeRankItem'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '@/context/dialogContext'

export const MainYoutube = () => {
  const navigate = useNavigate()
  const axios = useAxios()
  const [listItem, setListItem] = useState<YoutubeRankData[]>([])
  const [likedItem, setLikedItem] = useState<YoutubeRankData[]>([])
  const { showAlert } = useDialog()

  useEffect(() => {
    getRankList()
  }, [])

  useEffect(() => {
    getLikedList()
  }, [listItem])

  const getRankList = async () => {
    const response = await axios.get(`/youtube/getRankList`, {
      headers: { 'Cache-Control': 'no-cache' },
    })

    const { rankList } = response.data.data

    if (rankList && Array.isArray(rankList)) {
      setListItem(rankList)
    }
  }

  const getLikedList = async () => {
    const response = await axios.get(`/youtube/getLikedList`, {
      headers: { 'Cache-Control': 'no-cache' },
    })

    const { likedList } = response.data.data

    if (likedList && Array.isArray(likedList)) {
      setLikedItem(likedList)
    }
  }

  const moveYoutubeRank = () => {
    window.scrollTo(0, 0)
    navigate(`/youtube`)
  }

  const setLikeStatus = async (
    formData: FormData,
    chnlId: string,
    currentLikeStatus: string,
  ) => {
    try {
      await axios.post(`/youtube/like`, formData)

      // API 성공 후 데이터 업데이트 (likeCount 조정)
      setListItem((prevItems) =>
        prevItems.map((item) =>
          item.chnlId === chnlId
            ? {
                ...item,
                likeCount:
                  currentLikeStatus === 'Y'
                    ? item.likeCount - 1
                    : item.likeCount + 1,
                userLikeYn: currentLikeStatus === 'Y' ? 'N' : 'Y',
              }
            : item,
        ),
      )
    } catch (err: any) {
      showAlert({
        type: 'error',
        title: '좋아요 요청이 되지 않았어요.',
      })
    }
  }
  return (
    <>
      <MainYoutubeWrapper>
        <YoutubeTitle onClick={moveYoutubeRank}>
          <div>{RankList.title}</div>
          <div
            style={{
              cursor: 'pointer',
              fontSize: '13px',
              color: '#888888',
              fontWeight: 'normal',
            }}
            onClick={moveYoutubeRank}
          >
            {'더보기'}
          </div>
        </YoutubeTitle>
        {listItem.length > 0 && (
          <MainYoutubeSwiper
            rankList={listItem}
            setLikeStatus={setLikeStatus}
          />
        )}
      </MainYoutubeWrapper>
    </>
  )
}

const YoutubeTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
`

const MainYoutubeWrapper = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
`
